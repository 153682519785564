import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { getAgentCommissionPayments } from "./../../../config/agent_reports_api_calls";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Fileupload } from "../../../components/Image/Fileupload";
import dateFormat, { masks } from "dateformat";
import DataTable from "../../../components/Tables/DataTable";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  APP_LIVE_URL,
} from "./../../../config/config";
const auth = new HelperClass();

class PaymentsHistory extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      client_login_link: "",
      copied: false,
      copied_client: false,
      copied2: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      cancel_button: false,
      tableLoader: true,
      login_url: "",
      logo: "",
      signature_image: "",
      columnsArray: [
        {
          name: "dateAdded",
          label: "Order Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "orderno",
          label: "Order #",
        },
        {
          name: "name",
          label: "Client Name",
        },
        {
          name: "comp_name",
          label: "Client Company",
        },
        {
          name: "email",
          label: "Client Email",
        },
        {
          name: "commission_amount",
          label: "Amount Paid",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatAsCurrency(value)}</div>;
            },
          },
        },
        {
          name: "payment_method_pay",
          label: "Payment Method",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatPaymentMethod(value)}</div>;
            },
          },
        },
        /*{
          name: "payment_notes",
          label: "Payment notes",
        },*/
        {
          name: "payment_receipt",
          label: "View Receipt",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewPdf(value, tableMeta)}</div>;
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const reportsData = await getAgentCommissionPayments(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("getAgentCommissionSettings: ", reportsData.data);

    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        tableData: reportsData.data.data,
        tableLoader: false,
      });
    } else {
      console.log("Last: ", reportsData.data);
      //window.location.replace("/error");
    }
  }

  viewPdf = (value, tableMeta) => {
    let domain_str = value;
    const rowIndex = tableMeta.rowIndex;
    const agreement_title = tableMeta.rowIndex;
    //console.log("viewPdf pdf_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <div>
          {this.state.downloadingIndex === rowIndex ? (
            <div className="text-left">
              <div
                className="spinner-border spinner-border-sm text-primary"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <a
              onClick={() => {
                this.setState({ downloadingIndex: rowIndex });
                setTimeout(() => {
                  //this.setState({ downloadingIndex: null });
                  this.downloadPdf(value, tableMeta);
                }, 2000);
                //this.downloadPdf(value, tableMeta)
              }}
              style={{ cursor: "pointer" }}
              title="Download PDF"
            >
              <span className="badge badge-outline-primary">
                <em
                  className="icon ni ni-download"
                  style={{ fontSize: "18px" }}
                ></em>
              </span>
            </a>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-light">---</span>
        </div>
      );
    }
  };

  downloadPdf = async (d_f_name, tableMeta) => {
    let file_name = d_f_name;

    var url = APP_LIVE_URL + "files_data/receipts/" + file_name;

    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        this.setState({ errorMessage: "", downloadingIndex: null });
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the receipts.",
          downloadingIndex: null,
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  format_date = (value) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  formatPaymentMethod = (value) => {
    if (value !== "") {
      if (value === "wire") {
        return "Wire transfer";
      } else if (value === "bank") {
        return "Bank Transfer";
      } else if (value === "other") {
        return "Other";
      } else if (value === "Quickbooks") {
        return "Quickbooks";
      } else {
        return value;
      }
    } else {
      return "---";
    }
  };

  getDecimalPoint = () => {
    //console.log("this.props.quotationSettings[0]?.decimal_point: ", this.props);
    if (
      this.props.quotationSettings[0]?.decimal_point &&
      this.props.quotationSettings[0]?.decimal_point !== ""
    ) {
      const decimalPoint = this.props.quotationSettings[0]?.decimal_point;
      return decimalPoint !== "" ? Number(decimalPoint) : 2;
    }
    return 2;
  };

  formatAsCurrency = (amount) => {
    //console.log("amount: ", typeof amount);
    let decimal_point = this.getDecimalPoint();

    if (typeof amount === "string") {
      amount = parseFloat(amount);
    }
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: decimal_point,
      maximumFractionDigits: decimal_point,
    });
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      {" "}
                      <em className="icon ni ni-histroy"></em> Payment History
                    </h3>
                    <div className="nk-block-des text-soft">
                      <p>
                        This page displays a list of payments sent to the
                        channel partner, providing a clear overview of all
                        completed transactions. Each entry includes details such
                        as the payment amount, date, and payment method,
                        ensuring transparency and easy tracking..
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert example-alert-revoke mt-3 mb-3"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <>
                          <DataTable
                            columns={this.state.columnsArray}
                            tableData={this.state.tableData}
                            title=""
                          />
                        </>
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
    quotationSettings: state.quotationSettings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentsHistory);
