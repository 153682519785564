import react, { Component, createRef } from "react";
import Footer from "./footer";
import Header from "./Header";
import ContentLoader from "react-content-loader";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import Select from "react-select";
import countryList from "react-select-country-list";
import {
  addSettings,
  addMsaInfo,
  setLeftMenu,
  setQuotationSettings,
} from "../../actions";

import LefeSideBar from "./leftSideBar";
import Dashboard from "../../Pages/Reseller/Dashboard";
import Clients from "../../Pages/Reseller/Clients";
import Addclient from "../../Pages/Reseller/Clients/addclient";
import ClientsQueue from "../../Pages/Reseller/Clients/clients_queue";
import Services from "../../Pages/Reseller/Services";
import Addservice from "../../Pages/Reseller/Services/addservice";

import PartnerQuotations from "../../Pages/Reseller/Partner_quotations";
import EditQuotations from "../../Pages/Reseller/Partner_quotations/edit";

import PartnerRfqs from "../../Pages/Reseller/Partner_rfqs";

import PartnerOrders from "../../Pages/Reseller/Partner_orders";
import ViewSalesOrder from "../../Pages/Reseller/Partner_orders/view";
import EditSalesOrder from "../../Pages/Reseller/Partner_orders/edit";

import Documents from "../../Pages/Reseller/Documents";
import Cpforms from "../../Pages/Reseller/Forms";
import CpFormSubmit from "../../Pages/Reseller/Forms/form_submit";
import CpFormSubmissions from "../../Pages/Reseller/Forms/form_submissions";
import CpFormSingleSubmission from "../../Pages/Reseller/Forms/form_single_submission";

import Analytics from "../../Pages/Reseller/Analytics";
import Reports from "../../Pages/Reseller/Reports";
import EmailNotifications from "../../Pages/Reseller/Email_notifications";
import Agreements from "../../Pages/Reseller/Agreements";
import Pdfdownload from "../../Pages/Reseller/Agreements/pdfdownload";
import UnsignedAgreements from "../../Pages/Reseller/Agreements/unsignedagreements";
import Agents from "../../Pages/Reseller/Agents";
import Addagent from "../../Pages/Reseller/Agents/addagent";
import Sharelinks from "../../Pages/Reseller/Sharelink";
import SharelinkSettings from "../../Pages/Reseller/Sharelink/sharelink_settings";
import AddSharelink from "../../Pages/Reseller/Sharelink/add_sharelink";
import SharelinkAnalytics from "../../Pages/Reseller/Sharelink/sharelink_analytics";

import Upgrades from "../../Pages/Reseller/Upgrades";
import BackOffice from "../../Pages/Reseller/BackOffice";
import MSA from "../../Pages/Reseller/MSA";
import DashboardLoader from "../ContentLoaders/dashboard_loader";
import ErrorPage from "../Errors/404";

import PaymentsHistory from "../../Pages/Reseller/Billings";
import Payouts from "../../Pages/Reseller/Billings/Payouts";
import CommissionReport from "../../Pages/Reseller/Billings/CommissionReport";
import CommissionSettings from "../../Pages/Reseller/Billings/Settings";

import Cms from "../../Pages/Reseller/Cms";
import Pageslist from "../../Pages/Reseller/Cms/pageslist";
import Addpages from "../../Pages/Reseller/Cms/addpages";
import Editpage from "../../Pages/Reseller/Cms/editpage";
import Menuslist from "../../Pages/Reseller/Cms/menuslist";
import Addmenus from "../../Pages/Reseller/Cms/addmenus";
import Editmenu from "../../Pages/Reseller/Cms/editmenu";
import Addheader from "../../Pages/Reseller/Cms/addheader";
import Headerlist from "../../Pages/Reseller/Cms/headerlist";
import Websettings from "../../Pages/Reseller/Cms/websettings";

import Themes from "../../Pages/Reseller/Themes";
import Editor from "../../Pages/Reseller/Themes/editor";

import Domains from "../../Pages/Reseller/Domains";
import Adddomains from "../../Pages/Reseller/Domains/adddomains";

import AccessDenied from "../Errors/AccessDenied";

import {
  getAccountData,
  profileUpdate,
  resellerLeftMenu,
  getQuotationSettings,
} from "../../config/api_calls";
import {
  LOGO,
  THEME_COLOR,
  THEME_TEXT_COLOR,
  VENDOR_SIGNATURE,
} from "../../config/config";
import { REPLACECONTENT, ValidateEmail, normalize } from "../../config/utility";
import HelperClass from "./../../config/helperClass";
import Agreement from "./agreement_new";

import TextField from "../InputFields/TextField";
import { ExitToApp } from "@material-ui/icons";

const auth = new HelperClass();
const pageAssociations = {
  partner_quotations: ["EditQuotations"],
  partner_orders: ["ViewSalesOrder", "EditSalesOrder"],
  sharelinks: ["add_sharelink"],
  domains: ["Adddomains"],
  agents: ["Addagent"],
  Clients: ["Addclient"],
  cp_forms: ["agent_form_submit","agent_form_submissions", "agent_single_submissions"]
};

class Main extends Component {
  constructor(props) {
    super(props);

    if (auth.isLogin() === false) {
      auth.logout();
    }

    this.state = {
      view: this.props.view,
      activePage: "",
      is_msa_signed: false,
      Agreement_Content: "",
      title_agreement: "",
      bgColor: "#ffffff",
      vendor_signor_signature_file: "",
      published_date: "",
      defaultLoader: true,
      errorCompName: "",
      comp_name: "",
      errorName: "",
      name: "",
      errorTitle: "",
      title: "",
      errorEmail: "",
      email: "",
      errorPhone: "",
      phone: "",
      errorPass: "",
      password: "",
      confirm_password: "",
      errorAddress: "",
      address: "",
      errorCity: "",
      city: "",
      errorState: "",
      states: "",
      errorZip: "",
      zip: "",
      errorMessage: "",
      disabled: "",
      successMessage: "",
      routingUrl: "",
      passwordShown: false,
      confirmPasswordShown: false,
      errorAddress2: "",
      address2: "",
      country: "",
      errorCountry: "",
      isLoading: true,
      useGooglePlaces: true,
      toggleButtonText: "Allow PO Box Address",
      loginDetails: [],
    };
    this.phoneInputRef = createRef();
    this.countryOptions = countryList()
      .getData()
      .filter(
        (country) => country.label !== "United States Minor Outlying Islands"
      );
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    const phoneNumber = await auth.getPhone();
    this.setState({
      comp_name: auth.getCompName(),
      name: auth.getName() === false ? "" : auth.getName(),
      email: auth.getEmail(),
      phone: phoneNumber || "",
      title: auth.getTitle() === false ? "" : auth.getTitle(),
    });
    this.defaultChecks();
    await this.getLeftMenu();
    await this.getQuotationSetting();
    this.setState({ isLoading: false });
  }

  getQuotationSetting = async () => {
    let acctno = auth.getAccount();

    let res = await getQuotationSettings(
      acctno,
      auth.getToken(),
      auth.getEmail()
    );
    //console.log("getQuotationSettings: ", res.data);
    if (
      res.data.status === 200 &&
      res.data.message === "success" &&
      res.data.data.length > 0
    ) {
      // Dispatch action to update left menu in Redux
      this.props.setQuotationSettings(res.data.data);
    }
  };

  getLeftMenu = async () => {
    let acctno = auth.getAccount();

    let res = await resellerLeftMenu(acctno, auth.getToken(), auth.getEmail());
    //console.log("getLeftMenu: ", res.data);
    if (
      res.data.status === 200 &&
      res.data.message === "success" &&
      res.data.data.length > 0
    ) {
      // Dispatch action to update left menu in Redux
      this.props.setLeftMenu(res.data.data);
      this.setState({ loginDetails: res.data.loginDetails });
    }
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  handleFocus = () => {
    // Move cursor to the end of the input value
    const input = this.phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      useGooglePlaces: !prevState.useGooglePlaces,
      toggleButtonText:
        prevState.toggleButtonText === "Allow PO Box Address"
          ? "Allow Simple Address"
          : "Allow PO Box Address",
    }));
  };

  loginDetailView = () => {
    this.setState({ successMessage: "", errorMessage: "" });
    window.$("#modalLoginDetail").modal("show");
  };

  handleAddressSelect = async (place) => {
    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        console.log("addressComponents", addressComponents);
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        let neighborhood = null;
        let locality = null;
        let address2 = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            neighborhood = component.long_name;
          } else if (component.types.includes("locality")) {
            locality = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("subpremise")) {
            address2 = component.long_name;
          } else if (
            ![
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += " ";
            }
            streetAddress += component.long_name;
          }
        });

        // Check if neighborhood is part of the formatted address
        if (neighborhood && formattedAddress.includes(neighborhood)) {
          city = neighborhood;
        } else {
          city = locality;
        }

        this.setState({
          city: city && city !== "" ? city : "",
          states: state && state !== "" ? state : "",
          zip: zipCode && zipCode !== "" ? zipCode : "",
          country: country && country !== "" ? country : "",
          address: streetAddress && streetAddress !== "" ? streetAddress : "",
          address2: address2 && address2 !== "" ? address2 : "",
        });
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const country = event.label;
    this.setState({
      country: country,
    });
  };

  formUpdate = async () => {
    let {
      name,
      email,
      comp_name,
      title,
      phone,
      address,
      city,
      states,
      zip,
      password,
      confirm_password,
      address2,
      country,
    } = this.state;
    const modalBody = document.getElementById("modalFormProfile");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }
    let is_validated = true;
    if (comp_name === "") {
      this.setState({
        errorCompName: "error",
        errorMessage: "Company Name is required.",
      });
      is_validated = false;
    } else if (name === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessage: "Full Name is required.",
      });
      is_validated = false;
    } else if (phone === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorPhone: "error",
        errorMessage: "Phone is required.",
      });
      is_validated = false;
    } else if (!this.isPhoneValid(phone)) {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorPhone: "error",
        errorMessage: "Invalid phone format.",
      });
      is_validated = false;
    } else if (address === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorPhone: "",
        errorAddress: "error",
        errorMessage: "Address is required.",
      });
      is_validated = false;
    } else if (city === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorPhone: "",
        errorAddress: "",
        errorCity: "error",
        errorMessage: "City is required.",
      });
      is_validated = false;
    } else if (states === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorPhone: "",
        errorAddress: "",
        errorCity: "",
        errorState: "error",
        errorMessage: "State is required.",
      });
      is_validated = false;
    } else if (zip === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorPhone: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "error",
        errorMessage: "Zip is required.",
      });
      is_validated = false;
    } else if (country === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorPhone: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "error",
        errorMessage: "Country is required.",
      });
      is_validated = false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
      });
      is_validated = false;
    } else if (password !== "") {
      if (password.length < 8) {
        this.setState({
          errorCompName: "",
          errorName: "",
          errorPhone: "",
          errorAddress: "",
          errorCity: "",
          errorState: "",
          errorZip: "",
          errorCountry: "",
          errorPass: "error",
          errorMessage: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (confirm_password === "") {
        this.setState({
          errorCompName: "",
          errorName: "",
          errorPhone: "",
          errorAddress: "",
          errorCity: "",
          errorState: "",
          errorZip: "",
          errorCountry: "",
          errorPass: "error",
          errorMessage: "Confirm password is required.",
        });
        is_validated = false;
      } else if (confirm_password.length < 8) {
        this.setState({
          errorCompName: "",
          errorName: "",
          errorPhone: "",
          errorAddress: "",
          errorCity: "",
          errorState: "",
          errorZip: "",
          errorCountry: "",
          errorPass: "error",
          errorMessage: "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (password.trim() != confirm_password.trim()) {
        this.setState({
          errorCompName: "",
          errorName: "",
          errorPhone: "",
          errorAddress: "",
          errorCity: "",
          errorState: "",
          errorZip: "",
          errorCountry: "",
          errorPass: "error",
          errorMessage: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    }
    if (is_validated) {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorAddress: "",
        errorCity: "",
        errorState: "",
        errorZip: "",
        errorCountry: "",
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        errorConfirm: "",
        errorPhone: "",
        errorTitle: "",
        disabled: true,
      });

      let apiResponce = await profileUpdate(
        auth.getAccount(),
        auth.getToken(),
        name,
        title,
        comp_name,
        phone,
        address,
        city,
        states,
        zip,
        password,
        address2,
        country
      );
      console.log(apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 409 &&
        apiResponce.data.message === "Address validation failed"
      ) {
        const formattedErrorMessage = apiResponce.data.errors
          ? String(apiResponce.data.errors).replace(/,/g, "<br />")
          : "Address validation failed";

        this.setState({
          errorMessage: formattedErrorMessage,
          disabled: false,
        });
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "success"
      ) {
        Cookies.set(
          "comp_nme__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["comp_name"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "name__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["name"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "phone__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["phone"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        Cookies.set(
          "title__",
          CryptoJS.AES.encrypt(
            apiResponce.data.data[0]["title"],
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );

        this.setState({
          successMessage: "Profile updated successfully.",
          disabled: false,
        });

        setTimeout(async function () {
          window.$("#modalFormProfile").modal("hide");
        }, 2000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessage: "Something went wrong,try later.",
          disabled: false,
        });
        return false;
      } else {
        auth.logout();
      }
    }
  };

  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    });
  };

  toggleConfirmPassword = () => {
    this.setState({
      confirmPasswordShown: !this.state.confirmPasswordShown,
    });
  };

  handleChange = (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    if (name === "name") {
      value = value.replace(/[^a-zA-Z\s'.,-]/g, "");
    } else if (name === "title") {
      value = value.replace(/[^a-zA-Z0-9\s\-_.,&()]/g, "");
    } else if (name === "comp_name") {
      value = value.replace(/[^a-zA-Z0-9\s\-_,&().]/g, "");
    }
    this.setState({
      [name]: value,
    });
  };

  agreementSigned = () => {
    this.setState({ is_msa_signed: true });
  };

  defaultChecks = async () => {
    let apiData = await getAccountData(auth.getAccount(), auth.getToken());
    //console.log("Agreement DATA: ", apiData.data);
    if (
      apiData.data.status === 403 ||
      apiData.data.errors === "authentication missing" ||
      apiData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      apiData.data.status === 404 ||
      (apiData.data.data && apiData.data.data.length === 0)
    ) {
      console.log("First 404 Error");
      //this.props.history.push("/error");
    } else if (apiData.data.status === 200 && apiData.data.data.length > 0) {
      this.props.addSettings(apiData.data.resellers_setting);
      this.props.addMsaInfo(apiData.data.agreement);

      this.setState({
        address: apiData.data.data[0]["address"],
        city: apiData.data.data[0]["city"],
        states: apiData.data.data[0]["state"],
        zip: apiData.data.data[0]["zip"],
        address2: apiData.data.data[0]["address2"],
        country: apiData.data.data[0]["country"],
        routingUrl: apiData.data.url,
      });
      if (apiData.data.agreement === "No") {
        this.setState({
          is_msa_signed: false,
          defaultLoader: false,
        });
      } else if (apiData.data.agreement === "Yes") {
        this.setState({
          is_msa_signed: true,
          defaultLoader: false,
        });
      }
    } else if (
      (apiData.data.status === 200 &&
        apiData.data.data[0]["isVerified"] === "No") ||
      apiData.data.data[0]["account_status"] !== "active"
    ) {
      auth.logout();
    } else {
      console.log("Last Error");
      //this.props.history.push("/error");
    }
  };

  pageView = () => {
    const { leftMenuItems } = this.props;

    let urlFromParams = window.location.pathname;
    let urlSegments = urlFromParams.split("/");

    urlSegments = urlSegments.slice(1);

    const firstPart = urlSegments.slice(0, 2).join("/");
    //console.log("URL segments:", urlSegments);
    //console.log("First part of URL:", firstPart);

    const matchedMenuItem = leftMenuItems.find((menuItem) => {
      const menuItemLink = menuItem.link.startsWith("/")
        ? menuItem.link.slice(1)
        : menuItem.link;

      const submenuLinks = menuItem.submenu.map((submenuItem) =>
        submenuItem.link.startsWith("/")
          ? submenuItem.link.slice(1)
          : submenuItem.link
      );

      const menuItemLinkNormalized = `/${menuItemLink}`.toLowerCase();
      const firstPartNormalized = `/${firstPart}`.toLowerCase();

      if (
        menuItemLinkNormalized === firstPartNormalized ||
        submenuLinks.some(
          (submenuLink) =>
            `/${submenuLink}`.toLowerCase() === firstPartNormalized
        )
      ) {
        return true;
      }

      return false;
    });

    const shouldRenderComponent = !!matchedMenuItem;

    if (shouldRenderComponent) {
      return this.renderComponent();
    }

    const mainPages = Object.keys(pageAssociations);
    for (const mainPage of mainPages) {
      if (pageAssociations[mainPage].includes(this.state.view)) {
        const mainPageMatched = leftMenuItems.find((menuItem) => {
          const menuItemLink = menuItem.link.startsWith("/")
            ? menuItem.link.slice(1)
            : menuItem.link;

          const submenuLinks = menuItem.submenu.map((submenuItem) =>
            submenuItem.link.startsWith("/")
              ? submenuItem.link.slice(1)
              : submenuItem.link
          );

          const menuItemLinkNormalized = `/${menuItemLink}`.toLowerCase();
          const mainPageNormalized = `/${mainPage.toLowerCase()}`;

          if (
            menuItemLinkNormalized === mainPageNormalized ||
            submenuLinks.some(
              (submenuLink) =>
                `/${submenuLink}`.toLowerCase() === mainPageNormalized
            )
          ) {
            return true;
          }

          return false;
        });

        if (mainPageMatched) {
          return this.renderComponent();
        }
      }
    }

    setTimeout(() => {
      this.setState({ showAccessDenied: true });
    }, 500);

    if (this.state.showAccessDenied) {
      return <AccessDenied />;
    }
  };

  renderComponent = () => {
    let shouldRenderServices = false;
    switch (this.state.view) {
      case "dashboard":
        shouldRenderServices = this.props.leftMenuItems.some((menuItem) => {
          if (menuItem.link === "/services") {
            return true;
          } else if (menuItem.submenu && menuItem.submenu.length > 0) {
            return menuItem.submenu.some(
              (submenuItem) => submenuItem.link === "/services"
            );
          }
          return false;
        });

        return (
          <Dashboard
            props={this.props}
            shouldRenderServices={shouldRenderServices}
          />
        );
      case "clients":
        return <Clients props={this.props} />;
      case "clients_queue":
        return <ClientsQueue props={this.props} />;
      case "Addclient":
        return <Addclient props={this.props} />;
      case "services":
        return <Services props={this.props} />;
      case "cms":
        return <Cms props={this.props} />;
      case "partner_quotations":
        return <PartnerQuotations props={this.props} />;
      case "EditQuotations":
        return <EditQuotations props={this.props} />;
      case "partner_rfqs":
        return <PartnerRfqs props={this.props} />;
      case "partner_orders":
        return <PartnerOrders props={this.props} />;
      case "ViewSalesOrder":
        return <ViewSalesOrder props={this.props} />;
      case "EditSalesOrder":
        return <EditSalesOrder props={this.props} />;
      case "documents":
        return <Documents props={this.props} />;
      case "cp_forms":
        return <Cpforms props={this.props} />;
      case "agent_form_submit":
        return <CpFormSubmit props={this.props} />;
        case "agent_form_submissions":
        return <CpFormSubmissions props={this.props} />;
        case "agent_single_submissions":
        return <CpFormSingleSubmission props={this.props} />;
      case "Pageslist":
        return <Pageslist props={this.props} />;
      case "Addpages":
        return <Addpages props={this.props} />;
      case "Editpage":
        return <Editpage props={this.props} />;
      case "Pdfdownload":
        return <Pdfdownload props={this.props} />;
      case "UnsignedAgreements":
        return <UnsignedAgreements props={this.props} />;
      case "Editmenu":
        return <Editmenu props={this.props} />;
      case "Menuslist":
        return <Menuslist props={this.props} />;
      case "Addmenus":
        return <Addmenus props={this.props} />;
      case "Addheader":
        return <Addheader props={this.props} />;
      case "Headerlist":
        return <Headerlist props={this.props} />;
      case "Websettings":
        return <Websettings props={this.props} />;
      case "Addservice":
        return <Addservice props={this.props} />;
      case "Adddomains":
        return <Adddomains props={this.props} />;
      case "analytics":
        return <Analytics props={this.props} />;
      case "reports":
        return <Reports props={this.props} />;
      case "agreements":
        return <Agreements props={this.props} />;
      case "themes":
        return <Themes props={this.props} />;
      case "domains":
        return <Domains props={this.props} />;
      case "Editor":
        return <Editor props={this.props} />;
      case "PaymentsHistory":
        return <PaymentsHistory props={this.props} />;
      case "Payouts":
        return <Payouts props={this.props} />;
      case "CommissionReport":
        return <CommissionReport props={this.props} />;
      case "CommissionSettings":
        return <CommissionSettings props={this.props} />;  
      case "sharelinks":
        return <Sharelinks props={this.props} />;
      case "sharelink_settings":
        return <SharelinkSettings props={this.props} />;
      case "sharelink_analytics":
        return <SharelinkAnalytics props={this.props} />;
      case "add_sharelink":
        return <AddSharelink props={this.props} />;
      case "agents":
        return <Agents props={this.props} />;
      case "Addagent":
        return <Addagent props={this.props} />;
      case "upgrades":
        return <Upgrades props={this.props} />;
      case "back office":
        return <BackOffice props={this.props} />;
      case "msa_signing":
        return <MSA />;
      default:
        return <ErrorPage />;
    }
  };

  /*pageView = () => {
    console.log("PAGE PROP " + this.state.view);

    if (this.state.view === "dashboard") {
      return <Dashboard props={this.props} />;
    } else if (this.state.view === "clients") {
      return <Clients props={this.props} />;
    } else if (this.state.view === "clients_queue") {
      return <ClientsQueue props={this.props} />;
    } else if (this.state.view === "services") {
      return <Services props={this.props} />;
    } else if (this.state.view === "cms") {
      return <Cms props={this.props} />;
    } else if (this.state.view === "partner_quotations") {
      return <PartnerQuotations props={this.props} />;
    } else if (this.state.view === "EditQuotations") {
      return <EditQuotations props={this.props} />;
    } else if (this.state.view === "partner_rfqs") {
      return <PartnerRfqs props={this.props} />;
    } else if (this.state.view === "partner_orders") {
      return <PartnerOrders props={this.props} />;
    } else if (this.state.view === "ViewSalesOrder") {
      return <ViewSalesOrder props={this.props} />;
    } else if (this.state.view === "EditSalesOrder") {
      return <EditSalesOrder props={this.props} />;
    } else if (this.state.view === "documents") {
      return <Documents props={this.props} />;
    }  else if (this.state.view === "Pageslist") {
      return <Pageslist props={this.props} />;
    } else if (this.state.view === "Addpages") {
      return <Addpages props={this.props} />;
    } else if (this.state.view === "Editpage") {
      return <Editpage props={this.props} />;
    } else if (this.state.view === "Pdfdownload") {
      return <Pdfdownload props={this.props} />;
    } else if (this.state.view === "UnsignedAgreements") {
      return <UnsignedAgreements props={this.props} />;
    } else if (this.state.view === "Editmenu") {
      return <Editmenu props={this.props} />;
    } else if (this.state.view === "Menuslist") {
      return <Menuslist props={this.props} />;
    } else if (this.state.view === "Addmenus") {
      return <Addmenus props={this.props} />;
    } else if (this.state.view === "Addheader") {
      return <Addheader props={this.props} />;
    } else if (this.state.view === "Headerlist") {
      return <Headerlist props={this.props} />;
    } else if (this.state.view === "Websettings") {
      return <Websettings props={this.props} />;
    } else if (this.state.view === "Addservice") {
      return <Addservice props={this.props} />;
    } else if (this.state.view === "Adddomains") {
      return <Adddomains props={this.props} />;
    } else if (this.state.view === "analytics") {
      return <Analytics props={this.props} />;
    } else if (this.state.view === "reports") {
      return <Reports props={this.props} />;
    } else if (this.state.view === "agreements") {
      return <Agreements props={this.props} />;
    } else if (this.state.view === "themes") {
      return <Themes props={this.props} />;
    } else if (this.state.view === "domains") {
      return <Domains props={this.props} />;
    } else if (this.state.view === "Editor") {
      return <Editor props={this.props} />;
    } else if (this.state.view === "PaymentsHistory") {
      return <PaymentsHistory props={this.props} />;
    } else if (this.state.view === "Payouts") {
      return <Payouts props={this.props} />;
    } else if (this.state.view === "CommissionReport") {
      return <CommissionReport props={this.props} />;
    } else if (this.state.view === "sharelinks") {
      return <Sharelinks props={this.props} />;
    } else if (this.state.view === "sharelink_settings") {
      return <SharelinkSettings props={this.props} />;
    } else if (this.state.view === "sharelink_analytics") {
      return <SharelinkAnalytics props={this.props} />;
    } else if (this.state.view === "add_sharelink") {
      return <AddSharelink props={this.props} />;
    } else if (this.state.view === "agents") {
      return <Agents props={this.props} />;
    } else if (this.state.view === "Addagent") {
      return <Addagent props={this.props} />;
    } else if (this.state.view === "upgrades") {
      return <Upgrades props={this.props} />;
    } else if (this.state.view === "back office") {
      return <BackOffice props={this.props} />;
    }
    if (this.state.view === "msa_signing") {
      return <MSA />;
    } else {
      return <ErrorPage />;
    }
  };*/

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  pageChange = (data) => {
    this.props.history.push("/" + data);
  };

  profileView = () => {
    this.setState({ successMessage: "", errorMessage: "" });
    window.$("#modalFormProfile").modal("show");
  };

  render() {
    return (
      <div className="nk-body bg-lighter npc-general has-sidebar ">
        <div className="nk-app-root">
          <div className="nk-main">
            {auth.isLogin() !== false ? (
              <>
                {this.state.defaultLoader === true ? (
                  <div>
                    <div>
                      {this.state.is_msa_signed === true ? (
                        <LefeSideBar
                          mainProps={this.props}
                          menuArr={this.props.leftMenuItems}
                          routingUrl={this.state.routingUrl}
                          {...this.props}
                        />
                      ) : null}
                    </div>
                    <div>{/*<DashboardLoader />*/}</div>
                  </div>
                ) : (
                  <div>
                    {this.state.is_msa_signed === true ? (
                      <LefeSideBar
                        mainProps={this.props}
                        menuArr={this.props.leftMenuItems}
                        routingUrl={this.state.routingUrl}
                        {...this.props}
                      />
                    ) : null}
                  </div>
                )}
              </>
            ) : null}

            <div className="nk-wrap ">
              <Header
                isNdaSigned={this.state.is_msa_signed}
                modalOpen={() => {
                  this.profileView();
                }}
                modalLogin={() => {
                  this.loginDetailView();
                }}
              />

              {this.state.is_msa_signed === true ? (
                this.pageView()
              ) : (
                <Agreement
                  backgroundColor={this.state.bgColor}
                  vendor_signor_signature_file={
                    this.state.vendor_signor_signature_file
                  }
                  mainHistory={this.props}
                  agreementSigned={() => this.agreementSigned()}
                />
              )}
              {/* MODAL EDIT PROFILE START */}
              <div className="modal fade" tabIndex="-1" id="modalFormProfile">
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Profile</h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: this.state.errorMessage,
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />

                      <div className="row g-4">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Company Name"
                              error={this.state.errorCompName}
                              id="comp_name"
                              name="comp_name"
                              value={this.state.comp_name}
                              placeholder="Company Name"
                              change={this.handleChange}
                              type="text"
                            />
                            <small
                              className="form-text text-muted"
                              style={{
                                position: "absolute",
                                top: "80px",
                                color: "#555",
                                fontSize: "0.75rem",
                                fontWeight: "400",
                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                              }}
                            >
                              Allowed characters: (&nbsp; _ &nbsp; . &nbsp; - &nbsp; , &nbsp; &amp; &nbsp; ( ) )
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Full Name"
                              error={this.state.errorName}
                              id="name"
                              name="name"
                              value={this.state.name}
                              placeholder="Full Name"
                              change={this.handleChange}
                            />
                            <small
                              className="form-text text-muted"
                              style={{
                                position: "absolute",
                                top: "80px",
                                color: "#555",
                                fontSize: "0.75rem",
                                fontWeight: "400",
                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                              }}
                            >
                              Allowed characters: (&nbsp; ' &nbsp; - &nbsp; . &nbsp; , )
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Title"
                              error={this.state.errorTitle}
                              id="title"
                              name="title"
                              value={this.state.title}
                              placeholder="Title"
                              change={this.handleChange}
                            />
                            <small
                              className="form-text text-muted"
                              style={{
                                position: "absolute",
                                top: "80px",
                                color: "#555",
                                fontSize: "0.75rem",
                                fontWeight: "400",
                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                              }}
                            >
                              Allowed characters: (&nbsp; _ &nbsp; - &nbsp; . &nbsp; &amp; &nbsp; , &nbsp; ( ) )
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Email"
                              type="text"
                              error={this.state.errorEmail}
                              id="email"
                              name="email"
                              value={this.state.email}
                              placeholder="Enter your email address"
                              change={this.handleChange}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          {!this.state.isLoading && (
                            <PhoneInput
                              required
                              defaultCountry={"us"}
                              inputProps={{
                                name: "phone",
                                id: "phone",
                                maxLength: 20,
                              }}
                              style={{ marginTop: "30px" }}
                              value={this.state.phone}
                              onChange={(value) =>
                                this.handleChange({
                                  target: {
                                    name: "phone",
                                    value,
                                  },
                                })
                              }
                              helperText="Enter the phone number."
                              label="Agent Phone Number"
                              ref={this.phoneInputRef}
                              onFocus={this.handleFocus}
                            />
                          )}
                        </div>
                        <div
                          id="profileUpdate"
                          className="form-group col-md-4"
                          style={{ marginBottom: "0px" }}
                        >
                          <div class="nk-block-between-md">
                            <div class="nk-block-head-content">
                              <label
                                htmlFor="googleLocation-autocomplete"
                                style={{
                                  color: "black",
                                  fontWeight: "500",
                                  marginBottom: "8px",
                                }}
                              >
                                Address
                              </label>
                            </div>
                            <div class="nk-block-head-content">
                              <ul class="nk-block-tools gx-3">
                                <li>
                                  <a
                                    onClick={this.handleToggle}
                                    class="text-primary fs-11"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {this.state.toggleButtonText}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {this.state.useGooglePlaces ? (
                            <GooglePlacesAutocomplete
                              apiKey={
                                process.env
                                  .REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                              }
                              selectProps={{
                                value: this.state.address
                                  ? {
                                      label: this.state.address,
                                      value: this.state.address,
                                    }
                                  : null,
                                onChange: this.handleAddressSelect,
                                placeholder: this.state.address
                                  ? null
                                  : "Enter your address..",
                                styles: {
                                  input: (provided) => ({
                                    ...provided,
                                    color: "black",
                                    height: "35px",
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    color: "black",
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: "black",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9,
                                  }),
                                },
                              }}
                              className="googleLocation-autocomplete customCountrySelect.css-b62m3t-container"
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              name="address"
                              value={this.state.address}
                              placeholder="Enter Po Box address"
                              onChange={this.handleChange}
                              style={{
                                borderColor: this.state.errorAddress
                                  ? "red"
                                  : "initial",
                                height: "45px",
                                border: "1px solid #dbdfea",
                                fontSize: "0.9375rem",
                              }}
                            />
                          )}
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Apartment, Suite, Etc."
                              type="text"
                              error={this.state.errorAddress2}
                              id="address2"
                              name="address2"
                              value={this.state.address2}
                              placeholder="Apartment, Suite, Etc."
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="City"
                              type="text"
                              error={this.state.errorCity}
                              id="city"
                              name="city"
                              value={this.state.city}
                              placeholder="Enter your city"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="State"
                              type="text"
                              error={this.state.errorState}
                              id="states"
                              name="states"
                              value={this.state.states}
                              placeholder="Enter your state"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <TextField
                              label="Zip Code"
                              type="text"
                              error={this.state.errorZip}
                              id="zip"
                              name="zip"
                              value={this.state.zip}
                              placeholder="Enter your zip code"
                              change={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p
                              className="MuiFormHelperText-root MuiFormHelperText-contained Mui-required"
                              style={{
                                fontSize: "0.875rem",
                                fontWeight: 500,
                                color: "#344357",
                                marginBottom: ".5rem",
                              }}
                            >
                              Select country
                            </p>
                            <Select
                              className="customStyle-country"
                              options={this.countryOptions}
                              value={{
                                label: this.state.country,
                                value: this.state.country,
                              }}
                              onChange={this.handleChangeCountry}
                              placeholder="Select a country"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  minHeight: "45px",
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div
                            className="form-group"
                            style={{ position: "relative" }}
                          >
                            <TextField
                              label="Password"
                              type={
                                this.state.passwordShown ? "text" : "password"
                              }
                              error={this.state.errorPass}
                              id="password"
                              name="password"
                              value={this.state.password}
                              placeholder="Enter your password"
                              change={this.handleChange}
                            />
                            <a
                              style={{
                                left: "auto",
                                right: "2px",
                                top: "53px",
                                position: "absolute",
                                cursor: "pointer",
                              }}
                              tabIndex="-1"
                              onClick={this.togglePassword}
                              className="form-icon form-icon-right passcode-switch"
                              data-target="password"
                            >
                              {this.state.passwordShown ? (
                                <em className="icon ni ni-eye-off"></em>
                              ) : (
                                <em className="icon ni ni-eye"></em>
                              )}
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div
                            className="form-group"
                            style={{ position: "relative" }}
                          >
                            <TextField
                              label="Confirm Password"
                              type={
                                this.state.confirmPasswordShown
                                  ? "text"
                                  : "password"
                              }
                              error={this.state.errorPass}
                              id="confirm_password"
                              name="confirm_password"
                              value={this.state.confirm_password}
                              placeholder="Enter confirm password"
                              change={this.handleChange}
                            />
                            <a
                              style={{
                                left: "auto",
                                right: "2px",
                                top: "53px",
                                position: "absolute",
                                cursor: "pointer",
                              }}
                              tabIndex="-1"
                              onClick={() => {
                                this.toggleConfirmPassword();
                              }}
                              className="form-icon form-icon-right passcode-switch"
                              data-target="password"
                            >
                              {this.state.confirmPasswordShown ? (
                                <em className="icon ni ni-eye-off"></em>
                              ) : (
                                <em className="icon ni ni-eye"></em>
                              )}
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-4">&nbsp;</div>
                        <div style={{ clear: "both" }}></div>
                        {/* <div className="form-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <div className="form-control-wrap">
                          <a
                            tabIndex="-1"
                            href="#"
                            className="form-icon form-icon-right passcode-switch"
                            data-target="password"
                          >
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                            <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                          </a>
                          <input
                            type="password"
                            className={
                              "form-control form-control-lg " +
                              this.state.errorPass
                            }
                            id="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            placeholder="Enter your password"
                          />
                        </div>
                      </div> */}
                        <div className="col-lg-12 text-right">
                          <button
                            type="button"
                            className="btn btn-lg"
                            onClick={() => {
                              this.formUpdate();
                            }}
                            style={{
                              backgroundColor: THEME_COLOR,
                              color: THEME_TEXT_COLOR,
                              float: "right",
                            }}
                            disabled={this.state.disabled}
                          >
                            Update Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END MODAL EDIT PROFILE */}
              
{/* { MODAL LOGIN DETAILS } */}
<div
                className="modal fade"
                id="modalLoginDetail"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalLoginDetailLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl modal-dialog-top" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="modalLoginDetailLabel">
                        Login Details
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{
                          outline: "none",
                          boxShadow: "none",
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {/* Table to Display Login Details */}
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="thead-light">
                            <tr>
                              <th>IP Address</th>
                              <th>Country</th>
                              <th>State</th>
                              <th>City</th>
                              <th>Timezone</th>
                              <th>Login Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.loginDetails && this.state.loginDetails.length > 0 ? (
                              this.state.loginDetails.map((detail, index) => (
                                <tr key={index}>
                                  <td>{detail.ip_address}</td>
                                  <td>{detail.country}</td>
                                  <td>{detail.state}</td>
                                  <td>{detail.city}</td>
                                  <td>{detail.time_zone}</td>
                                  <td>
                                    {new Date(detail.login_time).toLocaleDateString('en-US', {
                                      month: 'long',
                                      day: 'numeric',
                                      year: 'numeric',
                                    }).replace(/(\d+)(?=,)/, '$1th')}, {new Date(detail.login_time).toLocaleTimeString('en-US')}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  No login details found.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* { MODAL LOGIN DETAILS } */}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state,
    leftMenuItems: state.leftMenuItems,
    quotationSettings: state.quotationSettings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
    addMsaInfo: (payload) => dispatch(addMsaInfo(payload)),
    setLeftMenu: (menuItems) => dispatch(setLeftMenu(menuItems)),
    setQuotationSettings: (quotationsettings) =>
      dispatch(setQuotationSettings(quotationsettings)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
