import { React, createRef, Component } from "react";

import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { Helmet, HelmetProvider } from "react-helmet-async";

import ReCAPTCHA from "react-google-recaptcha";

import {
  registerClient,
  checkAgentAccount,
  resendClientVerificationEmail,
  verifyFilerID,
} from "../../../config/client_api_calls";

import $ from "jquery";
import { checkAgent } from "./../../../config/api_calls";
import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";
import {
  COMPANY_NAME,
  LOGO,
  THEME_COLOR,
  APP_URL,
} from "../../../config/config";
import { ValidateEmail, axiosConfig, normalize } from "../../../config/utility";

import { PhoneInput } from "react-international-phone";
import Select from "react-select";
import countryList from "react-select-country-list";

//import { resendCodeFun } from "../../../config/api_calls";

import TextField from "./TextField";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/FormLoader";
import { ThreeDots } from "../../../components/ContentLoaders/newLoaders/ThreeDots";
import HelperClass from "./../../../config/helperClass";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

import { GoogleLogin } from "@react-oauth/google";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { PhoneNumberUtil } from "google-libphonenumber";

import Step1 from "../../../components/Client/ClientRegister/Step1";
import Step2 from "../../../components/Client/ClientRegister/Step2";
import Step3 from "../../../components/Client/ClientRegister/Step3";
import Step4 from "../../../components/Client/ClientRegister/Step4";
import Step5 from "../../../components/Client/ClientRegister/Step5";
import Step6 from "../../../components/Client/ClientRegister/Step6";
const auth = new HelperClass();

export default class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      company_name: "",
      title: "",
      email: "",
      password: "",
      errorEmail: "",
      errorPass: "",
      errorcPass: "",
      errorName: "",
      errorMessage: "",
      errorCompName: "",
      errorTitle: "",
      disabled: false,
      successMessage: "",
      confirm_password: "",
      errorConfirm: "",
      disableResendBtn: false,
      gifLoader: false,
      phone: "",
      errorPhone: "",
      fieldsHide: false,
      show_form: false,
      resellerData: [],
      tableLoader: true,
      imageLoader: true,
      res_accountno: "",
      res_title: "",
      super_agent_accountno: "",
      agent_title: "",
      action_type: "",
      pageError: "",
      res_url: "",
      url_type: "Simple",
      step: 1,
      filer_id: "",
      select_499: false,
      no_of_employes: "",
      service_id: "",
      service_type: "",
      packagesData: [],
      servicesData: [],
      portingData: [],
      miscData: [],
      subscriptio_type: "",
      passwordShown: false,
      confirmPasswordShown: false,
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      clientRegisteredEmail: "",
      clientRegisteredAccountno: "",
      afterRegistration: false,
      country: null,
      social_register: false,
      ssoSuccessMessage: "",
      errorName: "",
      errorClientCompany: "",
      errorClientEmail: "",
      errorClientPhone: "",
      errorClientAddress: "",
      errorClientCity: "",
      errorClientState: "",
      errorClientZip: "",
      errorClientCountry: "",
      errorClientPassword: "",
      errorCPassword: "",

      errorClientFilerID: "",
      filderIdPermission: "",
      filerIDRequired: "",
      filerData: [],
      filerAddress: "",
      filerCompany: "",
      filerZip: "",
      filerState: "",
      filerCity: "",
      filerCountry: "",
      filerPhone: "",
      verifying: false,
      filerVerified: false,
      show_po_box: false,
      useGooglePlaces: true,
      toggleButtonText: "Allow PO Box Address",
      registerType: "",

      policyCheckBox: false,
      policyCheckBox2: false,
      policyCheckBoxError: false,
    };
    this.site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    this.captcha_token = null;
    this.countryOptions = countryList()
      .getData()
      .filter(
        (country) => country.label !== "United States Minor Outlying Islands"
      );
    this.phoneInputRef = createRef();
  }

  async componentDidMount() {
    const {
      signup_hash,
      serviceid: service_id,
      servicetype,
    } = this.props.match.params;
    let registerType = "simple";
    if (servicetype && servicetype !== "") {
      registerType = "service";
      this.handleServiceType(service_id);
    } else if (service_id && service_id !== "") {
      if (this.isSharelinkHash(service_id)) {
        registerType = "sharelink";
      } else {
        this.handleServiceType(service_id);
        registerType = "service";
      }
    }
    this.setState({
      registerType: registerType,
    });
    /*let signup_hash = this.props.match.params.signup_hash;
    let service_id = this.props.match.params.serviceid;
    let servicetype = this.props.match.params.servicetype;
    if (service_id && service_id !== "") {
      let decodedServiceId = Buffer.from(service_id, "base64").toString(
        "utf-8"
      );
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(decodedServiceId)) {
        this.setState({
          email: decodedServiceId,
        });
      }
    }*/

    /*if (servicetype === "package") {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        pageError: "Package based registration is coming soon.",
      });
    } else {*/
    if (signup_hash !== "") {
      //Errors
      //404 and no_reseller
      //404 and no_agent
      let action_type = "client";
      let urlElements = window.location.href.split("/");
      console.log("CUrrent URL: ", urlElements[0] + "//" + urlElements[2]);
      let host_url = urlElements[2];
      if (service_id && service_id !== "") {
        this.setState({
          service_id: service_id,
        });
      }
      if (!servicetype && service_id === "") {
        servicetype = "";
      }
      try {
        let response = await checkAgentAccount({
          signup_hash: signup_hash,
          action_type: action_type,
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          host_url: host_url,
          service_id: service_id,
          servicetype: servicetype,
          registerType: registerType,
        });
        console.log("Agent.response.data : ", response.data);
        if (
          response.data.status === 404 ||
          response.data.status === 422 ||
          response.data.errors !== ""
        ) {
          this.setState({
            errorMessage: "There is some error.",
            successMessage: "",
            show_form: false,
            pageError: "",
          });
        }

        if (
          response.data.status === 404 &&
          response.data.message === "no_reseller"
        ) {
          this.setState({
            errorMessage: "",
            pageError:
              "Agent does not exists. Please contact support or try again with correct URL.",
            successMessage: "",
            show_form: false,
          });
        } else if (
          response.data.status === 404 &&
          response.data.message === "no_agent"
        ) {
          this.setState({
            pageError:
              "Agent does not exists. Please contact support or try again with correct URL.",
            errorMessage: "",
            successMessage: "",
            show_form: false,
          });
        } else if (
          response.data.status === 404 &&
          response.data.message === "sharelink_not_found"
        ) {
          this.setState({
            pageError: "",
            errorMessage:
              "Sharelink does not exists. Please contact support or try again with correct URL.",
            successMessage: "",
            show_form: false,
          });
        } else if (response.data.status === 403) {
          this.setState({
            errorMessage: "There is some error.",
            successMessage: "",
            show_form: false,
            pageError: "",
          });
        } else if (
          response.data.status === 200 &&
          response.data.message === "success"
        ) {
          let session_userName = localStorage.getItem("userName");
          let session_userEmail = localStorage.getItem("userEmail");
          let session_userSub = localStorage.getItem("userSub");
          let session_authProvider = localStorage.getItem("authProvider");
          const isSocialRegister =
            session_userName !== null &&
            session_userName !== "" &&
            session_userEmail !== null &&
            session_userEmail !== "" &&
            session_userSub !== null &&
            session_userSub !== "" &&
            session_authProvider !== null &&
            session_authProvider !== "";
          if (isSocialRegister) {
            const random_password = this.generateStrongPassword(12);
            this.setState({
              name: session_userName,
              email: session_userEmail,
              social_register: true,
              password: random_password,
              confirm_password: random_password,
            });
          }
          Cookies.set(
            "restkn__",
            CryptoJS.AES.encrypt(
              signup_hash,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "resacct__",
            CryptoJS.AES.encrypt(
              response.data.res_accountno,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "resregtype__",
            CryptoJS.AES.encrypt(
              response.data.action_type,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "restitle__",
            CryptoJS.AES.encrypt(
              response.data.reseller_title,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "agttitle__",
            CryptoJS.AES.encrypt(
              response.data.agent_title,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "agtacctno__",
            CryptoJS.AES.encrypt(
              response.data.agent_accountno,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "reslogo__",
            CryptoJS.AES.encrypt(
              response.data.reseller_logo,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "ressig__",
            CryptoJS.AES.encrypt(
              response.data.reseller_signature,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "residentifier__",
            CryptoJS.AES.encrypt(
              response.data.reseller_domain_identifier,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "urltype__",
            CryptoJS.AES.encrypt(
              response.data.url_type,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "clientserviceid__",
            CryptoJS.AES.encrypt(
              service_id,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "clientservicetype__",
            CryptoJS.AES.encrypt(
              servicetype,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          if (servicetype === "service") {
            this.setState({
              errorMessage: "",
              res_accountno: response.data.res_accountno,
              res_title: response.data.reseller_title,
              resellerData: response.data,
              tableLoader: false,
              imageLoader: false,
              show_form: true,
              agent_title: response.data.agent_title,
              super_agent_accountno: response.data.agent_accountno,
              action_type: action_type,
              pageError: "",
              servicesData: response.data.servicesListing,
              service_type: response.data.servicesListing[0].service_type,
              portingData: response.data.portingListing,
              miscData: response.data.miscListing,
              packagesData: [],
              subscriptio_type: servicetype,
              filderIdPermission:
                response.data.filderIdPermissions[0]["fillerID"],
              filerIDRequired:
                response.data.filderIdPermissions[0]["isRequired"],
            });
          } else if (servicetype === "package") {
            this.setState({
              errorMessage: "",
              res_accountno: response.data.res_accountno,
              res_title: response.data.reseller_title,
              resellerData: response.data,
              tableLoader: false,
              imageLoader: false,
              show_form: true,
              agent_title: response.data.agent_title,
              super_agent_accountno: response.data.agent_accountno,
              action_type: action_type,
              pageError: "",
              servicesData: [],
              service_type: "",
              portingData: [],
              miscData: [],
              packagesData: response.data.data,
              subscriptio_type: servicetype,
              filderIdPermission:
                response.data.filderIdPermissions[0]["fillerID"],
              filerIDRequired:
                response.data.filderIdPermissions[0]["isRequired"],
            });
          } else {
            this.setState({
              errorMessage: "",
              res_accountno: response.data.res_accountno,
              res_title: response.data.reseller_title,
              resellerData: response.data,
              tableLoader: false,
              imageLoader: false,
              show_form: true,
              agent_title: response.data.agent_title,
              super_agent_accountno: response.data.agent_accountno,
              action_type: action_type,
              pageError: "",
              servicesData: [],
              service_type: "",
              portingData: [],
              miscData: [],
              packagesData: [],
              subscriptio_type: "",
              filderIdPermission:
                response.data.filderIdPermissions[0]["fillerID"],
              filerIDRequired:
                response.data.filderIdPermissions[0]["isRequired"],
            });
          }
        }
      } catch (error) {
        this.setState({
          errorMessage: "There is some error.",
          successMessage: "",
          show_form: false,
          pageError: "",
        });
        console.log("catch : ", error);
      }
    } else {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
    }
    //}
  }

  isSharelinkHash = (hash) => {
    const Regex = /^[a-f0-9]{32}$/i;
    return Regex.test(hash);
  };
  handleServiceType = (service_id) => {
    const decodedServiceId = this.decodeBase64(service_id);
    if (this.isValidEmail(decodedServiceId)) {
      this.setState({ email: decodedServiceId });
    }
  };

  decodeBase64 = (encodedStr) => {
    return Buffer.from(encodedStr, "base64").toString("utf-8");
  };

  isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      useGooglePlaces: !prevState.useGooglePlaces,
      toggleButtonText:
        prevState.toggleButtonText === "Allow PO Box Address"
          ? "Allow Simple Address"
          : "Allow PO Box Address",
    }));
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  handleChangeRadio = (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    //console.log("Main Handler: ", name + " - " + value);
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const country = event.label;
    this.setState({
      country: country,
    });
  };

  handleFocus = () => {
    // Move cursor to the end of the input value
    const input = this.phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };

  handleCaptchaChange = (value) => {
    this.captcha_token = value;
  };

  handleChange = (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    if (name === "name") {
      value = value.replace(/[^a-zA-Z\s'.,-]/g, "");
    } else if (name === "company_name") {
      value = value.replace(/[^a-zA-Z0-9\s\-_.,&()]/g, "");
    }
    this.setState({
      [name]: value,
    });
    //console.log("Main Handler Radio: ", name + " - " + value);
  };

  handleChangeName = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    value = value.replace(/[^a-zA-Z0-9\s]/g, "");
    this.setState({
      [name]: value,
    });
    //console.log("Main Handler Radio: ", name + " - " + value);
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
    console.log("STEP: ", this.state.step);
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  gotoLogin = () => {
    window.location.href = `${auth.getResellerRegToken()}/client/login`;
  };

  resendEmail = async () => {
    const { clientRegisteredAccountno, clientRegisteredEmail } = this.state;

    this.setState({
      errorMessage: "",
      successMessage: "",
      disableResendBtn: true,
      gifLoader: true,
    });
    const resendEmail = await resendClientVerificationEmail({
      token: process.env.REACT_APP_API_TOKEN,
      api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
      email: clientRegisteredEmail,
      accountno: clientRegisteredAccountno,
    });

    console.log("resend email ", resendEmail.data);
    if (
      resendEmail.data.status === 404 ||
      resendEmail.data.status === 422 ||
      resendEmail.data.errors !== ""
    ) {
      this.setState({
        errorMessage: "There is some error.",
        successMessage: "",
        show_form: false,
        pageError: "",
      });
    }

    if (resendEmail.data.status === 404) {
      this.setState({
        errorMessage: resendEmail.data.errors,
        successMessage: "",
        show_form: false,
      });
    } else if (
      resendEmail.data.status === 200 &&
      resendEmail.data.message === "Success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage:
          "Email sent to your account Successfully. To complete verification process please go to your inbox and click on  verification link in the email.",
        disableResendBtn: false,
        gifLoader: false,
      });
      setTimeout(() => {
        this.setState({
          successMessage: "",
          errorMessage: "",
        });
      }, 5000);
    }
  };

  formSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    let { oauth_identifier, authProvider } = "";
    oauth_identifier = localStorage.getItem("userSub");
    authProvider = localStorage.getItem("authProvider");
    let {
      name,
      email,
      password,
      confirm_password,
      phone,
      company_name,
      title,
      filer_id,
      address,
      address2,
      city,
      state,
      zip,
      country,
      no_of_employes,
      service_id,
      super_agent_accountno,
      res_accountno,
      service_type,
      subscriptio_type,
      registerType,
    } = this.state;
    var is_validated = true;
    this.setState({
      errorMessage: "",
      errorMessage: "",
      errorSelectAgent: "",
      errorName: "",
      errorClientCompany: "",
      errorClientEmail: "",
      errorClientPhone: "",
      errorClientAddress: "",
      errorClientCity: "",
      errorClientState: "",
      errorClientZip: "",
      errorClientCountry: "",
      errorClientPassword: "",
      errorCPassword: "",
      errorClientFilerID: "",
      policyCheckBoxError: false,
    });
    this.setState({
      errorMessage: "",
    });
    const nameRegex = /^[a-zA-Z\s]+$/;
    const compRegex = /^[a-zA-Z0-9\s -]+$/;

    if (
      this.state.filerIDRequired === 1 &&
      filer_id === "" &&
      this.state.filderIdPermission === 1
    ) {
      this.setState({
        errorClientFilerID: "error",
        errorMessage: "Please enter the valid filer ID.",
      });
      is_validated = false;
    } else if (
      this.state.filerIDRequired === 1 &&
      !this.state.filerVerified &&
      this.state.filderIdPermission === 1
    ) {
      this.setState({
        errorClientFilerID: "error",
        errorMessage: "Filer ID must be verified.",
      });
      is_validated = false;
    } else if (this.state.name === "") {
      this.setState({
        errorName: "error",
        errorMessage: "Client Name is required.",
      });
      is_validated = false;
    } /*else if (!nameRegex.test(name)) {
      this.setState({
        errorName: "error",
        errorMessage: "Full Name should not contain numbers or special characters.",
      });
      is_validated = false;
    }*/ else if (company_name === "") {
      this.setState({
        errorClientCompany: "error",
        errorMessage: "Please enter the company name.",
      });
      is_validated = false;
    } /*else if (!compRegex.test(company_name)) {
      this.setState({
        errorClientCompany: "error",
        errorMessage: "Company Name should not contain special characters.",
      });
      is_validated = false;
    }*/ else if (email === "") {
      this.setState({
        errorClientEmail: "error",
        errorMessage: "Please enter the valid email.",
      });
      is_validated = false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorClientEmail: "error",
        errorMessage: "Invalid email format.",
      });
      is_validated = false;
    } else if (phone === "") {
      this.setState({
        errorClientPhone: "error",
        errorMessage: "Please enter the valid phone number.",
      });
      is_validated = false;
    } else if (!this.isPhoneValid(phone)) {
      this.setState({
        errorClientPhone: "error",
        errorMessage: "Invalid phone number.",
      });
      return false;
    } else if (address === "") {
      this.setState({
        errorClientAddress: "error",
        errorMessage: "Please enter the Address.",
      });
      is_validated = false;
    } else if (city === "") {
      this.setState({
        errorClientCity: "error",
        errorMessage: "Please enter the City.",
      });
      is_validated = false;
    } else if (state === "") {
      this.setState({
        errorClientState: "error",
        errorMessage: "Please enter the State.",
      });
      is_validated = false;
    } else if (zip === "") {
      this.setState({
        errorClientZip: "error",
        errorMessage: "Please enter the Zip.",
      });
      is_validated = false;
    } else if (country === "") {
      this.setState({
        errorClientCountry: "error",
        errorMessage: "Please select the Country.",
      });
      is_validated = false;
    } else if (password === "") {
      this.setState({
        errorClientPassword: "error",
        errorMessage: "Please enter the password.",
      });
      is_validated = false;
    } else if (password.length < 8) {
      this.setState({
        errorClientPassword: "error",
        errorMessage: "Use 8 characters or more for your password",
      });
      is_validated = false;
    } else if (confirm_password === "") {
      this.setState({
        errorCPassword: "error",
        errorMessage: "Confirm password is required.",
      });
      is_validated = false;
    } else if (confirm_password.length < 8) {
      this.setState({
        errorCPassword: "error",
        errorMessage: "Use 8 characters or more for your confirm password",
      });
      is_validated = false;
    } else if (password !== confirm_password) {
      this.setState({
        errorCPassword: "error",
        errorMessage: "Password and confirm password does not match.",
      });
      is_validated = false;
    } else if (!this.state.policyCheckBox) {
      this.setState({
        errorMessage: "Please agree to the Privacy Policy.",
        policyCheckBoxError: true,
      });
      is_validated = false;
    } else if (!this.state.social_register) {
      if (this.captcha_token === "" || this.captcha_token === null) {
        this.setState({
          errorMessage: "Invalid captcha.",
        });
        is_validated = false;
      }
    }

    if (!is_validated) {
      setTimeout(() => {
        this.setState({
          errorMessage: "",
          errorSelectAgent: "",
          errorName: "",
          errorClientCompany: "",
          errorClientEmail: "",
          errorClientPhone: "",
          errorClientAddress: "",
          errorClientCity: "",
          errorClientState: "",
          errorClientZip: "",
          errorClientCountry: "",
          errorClientPassword: "",
          errorCPassword: "",
          errorClientFilerID: "",
          policyCheckBoxError: false,
        });
      }, 4000);
    }

    if (is_validated) {
      try {
        this.setState({
          disabled: true,
        });
        let completeAddress = address + " " + address2;
        let { sharelink_hash } = this.props.match.params;
        sharelink_hash =
          sharelink_hash && sharelink_hash !== "" ? sharelink_hash : "";
        let clientRegisterService = await registerClient({
          name: name,
          email: email,
          password: password,
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          agent_accountno: super_agent_accountno,
          phone: phone,
          comp_name: company_name,
          title: title,
          filer_id: filer_id,
          no_of_employes: no_of_employes,
          service_id: service_id,
          res_accountno: res_accountno,
          service_type: service_type,
          subscriptio_type: subscriptio_type,
          address: completeAddress,
          city: city,
          state: state,
          zip: zip,
          country: country,
          oauth_identifier: oauth_identifier,
          authProvider: authProvider,
          registerType: registerType,
          sharelink_hash: sharelink_hash,
          market_consent_policy: this.state.policyCheckBox2.toString(),
        });
        console.log("response.data : ", clientRegisterService.data);
        if (
          clientRegisterService.data.status === 404 ||
          clientRegisterService.data.status === 422 ||
          clientRegisterService.data.errors !== ""
        ) {
          this.setState({
            errorMessage: "Invalid Email OR Password",
            disabled: false,
          });
        }

        if (
          clientRegisterService.data.status === 404 &&
          clientRegisterService.data.message === "Account Already Exists"
        ) {
          this.setState({
            errorMessage: clientRegisterService.data.message,
            disabled: false,
          });
        } else if (
          clientRegisterService.data.status === 409 &&
          clientRegisterService.data.message === "Address validation failed"
        ) {
          const formattedErrorMessage = clientRegisterService.data.errors
            ? String(clientRegisterService.data.errors).replace(/,/g, "<br />")
            : "Address validation failed";
          this.setState({
            errorMessage: formattedErrorMessage,
            disabled: false,
          });
        } else if (
          clientRegisterService.data.status === 200 &&
          clientRegisterService.data.message === "Success"
        ) {
          $("#resendBtn").show();
          $("#signupBtn").hide();
          localStorage.removeItem("userName");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("authProvider");
          localStorage.removeItem("userSub");
          $("#stepper-create-profile input").val("");
          this.setState({
            successMessage: ` Congrats! Successfully created your profile. Email
                sent to your account. To complete verification
                process please go to your inbox and click on
                verification link in the email.`,
            errorMessage: "",
            fieldsHide: true,
            disabled: false,
            afterRegistration: true,
            clientRegisteredAccountno:
              clientRegisterService.data.data.accountno,
            clientRegisteredEmail: clientRegisterService.data.data.email,
          });
          setTimeout(() => {
            this.setState({
              successMessage: "",
              errorMessage: "",
            });
          }, 5000);
        } else if (
          clientRegisterService.data.status === 200 &&
          clientRegisterService.data.message === "Success_sso"
        ) {
          //$("#resendBtn").show();
          $("#signupBtn").hide();
          localStorage.removeItem("userName");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("authProvider");
          localStorage.removeItem("userSub");
          setTimeout(() => {
            window.location.href = clientRegisterService.data.redirect_url;
          }, 1000);
        } else {
          this.setState({
            errorMessage: ` There is some error while creating the account.`,
            successMessage: "",
            fieldsHide: true,
          });
        }
      } catch (error) {
        console.log("catch : ", error);
        this.setState({
          errorMessage: "There is some error while creating the account.",
          disabled: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        errorMessage: "",
      });
    }, 4000);
  };

  submitForms = async () => {
    window.scrollTo(0, 0);
    const {
      name,
      company_name,
      email,
      phone,
      password,
      confirm_password,
      address,
      address2,
      city,
      state,
      zip,
    } = this.state;
    var is_validated = true;
    if (name === "") {
      this.setState({
        errorMessage: "Please enter the full name.",
      });
      is_validated = false;
    } else if (company_name === "") {
      this.setState({
        errorMessage: "Please enter the company name.",
      });
      is_validated = false;
    } else if (email === "") {
      this.setState({
        errorMessage: "Please enter the valid email.",
      });
      is_validated = false;
    } else if (phone === "") {
      this.setState({
        errorMessage: "Please enter the valid phone number.",
      });
      is_validated = false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorMessage: "Invalid email format.",
      });
      is_validated = false;
    } else if (normalize(phone) === null) {
      this.setState({
        errorMessage: "Invalid phone format.",
      });
      is_validated = false;
    } else if (password === "") {
      this.setState({
        errorMessage: "Please enter the password.",
      });
      is_validated = false;
    } else if (password.length < 8) {
      this.setState({
        errorMessage: "Use 8 characters or more for your password",
      });
      is_validated = false;
    } else if (confirm_password === "") {
      this.setState({
        errorMessage: "Confirm password is required.",
      });
      is_validated = false;
    } else if (confirm_password.length < 8) {
      this.setState({
        errorMessage: "Use 8 characters or more for your confirm password",
      });
      is_validated = false;
    } else if (password !== confirm_password) {
      this.setState({
        errorMessage: "Password and confirm password does not match.",
      });
      is_validated = false;
    } else if (address === "") {
      this.setState({
        errorMessage: "Please enter the Address.",
      });
      is_validated = false;
    } else if (city === "") {
      this.setState({
        errorMessage: "Please enter the City.",
      });
      is_validated = false;
    } else if (state === "") {
      this.setState({
        errorMessage: "Please enter the State.",
      });
      is_validated = false;
    } else if (zip === "") {
      this.setState({
        errorMessage: "Please enter the Zip.",
      });
      is_validated = false;
    } else {
      console.log("hello ");
    }
    setTimeout(() => {
      this.setState({
        errorMessage: "",
      });
    }, 3000);
  };

  downloadCsv = async () => {
    console.log("HERE FILE: ");
  };

  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    });
  };

  toggleConfirmPassword = () => {
    this.setState({
      confirmPasswordShown: !this.state.confirmPasswordShown,
    });
  };

  handleAddressSelect = async (place) => {
    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        console.log("addressComponents: ", data.results[0]);
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        let neighborhood = null;
        let locality = null;
        let address2 = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            neighborhood = component.long_name;
          } else if (component.types.includes("locality")) {
            locality = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("subpremise")) {
            address2 = component.long_name;
          } else if (
            ![
              "neighborhood",
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += " ";
            }
            streetAddress += component.long_name;
          }
        });
        // Check if neighborhood is part of the formatted address
        if (neighborhood && formattedAddress.includes(neighborhood)) {
          city = neighborhood;
        } else {
          city = locality;
        }
        this.setState({
          city: city && city !== "" ? city : "",
          state: state && state !== "" ? state : "",
          zip: zipCode && zipCode !== "" ? zipCode : "",
          country: country && country !== "" ? country : "",
          address: streetAddress && streetAddress !== "" ? streetAddress : "",
          address2: address2 && address2 !== "" ? address2 : "",
        });
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  logoutGoogle = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("authProvider");
    localStorage.removeItem("userSub");
    window.location.reload();
  };

  handleGoogleSuccess = (credentialResponse) => {
    if (
      process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID ===
      credentialResponse.clientId
    ) {
      this.authRegister(credentialResponse.credential);
    }
  };

  authRegister = (authToken) => {
    let reseller_hash = this.props.match.params.reseller_hash;
    let reseller_url = this.props.match.params.reseller_url;
    this.setState({
      tableLoader: true,
      ssoSuccessMessage:
        "Just a few more steps to get you all set up! Please complete these additional details to finish your registration.",
    });
    let urlElements = window.location.href.split("/");
    let action_type = "client";
    let signup_hash = urlElements[urlElements.length - 1];
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/login_Oauth",
          JSON.stringify({
            reseller_url: reseller_url,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            authToken: authToken,
          }),
          axiosConfig
        )
        .then((response) => {
          console.log("response: ", response);
          if (
            response.data.status === 200 &&
            response.data.message === "success" &&
            response.data.requestType === "user_register"
          ) {
            const userEmail = response.data.userEmail;
            const userName = response.data.userName;
            const userSub = response.data.userSub;
            const requestType = response.data.requestType;
            localStorage.setItem("userEmail", userEmail);
            localStorage.setItem("userName", userName);
            localStorage.setItem("userSub", userSub);
            localStorage.setItem("authProvider", "google");
            this.setState({
              successMessage:
                "Please wait, while we set up your account. You will shortly be redirected to complete the setup process.",
              errorMessage: "",
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else if (
            response.data.status === 200 &&
            response.data.message === "success" &&
            response.data.requestType === "user_login"
          ) {
            this.setState({
              successMessage: "Please wait, redirecting you to the dashboard.",
              errorMessage: "",
            });
            Cookies.set(
              "tkn__",
              CryptoJS.AES.encrypt(
                response.data.token,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "acn__",
              CryptoJS.AES.encrypt(
                response.data.accountno,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "eml__",
              CryptoJS.AES.encrypt(
                response.data.email,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "comp_nme__",
              CryptoJS.AES.encrypt(
                response.data.comp_name,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "name__",
              CryptoJS.AES.encrypt(
                response.data.name,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "phone__",
              CryptoJS.AES.encrypt(
                response.data.phone,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "adress__",
              CryptoJS.AES.encrypt(
                response.data.address,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "city__",
              CryptoJS.AES.encrypt(
                response.data.city,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "state__",
              CryptoJS.AES.encrypt(
                response.data.state,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "zip__",
              CryptoJS.AES.encrypt(
                response.data.zip,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "country__",
              CryptoJS.AES.encrypt(
                response.data.country,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "title__",
              CryptoJS.AES.encrypt(
                response.data.title,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            setTimeout(function () {
              window.location.replace("/dashboard");
            }, 1000);
          }
        });
    } catch (error) {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        tableLoader: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
      console.log("catch : ", error);
    }
  };

  generateStrongPassword = (length) => {
    const lowercaseCharset = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numberCharset = "0123456789";
    const specialCharset = "!@#$%^&*()-_=+";

    let password = "";

    password +=
      lowercaseCharset[Math.floor(Math.random() * lowercaseCharset.length)];
    password +=
      uppercaseCharset[Math.floor(Math.random() * uppercaseCharset.length)];
    password += numberCharset[Math.floor(Math.random() * numberCharset.length)];
    password +=
      specialCharset[Math.floor(Math.random() * specialCharset.length)];

    const remainingLength = length - 4;

    const combinedCharset =
      lowercaseCharset + uppercaseCharset + numberCharset + specialCharset;
    for (let i = 0; i < remainingLength; i++) {
      const randomIndex = Math.floor(Math.random() * combinedCharset.length);
      password += combinedCharset[randomIndex];
    }

    password = this.shuffleString(password);

    return password;
  };

  shuffleString = (str) => {
    const array = str.split("");
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array.join("");
  };

  handleVerify = async () => {
    if (!this.state.filer_id) {
      this.setState({
        errorMessage: "Filer ID cannot be empty.",
      });
      setTimeout(() => {
        this.setState({
          errorMessage: "",
        });
      }, 3000);
      return;
    }

    this.setState({ verifying: true });

    // Create a timeout promise
    const timeoutPromise = new Promise(
      (_, reject) =>
        setTimeout(() => reject(new Error("Request timed out")), 20000) // 20 seconds timeout
    );

    try {
      const servicesResponce = await Promise.race([
        verifyFilerID(auth.getAccount(), auth.getToken(), this.state.filer_id),
        timeoutPromise,
      ]);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage:
            "There is some error while verifying the filer id. Please enter the correct FCC 499 Filer ID.",
          verifying: false,
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000); // Remove error message after 3 seconds
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        const filerData = JSON.parse(servicesResponce.data.data);
        const filerInfo = filerData.Filer499QueryResults.Filer[0];
        const idInfo = filerInfo.Filer_ID_Info[0];
        const hqAddress = idInfo.hq_address[0];
        let phoneNumber = idInfo.Customer_Inquiries_telephone[0];
        if (phoneNumber && phoneNumber !== "") {
          if (!phoneNumber.startsWith("+1")) {
            phoneNumber = `+1${phoneNumber}`;
          }
        }

        this.setState({
          successMessage: "Successfully verified filer id",
          name: idInfo.Legal_Name[0],
          company_name: idInfo.doing_business_as[0],
          address: hqAddress.address_line[0],
          zip: hqAddress.zip_code[0],
          state: hqAddress.state[0],
          city: hqAddress.city[0],
          country: "United States",
          phone: phoneNumber,
          errorMessage: "",
          verifying: false,
          filerVerified: true,
          errorClientFilerID: "",
        });

        setTimeout(() => {
          this.setState({ successMessage: "" });
        }, 3000);
      } else {
        this.setState({
          errorMessage: "There is some error while verifying the filer id.",
          verifying: false,
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      }
    } catch (error) {
      console.error("Verification error:", error);
      this.setState({
        errorMessage: "Verification request timed out or failed. Try again.",
        verifying: false,
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 3000); // Remove error message after 3 seconds
    }
  };

  render() {
    const { step } = this.state;
    const {
      company_name,
      filer_id,
      password,
      confirm_password,
      name,
      email,
      phone,
      title,
      no_of_employes,
      errorMessage,
      successMessage,
      service_id,
      agent_title,
      res_title,
      city,
      zip,
      state,
      country,
      address,
      filderIdPermission,
      filerIDRequired,
      useGooglePlaces,
      toggleButtonText,
    } = this.state;
    const values = {
      company_name,
      filer_id,
      password,
      confirm_password,
      name,
      email,
      phone,
      title,
      no_of_employes,
      errorMessage,
      successMessage,
      service_id,
      agent_title,
      res_title,
    };

    const errorStyle = this.state.errorClientAddress
      ? {
          input: (provided) => ({
            ...provided,
            borderColor: "red", // Change border color to red on error
          }),
          control: (provided) => ({
            ...provided,
            borderColor: "red", // Optionally, change control border color too
          }),
        }
      : {};

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-body npc-crypto ui-clean pg-auth">
          <div className="nk-app-root">
            <div className="nk-main ">
              <div className="nk-wrap nk-wrap-nosidebar">
                <div className="nk-content ">
                  <div className="nk-block nk-block-middle nk-auth-body wide-md">
                    {this.state.pageError !== "" ? (
                      <div
                        className="nk-wrap nk-wrap-nosidebar"
                        style={{
                          width: "80%",
                          marginRight: "10%",
                          marginLeft: "10%",
                        }}
                      >
                        <div className="nk-content ">
                          <div className="nk-block nk-block-middle wide-md mx-auto">
                            <div className="nk-block-content nk-error-ld text-center">
                              <img
                                className="nk-error-gfx"
                                src="../../../../assets/images/error-404_1.png"
                                alt=""
                              />
                              <div className="wide-xs mx-auto">
                                <h3 className="nk-error-title">
                                  Oops! Why you’re here?
                                </h3>
                                <p className="nk-error-text">
                                  {this.state.pageError}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="brand-logo pb-4 text-center">
                          {this.state.imageLoader === true ? (
                            ThreeDots()
                          ) : (
                            <>
                              {auth.getResellerLogo() !== "" ? (
                                <a
                                  href={`${auth.getResellerRegToken()}/client/login`}
                                  className="logo-link"
                                >
                                  <img
                                    className="logo-light logo-img logo-img-lg"
                                    src={auth.getResellerLogo()}
                                    alt="logo"
                                  />
                                  <img
                                    className="logo-dark logo-img logo-img-lg"
                                    src={auth.getResellerLogo()}
                                    alt="logo-dark"
                                  />
                                </a>
                              ) : (
                                <a
                                  href={`${auth.getResellerRegToken()}/client/login`}
                                  className="logo-link"
                                >
                                  <img
                                    className="logo-light logo-img logo-img-lg"
                                    src={LOGO}
                                    alt="logo"
                                  />
                                  <img
                                    className="logo-dark logo-img logo-img-lg"
                                    src={LOGO}
                                    alt="logo-dark"
                                  />
                                </a>
                              )}
                            </>
                          )}
                        </div>
                        <div className="card card-bordered pt-3 ">
                          {this.state.ssoSuccessMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ padding: "0rem 2rem 1rem" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Register Client</h4>
                                  <p>{this.state.ssoSuccessMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.tableLoader === true ? (
                            FormLoader()
                          ) : (
                            <>
                              <div className="card-inner card-inner-lg">
                                <div className="nk-block-head">
                                  <div className="nk-block-head-content">
                                    <HelmetProvider>
                                      <Helmet>
                                        <meta
                                          name="description"
                                          content="Client SignUp"
                                        />
                                        <title>Client SignUp</title>
                                      </Helmet>
                                    </HelmetProvider>
                                    <h4 className="nk-block-title">
                                      {auth.getResellerRegTitle() !== ""
                                        ? auth.getResellerRegTitle()
                                        : "Number.io"}{" "}
                                      Client SignUp
                                    </h4>
                                    <div className="nk-block-des">
                                      <p
                                        style={{
                                          color: "#637381",
                                          marginTop: "25px",
                                        }}
                                      >
                                        {" "}
                                        This portal provides you access to{" "}
                                        {auth.getResellerRegTitle() !== ""
                                          ? auth.getResellerRegTitle()
                                          : "Number.io"}{" "}
                                        service contracts, rates, reports, and
                                        product marketing resources you need to
                                        establish services. Please visit the
                                        main site for other{" "}
                                        {auth.getResellerRegTitle() !== ""
                                          ? auth.getResellerRegTitle()
                                          : "Number.io"}{" "}
                                        Business Units.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {this.state.social_register ? (
                                  <p>
                                    Just a few more steps to get you all set up!
                                    Please complete these additional details to
                                    finish your registration.
                                  </p>
                                ) : (
                                  <>
                                    {!this.state.fieldsHide ? (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            margin: "1rem",
                                          }}
                                        >
                                          <div class="googleLoginBtn-large">
                                            <GoogleLogin
                                              onSuccess={
                                                this.handleGoogleSuccess
                                              }
                                              onError={() => {
                                                console.log(
                                                  "Registeration Failed"
                                                );
                                              }}
                                              size="large"
                                              theme="outline"
                                              text="signup_with"
                                              width="400px"
                                              logo_alignment="center"
                                            />
                                          </div>
                                          <div class="googleLoginBtn-small">
                                            <GoogleLogin
                                              onSuccess={
                                                this.handleGoogleSuccess
                                              }
                                              onError={() => {
                                                console.log("Login Failed");
                                              }}
                                              size="large"
                                              theme="outline"
                                              text="signup_with"
                                              width="315px"
                                              logo_alignment="center"
                                            />
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <hr
                                            style={{
                                              flex: 1,
                                              border: "none",
                                              borderBottom: "1px solid #ccc",
                                            }}
                                          />
                                          <span style={{ padding: "0 10px" }}>
                                            OR
                                          </span>
                                          <hr
                                            style={{
                                              flex: 1,
                                              border: "none",
                                              borderBottom: "1px solid #ccc",
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                  </>
                                )}
                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert mb-4">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: this.state.errorMessage,
                                          }}
                                        ></p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert mb-4">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.afterRegistration === false ? (
                                  <>
                                    <form
                                      method="post"
                                      //onSubmit={this.formSubmit}
                                      id="stepper-create-profile"
                                    >
                                      <div className="row">
                                        {this.state.filderIdPermission ===
                                          1 && (
                                          <>
                                            <div className="form-group col-lg-6">
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <label
                                                  className="form-label"
                                                  htmlFor="filer_id"
                                                >
                                                  FCC 499 Filer ID No{" "}
                                                  {this.state
                                                    .filerIDRequired === 1 && (
                                                    <sup>*</sup>
                                                  )}
                                                </label>
                                              </div>
                                              <div className="form-control-wrap">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="filer_id"
                                                  name="filer_id"
                                                  defaultValue={
                                                    this.state.filer_id
                                                  }
                                                  onChange={this.handleChange}
                                                  required={
                                                    this.state
                                                      .filerIDRequired === 1
                                                  }
                                                  maxLength={100}
                                                  style={{
                                                    border: this.state
                                                      .errorClientFilerID
                                                      ? "1px solid red"
                                                      : "",
                                                  }}
                                                  autoComplete="false"
                                                />
                                                <span
                                                  className="badge badge-pill badge-outline-success ml-auto mt-2"
                                                  style={{
                                                    color: "#1ee0ac",
                                                    cursor: "pointer",
                                                    float: "right",
                                                    opacity: this.state
                                                      .verifying
                                                      ? 0.5
                                                      : 1,
                                                    pointerEvents: this.state
                                                      .verifying
                                                      ? "none"
                                                      : "auto",
                                                  }}
                                                  onClick={this.handleVerify}
                                                >
                                                  <em className="icon ni ni-check"></em>{" "}
                                                  {this.state.verifying
                                                    ? "Verifying..."
                                                    : "Verify"}
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-first-name"
                                          >
                                            Full Name <sup>*</sup>
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="name"
                                              name="name"
                                              placeholder="Full Name"
                                              value={this.state.name}
                                              onChange={this.handleChange}
                                              maxLength={50}
                                              required
                                              style={{
                                                border:
                                                  this.state.errorName &&
                                                  "1px solid red",
                                              }}
                                            />
                                            <small
                                              className="form-text text-muted"
                                              style={{
                                                position: "absolute",
                                                top: "34px",
                                                right: "0px",
                                                color: "#555",
                                                fontSize: "0.75rem",
                                                fontWeight: "400",
                                                fontFamily:
                                                  '"Roboto", "Helvetica", "Arial", sans-serif',
                                              }}
                                            >
                                              Allowed characters: (&nbsp; '
                                              &nbsp; - &nbsp; . &nbsp; , )
                                            </small>
                                          </div>
                                        </div>

                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-first-name"
                                          >
                                            Company Name <sup>*</sup>
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="company_name"
                                              name="company_name"
                                              placeholder="Company Name"
                                              value={this.state.company_name}
                                              onChange={this.handleChange}
                                              maxLength={50}
                                              required
                                              style={{
                                                border:
                                                  this.state
                                                    .errorClientCompany &&
                                                  "1px solid red",
                                              }}
                                            />
                                            <small
                                              className="form-text text-muted"
                                              style={{
                                                position: "absolute",
                                                top: "34px",
                                                right: "0px",
                                                color: "#555",
                                                fontSize: "0.75rem",
                                                fontWeight: "400",
                                                fontFamily:
                                                  '"Roboto", "Helvetica", "Arial", sans-serif',
                                              }}
                                            >
                                              Allowed characters: (&nbsp; _
                                              &nbsp; . &nbsp; - &nbsp; , &nbsp;
                                              &amp; &nbsp; ( ) )
                                            </small>
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-contact-email"
                                          >
                                            Email <sup>*</sup>
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="email"
                                              className="form-control"
                                              id="email"
                                              name="email"
                                              defaultValue={this.state.email}
                                              onChange={this.handleChange}
                                              placeholder="info@example.com"
                                              maxLength={100}
                                              required
                                              style={{
                                                border:
                                                  this.state.errorClientEmail &&
                                                  "1px solid red",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="fv-phone"
                                          >
                                            Phone <sup>*</sup>
                                          </label>

                                          {/*<PhoneInput
                                            required
                                            defaultCountry={"us"}
                                            inputProps={{
                                              name: "phone",
                                              id: "phone",
                                              maxLength: 20,
                                            }}
                                            value={this.state.phone}
                                            onChange={(value) =>
                                              this.handleChange({
                                                target: {
                                                  name: "phone",
                                                  value,
                                                },
                                              })
                                            }
                                            helperText="Enter the phone number here."
                                            label="Phone Number"
                                            ref={this.phoneInputRef}
                                            onFocus={this.handleFocus}
                                          />*/}
                                          <PhoneInput
                                            required
                                            defaultCountry={"us"}
                                            error={this.state.errorPhone}
                                            inputProps={{
                                              name: "phone",
                                              id: "phone",
                                              maxLength: 20,
                                              className: this.state
                                                .errorClientPhone
                                                ? "error-border"
                                                : "default-border",
                                            }}
                                            dialCodePreviewStyleProps={{
                                              className: this.state
                                                .errorClientPhone
                                                ? "error-border"
                                                : "default-border",
                                            }}
                                            value={this.state.phone}
                                            onChange={(value) =>
                                              this.handleChange({
                                                target: {
                                                  name: "phone",
                                                  value,
                                                },
                                              })
                                            }
                                            helperText="Enter the phone number."
                                            label="Agent Phone Number"
                                            ref={this.phoneInputRef}
                                            onFocus={this.handleFocus}
                                          />
                                        </div>

                                        <div className="form-group col-lg-6">
                                          <div class="nk-block-between-md g-4">
                                            <div class="nk-block-head-content">
                                              <label
                                                htmlFor="googleLocation-autocomplete"
                                                style={{
                                                  color: "black",
                                                  fontWeight: "500",
                                                  marginBottom: "8px",
                                                }}
                                              >
                                                Address *
                                              </label>
                                            </div>
                                            <div class="nk-block-head-content">
                                              <ul class="nk-block-tools gx-3">
                                                <li>
                                                  <a
                                                    onClick={this.handleToggle}
                                                    class="text-primary fs-11"
                                                    style={{
                                                      cursor: "pointer",
                                                      fontSize: "11px",
                                                    }}
                                                  >
                                                    {toggleButtonText}
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div>
                                            {useGooglePlaces ? (
                                              <GooglePlacesAutocomplete
                                                apiKey={
                                                  process.env
                                                    .REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                                                }
                                                selectProps={{
                                                  value: this.state.address
                                                    ? {
                                                        label:
                                                          this.state.address,
                                                        value:
                                                          this.state.address,
                                                      }
                                                    : null,
                                                  onChange:
                                                    this.handleAddressSelect,
                                                  placeholder: this.state
                                                    .address
                                                    ? null
                                                    : "Enter your address..",

                                                  styles: {
                                                    ...errorStyle,
                                                    input: (provided) => ({
                                                      ...provided,
                                                      color: "black",
                                                    }),
                                                    option: (provided) => ({
                                                      ...provided,
                                                      color: "black",
                                                    }),
                                                    singleValue: (
                                                      provided
                                                    ) => ({
                                                      ...provided,
                                                      color: "black",
                                                    }),
                                                    menu: (provided) => ({
                                                      ...provided,
                                                      zIndex: 9,
                                                    }),
                                                  },
                                                }}
                                                id="googleLocation-autocomplete"
                                              />
                                            ) : (
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="address"
                                                name="address"
                                                defaultValue={
                                                  this.state.address
                                                }
                                                onChange={this.handleChange}
                                                placeholder="PO Box Address"
                                                maxLength={50}
                                                fullWidth
                                                error={
                                                  this.state
                                                    .errorClientAddress ===
                                                  "error"
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-contact-email"
                                          >
                                            Apartment, Suite, Etc.
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="address2"
                                              name="address2"
                                              defaultValue={this.state.address2}
                                              onChange={this.handleChange}
                                              placeholder="Apartment, Suite, Etc."
                                              maxLength={200}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-contact-email"
                                          >
                                            City <sup>*</sup>
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="city"
                                              name="city"
                                              defaultValue={this.state.city}
                                              onChange={this.handleChange}
                                              placeholder="City"
                                              maxLength={30}
                                              required
                                              style={{
                                                border:
                                                  this.state.errorClientCity &&
                                                  "1px solid red",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-contact-email"
                                          >
                                            State <sup>*</sup>
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="state"
                                              name="state"
                                              defaultValue={this.state.state}
                                              onChange={this.handleChange}
                                              placeholder="State"
                                              maxLength={3}
                                              required
                                              style={{
                                                border:
                                                  this.state.errorClientState &&
                                                  "1px solid red",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-contact-email"
                                          >
                                            Zip / Postal Code <sup>*</sup>
                                          </label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="zip"
                                              name="zip"
                                              defaultValue={this.state.zip}
                                              onChange={this.handleChange}
                                              placeholder="Zip / Postal Code"
                                              maxLength={15}
                                              required
                                              style={{
                                                border:
                                                  this.state.errorClientZip &&
                                                  "1px solid red",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-lg-6">
                                          {" "}
                                          <label
                                            className="form-label"
                                            htmlFor="cp1-contact-email"
                                          >
                                            Country <sup>*</sup>
                                          </label>{" "}
                                          <div className="form-control-wrap">
                                            {" "}
                                            <Select
                                              options={this.countryOptions}
                                              placeholder="Select country"
                                              value={{
                                                label: this.state.country,
                                                value: this.state.country,
                                              }}
                                              onChange={
                                                this.handleChangeCountry
                                              }
                                            />{" "}
                                          </div>{" "}
                                        </div>
                                        {this.state.social_register ? null : (
                                          <>
                                            <div className="form-group col-lg-6">
                                              <label
                                                className="form-label"
                                                htmlFor="cp1-name-on-card"
                                              >
                                                Password <sup>*</sup>
                                              </label>
                                              <div className="form-control-wrap">
                                                <a
                                                  style={{
                                                    cursor: "pointer",
                                                    top: "16px",
                                                  }}
                                                  tabIndex="-1"
                                                  onClick={() => {
                                                    this.togglePassword();
                                                  }}
                                                  className="form-icon form-icon-right passcode-switch"
                                                  data-target="password"
                                                >
                                                  {this.state.passwordShown ? (
                                                    <em className="icon ni ni-eye-off"></em>
                                                  ) : (
                                                    <em className="icon ni ni-eye"></em>
                                                  )}
                                                </a>
                                                <input
                                                  type={
                                                    this.state.passwordShown
                                                      ? "text"
                                                      : "password"
                                                  }
                                                  minLength="8"
                                                  maxLength="50"
                                                  className="form-control"
                                                  id="password"
                                                  name="password"
                                                  placeholder="Password"
                                                  defaultValue={this.password}
                                                  onChange={this.handleChange}
                                                  required
                                                  style={{
                                                    border:
                                                      this.state
                                                        .errorClientPassword &&
                                                      "1px solid red",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group col-lg-6">
                                              <label
                                                className="form-label"
                                                htmlFor="cp1-card-number"
                                              >
                                                Confirm Password <sup>*</sup>
                                              </label>
                                              <div className="form-control-wrap">
                                                <a
                                                  style={{
                                                    cursor: "pointer",
                                                    top: "16px",
                                                  }}
                                                  tabIndex="-1"
                                                  onClick={() => {
                                                    this.toggleConfirmPassword();
                                                  }}
                                                  className="form-icon form-icon-right passcode-switch"
                                                  data-target="password"
                                                >
                                                  {this.state
                                                    .confirmPasswordShown ? (
                                                    <em className="icon ni ni-eye-off"></em>
                                                  ) : (
                                                    <em className="icon ni ni-eye"></em>
                                                  )}
                                                </a>
                                                <input
                                                  type={
                                                    this.state
                                                      .confirmPasswordShown
                                                      ? "text"
                                                      : "password"
                                                  }
                                                  minLength="8"
                                                  maxLength="50"
                                                  className="form-control"
                                                  id="confirm_password"
                                                  name="confirm_password"
                                                  placeholder="Password"
                                                  defaultValue={
                                                    this.confirm_password
                                                  }
                                                  onChange={this.handleChange}
                                                  required
                                                  style={{
                                                    border:
                                                      this.state
                                                        .errorCPassword &&
                                                      "1px solid red",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        )}
                                        <div
                                          className={`custom-control custom-control-md custom-checkbox col-lg-12 ${
                                            this.state.policyCheckBoxError
                                              ? "checkbox-error"
                                              : ""
                                          }`}
                                          style={{
                                            padding: 0,
                                            marginLeft: "3.1rem",
                                            marginBottom: "1.3rem",
                                            maxWidth: "90%",
                                          }}
                                        >
                                          <div className="form-group">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck2"
                                              checked={
                                                this.state.policyCheckBox
                                              }
                                              onChange={() => {
                                                const isChecked =
                                                  !this.state.policyCheckBox;
                                                this.setState({
                                                  policyCheckBox: isChecked,
                                                });
                                                console.log(
                                                  "Policy Checkbox Checked:",
                                                  isChecked
                                                );
                                              }}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck2"
                                            >
                                              I consent to{" "}
                                              {this.state.res_title} collecting
                                              my personal data in accordance
                                              with the{" "}
                                              <a
                                                href={`${APP_URL}pages/privacyPolicy`}
                                                target="_blank"
                                                className="text-decoration-underline"
                                              >
                                                Privacy Policy
                                              </a>{" "}
                                              and contacting me via phone or
                                              email. By submitting this form,
                                              you agree to our{" "}
                                              <a
                                                href={`${APP_URL}pages/privacyPolicy`}
                                                target="_blank"
                                                className="text-decoration-underline"
                                              >
                                                Privacy Policy
                                              </a>
                                              .
                                            </label>
                                          </div>
                                        </div>

                                        <div
                                          className="custom-control custom-control-md custom-checkbox col-lg-12"
                                          style={{
                                            padding: 0,
                                            marginLeft: "3.1rem",
                                            marginBottom: "1.3rem",
                                            maxWidth: "90%",
                                          }}
                                        >
                                          <div className="form-group">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck3"
                                              checked={
                                                this.state.policyCheckBox2
                                              }
                                              onChange={() => {
                                                const isChecked =
                                                  !this.state.policyCheckBox2;
                                                this.setState({
                                                  policyCheckBox2: isChecked,
                                                });
                                                console.log(
                                                  "Policy Checkbox2 Checked:",
                                                  isChecked
                                                );
                                              }}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck3"
                                            >
                                              I agree to receive marketing
                                              communications from{" "}
                                              {this.state.res_title}.
                                            </label>
                                          </div>
                                        </div>
                                        {this.state.social_register ? null : (
                                          <div className="form-group col-lg-12">
                                            <ReCAPTCHA
                                              sitekey={this.site_key}
                                              onChange={
                                                this.handleCaptchaChange
                                              }
                                              style={{
                                                padding: "0px 0px 15px",
                                              }}
                                            />
                                          </div>
                                        )}
                                        <div className="form-group col-lg-12">
                                          <a
                                            className="btn btn-lg btn-block"
                                            disabled={this.state.disabled}
                                            //type="submit"
                                            style={{
                                              backgroundColor: THEME_COLOR,
                                              color: "white",
                                              cursor: "pointer",
                                            }}
                                            onClick={this.formSubmit}
                                          >
                                            Submit
                                          </a>
                                        </div>
                                        {this.state.social_register &&
                                        !this.state.fieldsHide ? (
                                          <div className="form-group col-lg-12">
                                            <button
                                              id="logoutBtn"
                                              className="btn btn-lg btn-block"
                                              onClick={() =>
                                                this.logoutGoogle()
                                              }
                                              style={{
                                                // backgroundColor: THEME_COLOR,
                                                color: THEME_COLOR,
                                                border: `1px solid ${THEME_COLOR}`,
                                              }}
                                            >
                                              Log Out
                                            </button>
                                          </div>
                                        ) : null}
                                      </div>
                                    </form>
                                    <div className="form-note-s2 text-center pt-4">
                                      {" "}
                                      Already have an account ?{" "}
                                      <a
                                        href={`${auth.getResellerRegToken()}/client/login`}
                                      >
                                        Sign in instead
                                      </a>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {this.state.gifLoader === true ? (
                                      FormLoader()
                                    ) : (
                                      <div className="gfx w-50 mx-auto text-center">
                                        <img
                                          className="hide_this_img image"
                                          src="../../../assets/images/animation_success.gif"
                                        />
                                      </div>
                                    )}
                                    <div className="row pt-4">
                                      <div className="form-group col-lg-6">
                                        <button
                                          type="button"
                                          className="btn btn-lg btn-block"
                                          onClick={this.gotoLogin}
                                          style={{
                                            backgroundColor: THEME_COLOR,
                                            color: "white",
                                          }}
                                        >
                                          Login
                                        </button>
                                      </div>
                                      <div className="form-group col-lg-6">
                                        <button
                                          className="btn btn-lg btn-block"
                                          onClick={this.resendEmail}
                                          disabled={this.state.disableResendBtn}
                                          id="resendBtn"
                                          style={{
                                            backgroundColor: THEME_COLOR,
                                            color: "white",
                                          }}
                                        >
                                          Resend Email
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="container wide-lg">
                    <div className="row g-3">
                      <div className="col-lg-12">
                        <div className="nk-block-content text-center">
                          <p>
                            &copy; {new Date().getFullYear()}{" "}
                            <a
                              href="https://onboardsoft.com/"
                              style={{ color: "#526484" }}
                            >
                              OnBoardsoft LLC
                            </a>
                            . All Rights Reserved.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
