import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listDomains,
  deletePage,
  verfiyDomain,
  submitDomain,
} from "./../../../config/domain_api_calls";
import $ from "jquery";

import Domain from "../../../components/MultiStepForm/Domain";
import DomainConnect from "../../../components/MultiStepForm/DomainConnect";
import DomainVerification from "../../../components/MultiStepForm/DomainVerification";
import DomainSuccess from "../../../components/MultiStepForm/DomainSuccess";

import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import { DomainDisabledRounded } from "@material-ui/icons";
const auth = new HelperClass();

class Adddomains extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      delete_id: "",
      defaultNrc: "",
      defaultMrc: "",
      new_nrc: "",
      new_mrc: "",
      rate_type: "",
      rate: "",
      update_nrc: "",
      update_mrc: "",
      update_rate: "",
      product_id: "",
      product_desc: "",
      mrc_rate_type: "Flat",
      service_title: "",
      service_type_edit: "",
      step: 1,
      domain_name: "",
      domain_title: "",
      dnsList: [],
      a_ip: "",
      is_verified: "0",
    };
  }

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
      a_ip: process.env.A_RECORD_IP,
    });
    console.log("STEP: ", this.state.step);
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  VerifyConnection = async () => {
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    const { domain_title, domain_name } = this.state;
    console.log("domain_name: ", domain_name);
    if (domain_name) {
      const servicesResponce = await verfiyDomain(
        auth.getAccount(),
        auth.getToken(),
        domain_name
      );
      console.log("DNS RESPONSE: ", servicesResponce.data.dnsList);
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage: "There is some error while verifying the domain.",
          tableLoader: false,
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        if (servicesResponce.data.dnsList.ErrorMessage) {
          console.log(
            "DNS RESPONSE ErrorMessage: ",
            servicesResponce.data.dnsList.ErrorMessage
          );
          this.setState({
            dnsList: [],
            errorMessage:
              "Unable to retrieve DNS record for " +
              domain_name +
              ". Try some other domain or add without verification.",
            tableLoader: false,
            disabled: false,
          });
        } else {
          this.setState({
            dnsList: servicesResponce.data.dnsList,
            errorMessage: "",
            tableLoader: false,
            disabled: false,
          });
        }
      } else {
        this.setState({
          errorMessage: "There is some error while verifying the domain.",
          tableLoader: false,
          disabled: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "Domain name cannot be empty.",
        tableLoader: false,
        disabled: false,
      });
    }
  };

  submitDomain = async () => {
    const { domain_title, domain_name, dnsList } = this.state;
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    let is_verified = "0";
    if (dnsList) {
      if (dnsList.DNSData) {
        let type = dnsList.DNSData.dnsRecords[0].type;
        if (type === 1) {
          this.setState({
            is_verified: "1",
          });
          is_verified = "1";
          console.log("Verified");
        } else {
          console.log("Verification Error");
        }
        console.log("dnsList: ", dnsList);
      }
    } else {
      console.log("Verification Not Applied");
    }

    console.log("is_verified: ", is_verified);
    if (domain_name) {
      const servicesResponce = await submitDomain(
        auth.getAccount(),
        auth.getToken(),
        domain_name,
        domain_title,
        is_verified
      );
      console.log("DNS RESPONSE: ", servicesResponce.data);
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage: "There is some error while adding the domain.",
          successMessage: "",
          tableLoader: false,
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          successMessage:
            "Your domain creation process is completed. Domain will be available in 5 to 10 minutes.",
          errorMessage: "",
          tableLoader: false,
          disabled: false,
        });
        //this.nextStep();
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success_not_verified"
      ) {
        this.setState({
          successMessage:
            "Domain added but not configured because it is not verified.",
          errorMessage: "",
          tableLoader: false,
          disabled: false,
        });
        /*setTimeout(() => {
          this.nextStep();
        }, 3000);*/
      } else {
        this.setState({
          errorMessage: "There is some error while adding the domain.",
          successMessage: "",
          tableLoader: false,
          disabled: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "Domain name cannot be empty.",
        successMessage: "",
        tableLoader: false,
        disabled: false,
      });
    }
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  defaultText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[5];
    if (is_default === 1) {
      return "Active";
    } else {
      return "Inactive";
    }
  };

  deletePage = async (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let id = tableMeta.rowData[0];
    this.setState({
      delete_id: id,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  publishText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[4];
    if (is_default === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  async componentDidMount() {
    const servicesResponce = await listDomains(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("servicesResponce.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      window.location.replace("/error");
    }
  }

  setMarkUp = async (value, tableMeta) => {
    await this.handleStatesDynamic(
      tableMeta.rowData[0],
      tableMeta.rowData[3].replace("$", ""),
      tableMeta.rowData[4].replace("$", ""),
      tableMeta.rowData[6].replace("$", ""),
      tableMeta.rowData[1],
      tableMeta.rowData[5]
    );
    console.log("setMarkupData: ", this.state.service_title);
    window.$("#modalForm").modal("show");
  };

  handleStatesDynamic = async (
    serviceId,
    update_nrc,
    update_mrc,
    update_rate,
    service_title,
    service_type_edit
  ) => {
    this.setState({
      serviceId: serviceId,
      update_nrc: update_nrc,
      update_mrc: update_mrc,
      update_rate: update_rate,
      service_title: service_title,
      service_type_edit: service_type_edit,
    });
  };

  showDetails = (value, tableMeta) => {
    this.setState({
      product_desc: tableMeta.rowData[7],
    });
    window.$("#modalFormDetail").modal("show");
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChanges = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  calculatePercent = (percent, num) => {
    return (percent / 100) * num;
  };
  modalHide = () => {
    window.$("#modalFormDetail").modal("hide");
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  addToTrash = async (e) => {
    let delete_id = this.state.delete_id;

    if (delete_id === "") {
      return this.setState({ errorMessage: "Please select a row to delete." });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await deletePage(
        auth.getAccount(),
        auth.getToken(),
        delete_id
      );

      console.log("saveResponce.data", saveResponce.data);

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        return this.setState({
          errorMessage: "The page does not exists.",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          successMessage: "Congratulations!",
        });
        this.setState({
          errorMessage: "",
          successMessage: "Page deleted Successfully.",
          disabled: false,
        });

        setTimeout(function () {
          window.$("#modalDeleteAlert").modal("hide");
        }, 3000);
        this.componentDidMount();
      } else {
        return this.setState({
          errorMessage: "There is some error while deleting the page.",
          successMessage: "",
          disabled: false,
        });
      }
    }
  };

  render() {
    const { step } = this.state;
    const {
      domain_title,
      domain_name,
      dnsList,
      a_ip,
      errorMessage,
      successMessage,
    } = this.state;
    const values = {
      domain_title,
      domain_name,
      dnsList,
      a_ip,
      errorMessage,
      successMessage,
    };
    return (
      <div className="nk-content " id="Adddomains_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/domains")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Domains
                      </Link>
                      <Typography color="textPrimary" className="">
                        Add Domain
                      </Typography>
                    </Breadcrumbs>
                    {/*<h3 className="nk-block-title page-title">
                      <PageTitle name="Manage Pages" />
          </h3>*/}
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        {/*<em className="icon ni ni-more-v"></em>*/}
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}

                      {(() => {
                        switch (step) {
                          default:
                            return (
                              <h1>
                                User Forms not working. Enable Javascript!
                              </h1>
                            );
                          case 1:
                            return (
                              <Domain
                                nextStep={this.nextStep}
                                handleChange={this.handleChange}
                                values={values}
                              />
                            );
                          case 2:
                            return (
                              <DomainConnect
                                nextStep={this.nextStep}
                                prevStep={this.prevStep}
                                handleChange={this.handleChange}
                                VerifyConnection={this.VerifyConnection}
                                values={values}
                              />
                            );
                          case 3:
                            return (
                              <DomainVerification
                                nextStep={this.nextStep}
                                prevStep={this.prevStep}
                                values={values}
                                submitDomain={this.submitDomain}
                              />
                            );
                          //
                          case 4:
                            return <DomainSuccess firstStep={this.firstStep} />;
                        }
                      })()}
                      {/* END TABLE */}

                      {/* Table 5th Col End */}
                      <div
                        className="modal fade"
                        tabIndex="-1"
                        id="modalDeleteAlert"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <a
                              style={{ cursor: "pointer" }}
                              className="close"
                              onClick={() => {
                                this.modalHideDel();
                              }}
                            >
                              <em className="icon ni ni-cross"></em>
                            </a>
                            <div className="modal-body modal-body-lg text-center">
                              <div className="nk-modal">
                                <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                                <h4 className="nk-modal-title">Delete Page?</h4>
                                <div className="nk-modal-text">
                                  <div className="caption-text">
                                    Are you sure you want to delete this page?
                                  </div>
                                </div>
                                <div className="nk-modal-action">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    className="btn btn-lg btn-mw btn-primary"
                                    onClick={() => {
                                      this.modalHideDel();
                                    }}
                                  >
                                    CANCEL
                                  </a>
                                  {"  "}
                                  <a
                                    style={{ cursor: "pointer" }}
                                    className="btn btn-lg btn-mw btn-danger"
                                    onClick={() => {
                                      this.addToTrash();
                                    }}
                                  >
                                    DELETE
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Adddomains);
