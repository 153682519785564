import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  ValidateEmail,
  axiosConfig,
  normalizePhone,
  ValidatePasswordPattern,
} from "../../../config/utility";
import {
  addBusinessInformation,
  addFinancialInformation,
  addTradeReferences,
  saveSignature,
  saveChooseSignature,
  configureCreditApplication,
} from "./../../../config/client_api_calls";

import $ from "jquery";

import BusinessInformation from "../../../components/Client/CreditApplication/BusinessInformation";
import FinancialInformation from "../../../components/Client/CreditApplication/FinancialInformation";
import TradeReferences from "../../../components/Client/CreditApplication/TradeReferences";
import SignatoryDetails from "../../../components/Client/CreditApplication/SignatoryDetails";
import SsoSuccess from "../../../components/Client/CreditApplication/Success";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import html2canvas from "html2canvas";
import countryList from "react-select-country-list";


const auth = new HelperClass();

class CreditApp extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      serviceData: [],
      tableDataBundle: [],
      completeData: [],
      defaultServices: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      errorMessageFinance: "",
      successMessageFinance: "",
      errorMessageTrade: "",
      errorMessageSignatory: "",
      successMessageSignatory: "",
      waitMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      tableLoaderBundle: true,
      errorMessageEmail: "",
      VerifyBusinessButton: false,
      VerifyFinancialButton: false,
      VerifyTradeButton: false,
      verifyDetailsButton: false,
      resendPinButton: false,
      successMessageEmail: "",
      errorMessagePassword: "",
      errorMessageConsent: "",
      errorMessageFinal: "",
      step: 1,
      email: "",
      disabled: false,
      //Business Info
      customer_name: "",
      customer_address1: "",
      customer_address2: "",
      customer_city: "",
      customer_state: "",
      customer_zip: "",
      customer_phone: "",
      duns_number: "",
      //Financial Info
      bank_details: false,
      bank_name: "",
      bank_contact: "",
      bank_address: "",
      bank_contact_phone: "",
      bank_contact_email: "",
      bank_routing_numbers: "",
      bank_account_number: "",
      //Trade Reference
      current_carrier_1: "",
      account_number_1: "",
      current_invoice_value_1: "",
      payment_terms_1: "",
      contact_name_1: "",
      contact_phone_1: "",
      contact_email_1: "",
      current_carrier_2: "",
      account_number_2: "",
      current_invoice_value_2: "",
      payment_terms_2: "",
      contact_name_2: "",
      contact_phone_2: "",
      contact_email_2: "",
      //Signatory Details
      sig_customer_name: "",
      sig_printed_name: "",
      sig_title: "",
      signatureText: "",
      signatureClass: "'Amita', cursive",
      signature_type: "draw",
      credit_app_id: "",
      signature_image: "",
    };
    this.countryOptions = countryList()
      .getData()
      .filter(
        (country) => country.label !== "United States Minor Outlying Islands"
      );
  }

  async componentDidMount() {
    if (
      this.props.props.match.params.credit_id &&
      this.props.props.match.params.credit_id !== ""
    ) {
      let credit_id = this.props.props.match.params.credit_id;
      this.setState({
        credit_app_id: credit_id,
      });
      const saveResponce = await configureCreditApplication(
        auth.getClientAccount(),
        auth.getClientToken(),
        credit_id
      );
      console.log("configureCreditApplication: ", saveResponce.data);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "Not Verified"
      ) {
      } else if (saveResponce.data.status === 200) {
        if (
          saveResponce.data.businessDetails &&
          saveResponce.data.businessDetails.length > 0
        ) {
          let business_details = saveResponce.data.businessDetails;
          console.log(
            'business_details[0]["customer_name"]',
            business_details[0]["customer_name"]
          );
          this.setState({
            customer_name: business_details[0]["customer_name"],
            customer_address1: business_details[0]["customer_address1"],
            customer_address2: business_details[0]["customer_address2"],
            customer_city: business_details[0]["customer_city"],
            customer_state: business_details[0]["customer_state"],
            customer_zip: business_details[0]["customer_zip"],
            customer_phone: business_details[0]["customer_phone"],
          });
        }
        if (
          saveResponce.data.financialDetails &&
          saveResponce.data.financialDetails.length > 0
        ) {
          let financial_details = saveResponce.data.financialDetails;
          this.setState({
            duns_number: financial_details[0]["duns_number"],
            bank_details: financial_details[0]["bank_details"],
            bank_name: financial_details[0]["bank_name"],
            bank_contact: financial_details[0]["bank_contact"],
            bank_address: financial_details[0]["bank_address"],
            bank_contact_phone: financial_details[0]["bank_contact_phone"],
            bank_contact_email: financial_details[0]["bank_contact_email"],
            bank_routing_numbers: financial_details[0]["bank_routing_numbers"],
            bank_account_number: financial_details[0]["bank_account_number"],
          });
        }
        if (
          saveResponce.data.signatoryDetails &&
          saveResponce.data.signatoryDetails.length > 0
        ) {
          let signatory_details = saveResponce.data.signatoryDetails;
          this.setState({
            sig_customer_name: signatory_details[0]["customer_name"],
            sig_printed_name: signatory_details[0]["printed_name"],
            sig_title: signatory_details[0]["title"],
          });
        }
        if (
          saveResponce.data.tradeReferences &&
          saveResponce.data.tradeReferences.length > 0
        ) {
          let tradeReferences = saveResponce.data.tradeReferences;
          this.setState({
            current_carrier_1: tradeReferences[0]["current_carrier_1"],
            account_number_1: tradeReferences[0]["account_no_1"],
            current_invoice_value_1:
              tradeReferences[0]["current_invoice_value_1"],
            payment_terms_1: tradeReferences[0]["payment_terms_1"],
            contact_name_1: tradeReferences[0]["contact_name_1"],
            contact_phone_1: tradeReferences[0]["contact_phone_1"],
            contact_email_1: tradeReferences[0]["contact_email_1"],
            current_carrier_2: tradeReferences[0]["current_carrier_2"],
            account_number_2: tradeReferences[0]["account_no_2"],
            current_invoice_value_2:
              tradeReferences[0]["current_invoice_value_2"],
            payment_terms_2: tradeReferences[0]["payment_terms_2"],
            contact_name_2: tradeReferences[0]["contact_name_2"],
            contact_phone_2: tradeReferences[0]["contact_phone_2"],
            contact_email_2: tradeReferences[0]["contact_email_2"],
          });
        }
      } else {
      }
    }
  }

  handleAddressSelect = async (place) => {
    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (
            ![
              "neighborhood",
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += ", ";
            }
            streetAddress += component.long_name;
          }
        });
        this.setState({
          customer_city: city && city !== "" ? city : "",
          customer_state: state && state !== "" ? state : "",
          customer_zip: zipCode && zipCode !== "" ? zipCode : "",
          //client_country: country && country !== "" ? country : "",
          customer_address1:
            streetAddress && streetAddress !== "" ? streetAddress : "",
        });
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  fieldValidationUsername = () => {
    let {
      customer_name,
      customer_phone,
      customer_address1,
      customer_address2,
      customer_city,
      customer_state,
      customer_zip,
    } = this.state;

    if (customer_name === "") {
      this.setState({
        errorMessage: "Customer name is required.",
        VerifyBusinessButton: false,
      });
      return false;
    } else if (customer_phone === "") {
      this.setState({
        VerifyBusinessButton: false,
        errorMessage: "Phone number is required.",
      });
      return false;
    } else if (normalizePhone(customer_phone) === null) {
      this.setState({
        VerifyBusinessButton: false,
        errorMessage: "Invalid phone format.",
      });
      return false;
    } else if (customer_address1 === "") {
      this.setState({
        VerifyBusinessButton: false,
        errorMessage: "Address #1 is required.",
      });
      return false;
    } else if (customer_city === "") {
      this.setState({
        VerifyBusinessButton: false,
        errorMessage: "City is required.",
      });
      return false;
    } else if (customer_state === "") {
      this.setState({
        VerifyBusinessButton: false,
        errorMessage: "State is required.",
      });
      return false;
    } else if (customer_zip === "") {
      this.setState({
        VerifyBusinessButton: false,
        errorMessage: "Zip is required.",
      });
      return false;
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });
    }
    return true;
  };

  submitBusinessInformation = async () => {
    let {
      customer_name,
      customer_phone,
      customer_address1,
      customer_address2,
      customer_city,
      customer_state,
      customer_zip,
      credit_app_id,
    } = this.state;
    this.setState({
      VerifyBusinessButton: true,
    });
    if (this.fieldValidationUsername() === true) {
      const saveResponce = await addBusinessInformation(
        auth.getClientAccount(),
        auth.getClientToken(),
        customer_name,
        customer_phone,
        customer_address1,
        customer_address2,
        customer_city,
        customer_state,
        customer_zip,
        credit_app_id
      );
      console.log(
        "customer_name: " +
          customer_name +
          " customer_phone: " +
          customer_phone +
          " customer_address1: " +
          customer_address1 +
          " customer_address2: " +
          customer_address2 +
          " customer_city: " +
          customer_city +
          " customer_state: " +
          customer_state +
          " customer_zip: " +
          customer_zip
      );
      console.log("submitBusinessInformation: ", saveResponce.data);

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "Not Verified"
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: "Address not verified. Please provide a valid address.",
          VerifyBusinessButton: false,
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          VerifyBusinessButton: false,
          disabled: false,
        });
        this.nextStep();
      } else {
        this.setState({
          errorMessage: "There is some error while processing your request.",
          successMessage: "",
          VerifyBusinessButton: false,
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  submitFinancialInformation = async () => {
    let {
      duns_number,
      bank_details,
      bank_name,
      bank_contact,
      bank_address,
      bank_contact_phone,
      bank_contact_email,
      bank_routing_numbers,
      bank_account_number,
      credit_app_id,
    } = this.state;
    this.setState({
      VerifyFinancialButton: true,
    });
    let is_validated = true;
    if (duns_number === "") {
      is_validated = false;
    }
    if (is_validated === true) {
      const saveResponce = await addFinancialInformation(
        auth.getClientAccount(),
        auth.getClientToken(),
        duns_number,
        bank_details,
        bank_name,
        bank_contact,
        bank_address,
        bank_contact_phone,
        bank_contact_email,
        bank_routing_numbers,
        bank_account_number,
        credit_app_id
      );

      console.log("submitBusinessInformation: ", saveResponce.data);

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        // window.location.replace("/error");
        this.setState({
          errorMessageFinance: saveResponce.data.message,
          VerifyFinancialButton: false,
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessageFinance: "",
          VerifyFinancialButton: false,
          disabled: false,
        });
        this.nextStep();
      } else {
        this.setState({
          errorMessageFinance:
            "There is some error while processing your request.",
          successMessageFinance: "",
          VerifyFinancialButton: false,
          disabled: false,
        });
      }
    } else {
      this.setState({
        errorMessageFinance: "Please enter the DUNS number.",
        VerifyFinancialButton: false,
        disabled: false,
      });
    }
    setTimeout(() => {
      this.setState({ successMessageFinance: "", errorMessageFinance: "" });
    }, 5000);
  };

  submitTradeReferences = async () => {
    let {
      current_carrier_1,
      account_number_1,
      current_invoice_value_1,
      payment_terms_1,
      contact_name_1,
      contact_phone_1,
      contact_email_1,
      current_carrier_2,
      account_number_2,
      current_invoice_value_2,
      payment_terms_2,
      contact_name_2,
      contact_phone_2,
      contact_email_2,
      credit_app_id,
    } = this.state;
    this.setState({
      VerifyTradeButton: true,
    });
    let is_validated = true;
    if (current_carrier_1 === "") {
      is_validated = false;
    }
    if (is_validated === true) {
      const saveResponce = await addTradeReferences(
        auth.getClientAccount(),
        auth.getClientToken(),
        current_carrier_1,
        account_number_1,
        current_invoice_value_1,
        payment_terms_1,
        contact_name_1,
        contact_phone_1,
        contact_email_1,
        current_carrier_2,
        account_number_2,
        current_invoice_value_2,
        payment_terms_2,
        contact_name_2,
        contact_phone_2,
        contact_email_2,
        credit_app_id
      );

      console.log("submitBusinessInformation: ", saveResponce.data);

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        // window.location.replace("/error");
        this.setState({
          errorMessageTrade: saveResponce.data.message,
          VerifyTradeButton: false,
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessageTrade: "",
          VerifyTradeButton: false,
          disabled: false,
        });
        this.nextStep();
      } else {
        this.setState({
          errorMessageTrade:
            "There is some error while processing your request.",
          successMessageFinance: "",
          VerifyTradeButton: false,
          disabled: false,
        });
      }
    } else {
      this.setState({
        errorMessageTrade: "Please enter at least one carrier information.",
        VerifyTradeButton: false,
        disabled: false,
      });
    }
    setTimeout(() => {
      this.setState({ successMessageFinance: "", errorMessageTrade: "" });
    }, 5000);
  };

  submitSignatoryDetails = async () => {
    let {
      signatureText,
      sig_printed_name,
      sig_title,
      signature_type,
      signatureClass,
      credit_app_id,
    } = this.state;
    this.setState({
      verifyDetailsButton: true,
    });
    let is_validated = true;
    if (signatureText === "") {
      is_validated = false;
    }
    if (signature_type === "choose") {
      await this.saveAsImage();
    }
    if (signature_type === "draw") {
      //await this.saveSignature();
    }
  };

  saveSignature = async (signature_image) => {
    let {
      signatureText,
      sig_printed_name,
      sig_title,
      signature_type,
      signatureClass,
      credit_app_id,
    } = this.state;
    this.setState({
      errorMessageSignatory: "",
      successMessageSignatory: "",
      verifyDetailsButton: true,
      disabled: true,
    });
    console.log("signature_image: ", signature_image);
    const apiData = await saveSignature(
      auth.getClientAccount(),
      auth.getClientToken(),
      signature_image,
      signatureText,
      sig_printed_name,
      sig_title,
      signature_type,
      credit_app_id
    );
    console.log("saveSignature: ", apiData.data);
    if (
      apiData.data.status === 403 ||
      apiData.data.errors === "authentication missing" ||
      apiData.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (apiData.data.status === 404) {
      this.setState({
        errorMessageSignatory:
          "There is some error while processing your request.",
        successMessageSignatory: "",
        verifyDetailsButton: false,
        disabled: false,
      });
    } else if (
      apiData.data.status === 200 &&
      apiData.data.message === "success"
    ) {
      this.setState({
        errorMessageSignatory: "",
        successMessageSignatory:
          "Credit application processed successfully. Please while we are redirecting you the next page.",
      });
      setTimeout(() => {
        window.location.replace(
          "/" + auth.getResellerIdentifier() + "/client/CreditApp"
        );
      }, 5000);
    }
  };

  /*saveAsImage = async () => {
    let {
      signatureText,
      sig_printed_name,
      sig_title,
      signature_type,
      signatureClass,
      credit_app_id,
    } = this.state;
    const element = document.getElementById("styled-text-container");
    console.log("element: ", element);
    if (element) {
      html2canvas(element, { scale: 2, backgroundColor: null }).then(
        (canvas) => {
          const imgData = canvas.toDataURL("image/png");

          // Send the image data to the backend for saving
          fetch(
            process.env.REACT_APP_API_URL +
              "credit_application/save_choose_image",
            {
              method: "POST",
              body: JSON.stringify({
                imgData: imgData,
                signatureText: signatureText,
                sig_printed_name: sig_printed_name,
                sig_title: sig_title,
                credit_app_id: credit_app_id,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          ).then((response) => {
            // Handle the data received from the server
            console.log("Received data:", response);
            if (response && response.status === 200) {
              this.setState({
                errorMessageSignatory: "",
                successMessageSignatory:
                  "Credit application processed successfully. Please while we are redirecting you the next page.",
              });
              setTimeout(() => {
                window.location.replace(
                  "/" + auth.getResellerIdentifier() + "/client/CreditApp"
                );
              }, 5000);
            } else {
              this.setState({
                errorMessageSignatory:
                  "There is some error while processing your request.",
                successMessageSignatory: "",
                verifyDetailsButton: false,
                disabled: false,
              });
            }
          });
        }
      );
    }
  };*/

  saveAsImage = async () => {
    let {
      signatureText,
      sig_printed_name,
      sig_title,
      signature_type,
      signatureClass,
      credit_app_id,
    } = this.state;
    const element = document.getElementById("styled-text-container");
    console.log("element: ", element);
    if (element) {
      html2canvas(element, { scale: 2, backgroundColor: null }).then(
        async (canvas) => {
          const imgData = canvas.toDataURL("image/png");

          const apiData = await saveChooseSignature(
            auth.getClientAccount(),
            auth.getClientToken(),
            imgData,
            signatureText,
            sig_printed_name,
            sig_title,
            signature_type,
            credit_app_id
          );
          console.log("saveSignature: ", apiData.data);
          if (
            apiData.data.status === 403 ||
            apiData.data.errors === "authentication missing" ||
            apiData.data.errors === "jwt expired"
          ) {
            auth.clientLogout();
          } else if (apiData.data.status === 404) {
            this.setState({
              errorMessageSignatory:
                "There is some error while processing your request.",
              successMessageSignatory: "",
              verifyDetailsButton: false,
              disabled: false,
            });
          } else if (
            apiData.data.status === 200 &&
            apiData.data.message === "success"
          ) {
            this.setState({
              errorMessageSignatory: "",
              successMessageSignatory:
                "Credit application processed successfully. Please while we are redirecting you the next page.",
            });
            setTimeout(() => {
              window.location.replace(
                "/" + auth.getResellerIdentifier() + "/client/CreditApp"
              );
            }, 5000);
          }
        }
      );
    }
  };

  signature_image_save = async (image_name) => {
    console.log("image_name: ", image_name);
    if (image_name !== "") {
      this.saveSignature(image_name);
    }
  };

  initiateProcess = async () => {
    this.setState({
      verifyDetailsButton: true,
      disabled: true,
    });
  };

  stopProcess = async () => {
    this.setState({
      verifyDetailsButton: false,
      disabled: false,
    });
  };

  setMarkUp = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];

    window.$("#modalForm").modal("show");
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  handleChanges = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleSignatureChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleSignature = (family) => {
    this.setState({ signatureClass: family });
  };

  handleChangeCheckbox = (input) => (e) => {
    this.setState({
      [input]:
        e.target.type === "checkbox" ? e.target.checked : e.target.checked,
    });
    //console.log("handleChangeCheckbox: ", input);
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  updateSignatureType = (type) => {
    this.setState({
      signature_type: type,
    });
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  render() {
    const { step } = this.state;
    const {
      email,
      customer_name,
      customer_address1,
      customer_address2,
      customer_city,
      customer_state,
      customer_zip,
      customer_phone,
      duns_number,
      bank_details,
      bank_name,
      bank_contact,
      bank_address,
      bank_contact_phone,
      bank_contact_email,
      bank_routing_numbers,
      bank_account_number,
      current_carrier_1,
      account_number_1,
      current_invoice_value_1,
      payment_terms_1,
      contact_name_1,
      contact_phone_1,
      contact_email_1,
      current_carrier_2,
      account_number_2,
      current_invoice_value_2,
      payment_terms_2,
      contact_name_2,
      contact_phone_2,
      contact_email_2,
      sig_customer_name,
      sig_printed_name,
      sig_title,
      signatureText,
      signatureClass,
      signature_type,
      signature_image,
      credit_app_id,
      disabled,
      VerifyBusinessButton,
      VerifyFinancialButton,
      VerifyTradeButton,
      verifyDetailsButton,
      errorMessage,
      successMessage,
      errorMessageFinance,
      successMessageFinance,
      errorMessageTrade,
      errorMessageSignatory,
      successMessageSignatory,
    } = this.state;
    const values = {
      email,
      customer_name,
      customer_address1,
      customer_address2,
      customer_city,
      customer_state,
      customer_zip,
      customer_phone,
      duns_number,
      bank_details,
      bank_name,
      bank_contact,
      bank_address,
      bank_contact_phone,
      bank_contact_email,
      bank_routing_numbers,
      bank_account_number,
      current_carrier_1,
      account_number_1,
      current_invoice_value_1,
      payment_terms_1,
      contact_name_1,
      contact_phone_1,
      contact_email_1,
      current_carrier_2,
      account_number_2,
      current_invoice_value_2,
      payment_terms_2,
      contact_name_2,
      contact_phone_2,
      contact_email_2,
      sig_customer_name,
      sig_printed_name,
      sig_title,
      signature_image,
      signatureText,
      signatureClass,
      signature_type,
      credit_app_id,
      disabled,
      VerifyBusinessButton,
      VerifyFinancialButton,
      VerifyTradeButton,
      verifyDetailsButton,
      errorMessage,
      successMessage,
      errorMessageFinance,
      successMessageFinance,
      errorMessageTrade,
      errorMessageSignatory,
      successMessageSignatory,
    };
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Support_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Add New Credit Application"
                        icon="icon ni ni-coin"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        {/*<em className="icon ni ni-more-v"></em>*/}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {(() => {
                            switch (step) {
                              default:
                                return (
                                  <div className="wide-xs mx-auto text-center">
                                    <h3 className="nk-error-title">
                                      Oops! There is some error
                                    </h3>
                                    <p className="nk-error-text">
                                      We are very sorry for inconvenience. It
                                      looks like the page you are trying to
                                      access is having problems. Please try
                                      again later.
                                    </p>
                                  </div>
                                );
                              case 1:
                                return (
                                  <>
                                    <BusinessInformation
                                      nextStep={this.nextStep}
                                      handleChange={this.handleChange}
                                      submitBusinessInformation={
                                        this.submitBusinessInformation
                                      }
                                      handleAddressSelect={this.handleAddressSelect}
                                      values={values}
                                    />
                                  </>
                                );
                              case 2:
                                return (
                                  <>
                                    <FinancialInformation
                                      nextStep={this.nextStep}
                                      prevStep={this.prevStep}
                                      handleChange={this.handleChange}
                                      handleChangeCheckbox={
                                        this.handleChangeCheckbox
                                      }
                                      submitFinancialInformation={
                                        this.submitFinancialInformation
                                      }
                                      addMenuHistory={this.addMenuHistory}
                                      values={values}
                                    />
                                  </>
                                );
                              case 3:
                                return (
                                  <>
                                    <TradeReferences
                                      nextStep={this.nextStep}
                                      prevStep={this.prevStep}
                                      handleChange={this.handleChange}
                                      submitTradeReferences={
                                        this.submitTradeReferences
                                      }
                                      addMenuHistory={this.addMenuHistory}
                                      values={values}
                                    />
                                  </>
                                );
                              case 4:
                                return (
                                  <>
                                    <SignatoryDetails
                                      nextStep={this.nextStep}
                                      prevStep={this.prevStep}
                                      handleChange={this.handleChange}
                                      handleSignatureChange={
                                        this.handleSignatureChange
                                      }
                                      handleSignature={this.handleSignature}
                                      updateSignatureType={
                                        this.updateSignatureType
                                      }
                                      submitSignatoryDetails={
                                        this.submitSignatoryDetails
                                      }
                                      signature_image_save={
                                        this.signature_image_save
                                      }
                                      initiateProcess={this.initiateProcess}
                                      stopProcess={this.stopProcess}
                                      addMenuHistory={this.addMenuHistory}
                                      values={values}
                                    />
                                  </>
                                );
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreditApp);
