import axios from "axios";

import $ from "jquery";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { axiosConfig } from "./utility";

export const listAgentForms = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/list_agent_assigned_forms",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgentDashboardForms = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/get_agent_dashboard_forms",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getClientFormVerification = async (token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/get_agent_form_verification",
          JSON.stringify({
            form_token: token,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          axiosConfig
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getAgentForm = async (
  accountno,
  token,
  form_accountno,
  form_token
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/get_agent_form",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_accountno: form_accountno,
            form_token: form_token,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const viewAgentFormSingleSubmission = async (
  accountno,
  token,
  form_token
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/get_agent_single_form_submission",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_token: form_token,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const viewClientFormSingleSubmission = async (
  accountno,
  token,
  form_token
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/get_client_single_form_submission",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_token: form_token,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getClientFormWithSubmissions = async (
  accountno,
  token,
  form_accountno,
  form_token
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "forms/get_agent_form_with_submissions",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_accountno: form_accountno,
            form_token: form_token,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const viewGeneralSubmission = async (
  accountno,
  token,
  form_accountno,
  id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/view_agent_submissions",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_accountno: form_accountno,
            id: id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteSubmission = async (
  accountno,
  token,
  form_accountno,
  id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/delete_agent_submissions",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_accountno: form_accountno,
            id: id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const sendFormEmail = async (
  accountno,
  token,
  form_accountno,
  emailtoname,
  emailto,
  emailsubject,
  emailcontent
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/send_agent_submissions_email",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_accountno: form_accountno,
            emailtoname: emailtoname,
            emailto: emailto,
            emailsubject: emailsubject,
            emailcontent: emailcontent,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveAgentResponse = async (
  accountno,
  token,
  response,
  form_accountno,
  form_token,
  form_title,
  form_desc,
  totalSteps
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/save_agent_response",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            response: response,
            form_accountno: form_accountno,
            form_token: form_token,
            form_title: form_title,
            form_desc: form_desc,
            totalSteps: totalSteps
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const allClientRejections = async (
  accountno,
  token,
  form_accountno,
  id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "forms/get_agent_rejections",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_accountno: form_accountno,
            id: id,
            submission_type: "agents"
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveClientResponseVerification = async (
  accountno,
  response,
  form_accountno,
  form_token,
  form_title,
  form_desc,
  totalSteps
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "forms/save_agent_response_verification",
          JSON.stringify({
            form_token: form_token,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_response: response,
            form_accountno: form_accountno,
            form_title: form_title,
            form_desc: form_desc,
            totalSteps: totalSteps,
          }),
          axiosConfig
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};



export const saveAgentResponseAsDraft = async (
  accountno,
  response,
  form_accountno,
  form_token,
  form_title,
  form_desc,
  submittedStep,
  totalSteps
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "forms/save_agent_response_draft",
          JSON.stringify({
            form_token: form_token,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_response: response,
            form_accountno: form_accountno,
            form_title: form_title,
            form_desc: form_desc,
            submittedStep: submittedStep,
            totalSteps: totalSteps,
          }),
          axiosConfig
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const saveAgentResponseDraft = async (
  accountno,
  response,
  form_accountno,
  form_token,
  form_title,
  form_desc,
  submittedStep,
  totalSteps
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "forms/save_agent_response_as_draft",
          JSON.stringify({
            form_token: form_token,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_response: response,
            form_accountno: form_accountno,
            form_title: form_title,
            form_desc: form_desc,
            submittedStep: submittedStep,
            totalSteps: totalSteps,
          }),
          axiosConfig
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};