import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { withRouter } from "react-router-dom";
import {
  getRfqs,
  deleteAnswerOption,
  deleteQuestions,
  listClients,
  getSingleRfq,
} from "./../../../config/quotation_api_calls";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { Fileupload } from "../../../components/Image/Fileupload";
import Tooltip from "@material-ui/core/Tooltip";
import MutextField from "@material-ui/core/TextField";
import dateFormat, { masks } from "dateformat";
import DataTable from "../../../components/Tables/DataTable";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const auth = new HelperClass();

const options = [
  { name: "Swedish", value: "sv" },
  { name: "English", value: "en" },
  { name: "Spanish", value: "es" },
  { name: "Urdu", value: "ur" },
];

class Rfqs extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      agentId: "",
      formLoader: true,
      successMessage: "",
      fileErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: false,
      smtp_test_button: false,
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      copied: false,
      copied2: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      reg_button_login: false,
      cancel_button: false,
      tableLoader: true,
      login_url: "",
      logo: "",
      signature_image: "",
      smtp_host: "",
      smtp_user: "",
      smtp_password: "",
      smtp_method: "tls",
      smtp_port: "",
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
      from_email: "",
      errorFromEmail: "",
      is_working: "",
      selected_options: [],
      optionErrorMessage: "",
      optionSuccessMessage: "",
      rfq_requirements: "",
      client_accountno: "",
      selected_accountno: null,
      all_active_clients: [],
      selected_account: "",
      rfq_accountno: "",
      rfq_attachment: "",
      all_answers: [],
      rfq_details: [],
      quotation_services: [],
      columnServices: [
        {
          name: "rfq_accountno",
          label: "RFQ #",
          options: {
            customBodyRender: (value) => {
              return value.split("###")[0];
            },
          },
        },
        
        
        {
          name: "dateCreated",
          label: "Date Created",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "client_title",
          label: "Client",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.clientTitle(value)}</div>;
            },
          },
        },
        {
          name: "rfq_status",
          label: "STATUS",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        /*{
          name: "rfq_attachement",
          label: "Attachment",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewPdf(value, tableMeta)}</div>;
            },
          },
        },*/

        {
          name: "ACTIONS",
          options: {
            customBodyRender: (value, tableMeta) => {
              let acc_data = tableMeta.rowData[0].split("###");
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showRequirements(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Show answer options"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-list"></em>
                                  <span>RFQ Details</span>
                                </a>
                              </li>

                              <li>
                                <a
                                  onClick={() =>
                                    this.create_quotation(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Assigned Services"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-grid-add-fill-c"></em>
                                  <span>Create Quotation</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    let client_accountno = this.props.match.params.client_accountno;
    this.setState({
      client_accountno: client_accountno,
    });
    const servicesResponce = await getRfqs(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    //console.log("getRfqQuestions: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
    this.listAllActiveClients();
  }

  create_quotation = async (value, tableMeta) => {
    let rfq_accountno_array = tableMeta.rowData[0].split("###");

    let rfq_accountno = rfq_accountno_array[0];
    let client_accountno = rfq_accountno_array[1];
    let quote_accountno = rfq_accountno_array[2];

    if (!quote_accountno && quote_accountno === "") {
      quote_accountno = this.generateRandomNumber();
      console.log("rfq_accountno_array: ", quote_accountno);
    }
    console.log("quote_accountno: ", quote_accountno);
    this.addMenuHistory(
      "/EditQuotations/" +
        client_accountno +
        "/" +
        quote_accountno +
        "/" +
        rfq_accountno
    );
  };

  generateRandomNumber = () => {
    const min = 1000000; // Minimum 10-digit number (10^9)
    const max = 9999999; // Maximum 10-digit number (10^10 - 1)
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
  };

  listAllActiveClients = async () => {
    const servicesResponce = await listClients(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("listAllActiveClients: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (
        servicesResponce.data.clients &&
        servicesResponce.data.clients.length > 0
      ) {
        for (let i = 0; i < servicesResponce.data.clients.length; i++) {
          let newArr = {
            label:
              servicesResponce.data.clients[i]["name"] +
              " (" +
              servicesResponce.data.clients[i]["comp_name"] +
              ")",
            value: servicesResponce.data.clients[i]["accountno"],
          };
          all_clients.push(newArr);
        }
      }
      this.setState({
        all_active_clients: all_clients,
      });
    } else {
      this.setState({
        errorMessage: "",
      });
    }
  };

  reloadClients = async () => {
    this.setState({
      tableLoader: true,
    });
    const servicesResponce = await getRfqs(
      auth.getAccount(),
      auth.getToken(),
      this.state.client_accountno
    );
    //console.log("getRfqQuestions: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  renderSearchIcon = () => (
    <span role="img" aria-label="drop-down-icon">
      ▼
    </span>
  );

  defaultText = (value, tableMeta) => {
    let is_default = value;
    if (is_default === "pending") {
      return <span className="badge badge-outline-primary">Pending</span>;
    } else if (is_default === "approved") {
      return <span className="badge badge-outline-success">Approved</span>;
    } else if (is_default === "completed") {
      return <span className="badge badge-success">Completed</span>;
    } else if (is_default === "cancelled") {
      return <span className="badge badge-outline-danger">Cancelled</span>;
    } else if (is_default === "in_review") {
      return <span className="badge badge-outline-warning">In Review</span>;
    }
  };

  clientTitle = (value, tableMeta) => {
    if (value !== "") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />
      );
    }
    return "---";
  };

  format_rfq_accountno = (value, tableMeta) => {
    let rfq_accountno = tableMeta.rowData[0].split("###");
    return rfq_accountno[0];
  };

  showRequirements = async (value, tableMeta) => {
    this.setState({
      formLoader: true,
      rfq_requirements: "",
    });
    let rfq_accountno_arr = tableMeta.rowData[0].split("###");
    let rfq_accountno = rfq_accountno_arr[0];
    let client_accountno = rfq_accountno_arr[1];

    const servicesResponce = await getSingleRfq(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      rfq_accountno
    );
    //console.log("getSingleRfq: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        rfq_details: [],
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      if (
        servicesResponce.data.data[0]["rfq_questions"] &&
        servicesResponce.data.data[0]["rfq_questions"].length > 0
      ) {
        let rfq_questions = servicesResponce.data.data[0]["rfq_questions"];
        for (let i = 0; i < rfq_questions.length; i++) {
          if (
            rfq_questions[i]["question_type"] === "radio" ||
            rfq_questions[i]["question_type"] === "dropdown"
          ) {
            let answer_id = this.doesAnswerIdExist(
              Number(rfq_questions[i]["answer_id"]),
              servicesResponce.data.all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
            console.log(
              "radio answer answer_id: " +
                rfq_questions[i]["answer_id"] +
                " " +
                answer_id
            );
          } else if (rfq_questions[i]["question_type"] === "checkbox") {
            let answer_id = this.doesAnswerArrayExist(
              JSON.parse(rfq_questions[i]["answer_id"]),
              servicesResponce.data.all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
            console.log(
              "checkbox answer answer_id: " +
                JSON.parse(rfq_questions[i]["answer_id"]) +
                " " +
                answer_id
            );
          } else {
            rfq_questions[i]["answer"] = rfq_questions[i]["answer_id"];
          }
        }
      }
      console.log(
        "rfq_questions: " +
          JSON.stringify(servicesResponce.data.data[0]["rfq_questions"])
      );
      this.setState({
        rfq_details: servicesResponce.data.data,
        quotation_services: servicesResponce.data.data[0]["rfq_services"],
        rfq_requirements: servicesResponce.data.data[0]["rfq_comments"],
        rfq_attachment: servicesResponce.data.data[0]["rfq_attachement"],
        all_answers: servicesResponce.data.all_answers,
      });
    } else {
      this.setState({
        rfq_details: [],
      });
      // window.location.replace("/error");
    }

    window.$("#modalFormOptionRfq").modal("show");
    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  doesAnswerIdExist = (answerIdToCheck, answerArray) => {
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const answer = answerArray.find((item) => item.id === answerIdToCheck);
    return answer ? answer.question_options : "";
  };

  doesAnswerArrayExist = (answerIdToCheck, answerArray) => {
    answerIdToCheck = JSON.stringify(answerIdToCheck);
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const objectValues = answerIdToCheck.slice(1, -1).split(",");
    var matchingItems = "";
    objectValues.map((setString, index) => {
      console.log("setString: ", setString);
      let answer = answerArray.find((item) => item.id === Number(setString));
      if (answer) {
        matchingItems +=
          "<p>" + (Number(index) + 1) + ": " + answer.question_options + "</p>";
      }
    });

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: matchingItems,
        }}
      />
    );
  };

  viewQuestionType = (value, tableMeta) => {
    let domain_str = tableMeta.rowData[4];

    if (domain_str === "") {
      return "---";
    } else if (domain_str !== "") {
      return (
        <span className="badge badge-outline-dark">
          <em className="icon ni ni-card-view"></em> View
        </span>
      );
    }
  };

  viewPdf = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <div>
          <a
            onClick={() => this.downloadPdf(value)}
            style={{ cursor: "pointer" }}
            title="Download Attachment"
          >
            <span className="badge badge-outline-secondary">
              {" "}
              <em className="icon ni ni-download"></em> View Attachment
            </span>
          </a>
        </div>
      );
    } else {
      return "---";
    }
  };

  downloadPdf = async (d_f_name) => {
    var url = d_f_name;
    let file_name = this.extractFileNameFromUrl(d_f_name);

    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  extractFileNameFromUrl = (url) => {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname; // Gets the path after the domain
    const fileName = pathname.split("/").pop(); // Extracts the last segment after splitting by '/'
    return fileName;
  };

  format_date = (value, tableMeta) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy, h:MM TT");
    } else {
      return "---";
    }
  };

  deleteQuestions = async (value, tableMeta) => {
    let question_id = tableMeta.rowData[0];
    this.setState({
      errorMessage: "",
      successMessage: "",
      disabled: true,
    });

    const servicesResponce = await deleteQuestions(
      auth.getAccount(),
      auth.getToken(),
      question_id
    );
    //console.log("addRfqQuestion: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "The question not found.",
        successMessage: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Question deleted successfully.",
        disabled: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      this.setState({
        errorMessage: "There is some error while deleting the question.",
        successMessage: "",
        disabled: false,
      });
    }

    setTimeout(() => {
      this.setState({ errorMessage: "", successMessage: "" });
    }, 5000);
  };

  deleteOption = async (id, question_id) => {
    this.setState({
      optionErrorMessage: "",
      optionSuccessMessage: "",
      disabled: true,
    });

    const servicesResponce = await deleteAnswerOption(
      auth.getAccount(),
      auth.getToken(),
      id,
      question_id
    );
    console.log("addRfqQuestion: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        optionErrorMessage:
          "There is some error while deleting the answer option.",
        optionSuccessMessage: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        optionErrorMessage: "",
        optionSuccessMessage: "Answer option deleted successfully.",
        disabled: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      this.setState({
        optionErrorMessage:
          "There is some error while adding the answer option.",
        optionSuccessMessage: "",
        disabled: false,
      });
    }

    setTimeout(() => {
      this.setState({ optionErrorMessage: "", optionSuccessMessage: "" });
    }, 5000);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  /*handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);
    if (changed_value) {
      this.setState(
        (prevState) => ({
          client_accountno: changed_value.value,
          selected_accountno: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          console.log("Updated state:", this.state.client_accountno);
          this.reloadClients();
        }
      );
    } else {
      this.setState({ selected_accountno: null });
    }
  };*/

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      this.setState(
        (prevState) => ({
          client_accountno: changed_value.value,
          selected_accountno: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          console.log("Updated state:", this.state.client_accountno);
          this.reloadClients();
        }
      );
    } else {
      this.setState(
        {
          client_accountno: null,
          selected_accountno: null,
        },
        () => {
          console.log("Cleared selection");
          this.reloadClients();
        }
      );
    }
  };

  clearValue = () => {
    this.setState({ selected_accountno: null });
  };

  validateIPAddress = (ipAddress) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValid = ipRegex.test(ipAddress);
    //console.log(`Is IP Address valid? ${isValid}`);
    return isValid;
  };

  validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = domainRegex.test(domain);
    //console.log(`Is domain valid? ${isValid}`);
    if (isValid) {
      return true;
    }
    return false;
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  addMenuHistory = (link) => {
    this.props.history.push(link);
  };

  render() {
    const searchParams = new URLSearchParams(window.location.search);
    const rfq_accountno = searchParams.get("rfq_accountno");
    const log_type = searchParams.get("log_type");
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div
                className="nk-block-head nk-block-head-sm"
                id="HeadContentRfq"
              >
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>

                      <Typography color="textPrimary" className="">
                        Request for Quotations
                      </Typography>
                    </Breadcrumbs>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        datatarget="pageMenu"
                      >
                        {/*<em className="icon ni ni-more-v"></em>*/}
                      </a>
                      <div
                        className="toggle-expand-content"
                        datacontent="pageMenu"
                      >
                        {/*<ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              style={{ cursor: "pointer", color: "#fff" }}
                              onClick={() => this.addMenuHistory("/AddNewRfq")}
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Add New Quotation
                            </a>
                          </li>
                      </ul>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-gs">
                {/* Table 5th Col Start */}
                <div className="col-xxl-12">
                  <div className="nk-block nk-block-lg">
                    {this.state.errorMessage !== "" ? (
                      <div
                        className="example-alert"
                        style={{ marginBottom: "15px" }}
                      >
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h4>Error</h4>
                            <p>{this.state.errorMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.successMessage !== "" ? (
                      <div
                        className="example-alert"
                        style={{ marginBottom: "15px" }}
                      >
                        <div className="alert alert-pro alert-success">
                          <div className="alert-text">
                            <h4>Success</h4>
                            <p>{this.state.successMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div class="card" style={{ borderRadius: "0px" }}>
                      <div class="card-inner border-bottom">
                        <div className="row g-gs mb-3">
                          <div className="col-md-6">
                            <div class="form-group">
                              <div class="form-label-group">
                                <label class="form-label">
                                  Choose Client{" "}
                                  <span class="text-danger"></span>
                                </label>
                              </div>
                              <div class="form-control-group">
                                <Select
                                  options={this.state.all_active_clients}
                                  name="selected_accountno"
                                  placeholder="Select a client to view their RFQs."
                                  value={this.state.selected_accountno}
                                  autoComplete="off"
                                  emptyMessage="Clients not found"
                                  onChange={this.handleChangeSearch}
                                  isClearable
                                  isSearchable
                                  components={animatedComponents}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      lineHeight: "40px",
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* START DATATABLE */}
                    {this.state.tableLoader === true ? (
                      tableLoader()
                    ) : (
                      <DataTable
                        columns={this.state.columnServices}
                        accountnoFromParams={rfq_accountno}
                        log_type={log_type}
                        tableData={this.state.tableData}
                        title=""
                      />
                    )}

                    {/* END TABLE */}
                  </div>
                </div>
                {/* Table 5th Col End */}
              </div>

              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalFormOptionRfq"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-list-round"></em> RFQ
                          Details{" "}
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* NRC DIVS START */}

                      {this.state.formLoader === true ? (
                        FormLoader()
                      ) : (
                        <>
                          <div>
                            <div className="card card-bordered">
                              <div className="card-inner-group">
                                {this.state.rfq_details &&
                                this.state.rfq_details[0].rfq_questions.length >
                                  0 ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">
                                        General Assesment
                                      </h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <table className="table table-striped">
                                        <thead>
                                          <tr>
                                            <th className="w-150px" style={{paddingLeft: "0px;"}}>
                                              Question
                                            </th>
                                            <th className="w-60">Answer</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.rfq_details[0].rfq_questions.map(
                                            (rfq, index) => (
                                              <tr key={index}>
                                                <td>{rfq.question}</td>
                                                <td>{rfq.answer}</td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.quotation_services &&
                                this.state.quotation_services.length > 0 ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">
                                        Products & Services
                                      </h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      {this.state.rfq_details &&
                                      this.state.rfq_details.length > 0 ? (
                                        <table className="table table-striped">
                                          <thead>
                                            <tr>
                                              <th className="w-60" style={{paddingLeft: "1.25rem"}}>
                                                Product Name
                                              </th>
                                              
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.quotation_services.map(
                                              (service, sid) => (
                                                <tr key={`ser${sid}`}>
                                                  <td>
                                                    {service.service_name}
                                                  </td>
                                                  {/*<td>
                                                    {service.sku &&
                                                    service.sku !== ""
                                                      ? service.sku
                                                      : "---"}
                                                  </td>
                                                  <td>{service.quantity}</td>
                                                  <td>
                                                    {service.service_unit &&
                                                    service.service_unit !== ""
                                                      ? service.service_unit
                                                      : "---"}
                                                  </td>*/}
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.rfq_attachment &&
                                this.state.rfq_attachment !== "" ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">Attachement</h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <ul className="row gx-1">
                                        <li className="col-sm-5">
                                          <p>
                                            <a
                                              onClick={() =>
                                                this.downloadPdf(
                                                  this.state.rfq_attachment
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                              title="Download Attachment"
                                            >
                                              <span className="badge badge-outline-secondary">
                                                {" "}
                                                <em className="icon ni ni-download"></em>{" "}
                                                Download Attachment
                                              </span>
                                            </a>
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}

                                <div className="card-inner">
                                  <div className="sp-plan-head-group">
                                    <div className="sp-plan-head">
                                      <h6 className="title">Requirement</h6>
                                      <span className=" text-dark">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: this.state.rfq_requirements,
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Rfqs));
