import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import $ from "jquery";
import {
  getCommissionReports,
  listClientsSuper,
  listCompleteOrders,
  sendDepositEmail,
  getBillingDetails,
} from "./../../../config/agent_reports_api_calls";
import { APP_LIVE_URL, APP_URL, API_URL } from "../../../config/config";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Fileupload } from "../../../components/Image/Fileupload";
import TextField from "../../../components/InputFields/TextField";
import DataTable from "../../../components/Tables/DataTable";
import moment from "moment";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { extendMoment } from "moment-range";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import dateFormat, { masks } from "dateformat";

const extendedMoment = extendMoment(moment);
const animatedComponents = makeAnimated();
const auth = new HelperClass();

class CommissionReport extends Component {
  constructor() {
    super();
    const today = extendedMoment();
    const monthAgo = moment().subtract(1, "month");
    this.state = {
      tableData: [],
      errorMessage: "",
      successMessage: "",
      tableLoader: true,
      //start_date: monthAgo.format("YYYY-MM-DD"),
      //end_date: today.format("YYYY-MM-DD"),
      start_date: "",
      end_date: "",
      selected_order_accountno: null,
      selected_client_accountno: null,
      selected_agent_accountno: auth.getAccount(),
      value: moment.range(monthAgo, today),
      commission_title_array: "get_commission_reports",
      commissionReportsArray: [],
      all_active_agents: [],
      all_active_clients: [],
      all_complete_orders: [],
      deposit_main_section: false,
      depositErrorMessage: "",
      depositSuccessMessage: "",
      emailto: "",
      emailcc: "",
      emailbcc: "",
      emailsubject: "",
      emailcontent: "",
      email_button: false,
      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",
      orderno: "",
      form_title: "",
      formDetails: [],
      formGroups: [],
      is_submitted: "",
      payment_method_title: "",
      selected_payment_method: "",
      paymentDetails: [],
      columnsArray: [
        {
          name: "order_created_date",
          label: "Order Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "orderno",
          label: "Order #",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatOrderno(value)}</div>;
            },
          },
        },
        {
          name: "client_details",
          label: "Client",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatCommission(value)}</div>;
            },
          },
        },

        {
          name: "order_total",
          label: "Order Total",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatAsCurrency(value)}</div>;
            },
          },
        },
        {
          name: "total_payments",
          label: "Order Total Paid",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatAsCurrency(value)}</div>;
            },
          },
        },
        {
          name: "payment_status",
          label: "Order Payment",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatCommission(value)}</div>;
            },
          },
        },
        {
          name: "order_commission",
          label: "Commission",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatAsCurrency(value)}</div>;
            },
          },
        },
        {
          name: "commission_approved",
          label: "Commission Approved",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatCommission(value)}</div>;
            },
          },
        },
        {
          name: "total_paid_commissions",
          label: "Paid Commission",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatAsCurrency(value)}</div>;
            },
          },
        },
        {
          name: "commission_paid",
          label: "Commission Paid",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatCommission(value)}</div>;
            },
          },
        },
        {
          name: "orderno",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              let edit_url = "/ViewSalesOrder/" + tableMeta.rowData[0];

              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.open_deposit_request(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Submit Deposit Request"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em class="icon ni ni-card-view"></em>
                                  <span>Request Payment</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: "client_company",
          label: "Client Company",
          options: { display: false },
        },
        {
          name: "client_email",
          label: "Client Email",
          options: { display: false },
        },
      ],
    };
  }

  async componentDidMount() {
    await this.getActiveClients();
    await this.getCompleteOrders();
    const reportsData = await getCommissionReports(
      auth.getAccount(),
      auth.getToken(),
      this.state.start_date,
      this.state.end_date,
      this.state.selected_order_accountno,
      this.state.selected_client_accountno
    );
    //console.log("getCommissionReports: ", reportsData.data);
    console.log("start_date: ", this.state.start_date);
    console.log("end_date: ", this.state.end_date);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        commissionReportsArray: reportsData.data.data,
        tableLoader: false,
      });
    } else {
      //console.log("Last: ", reportsData.data);
      //window.location.replace("/error");
    }
  }

  regenerate_report = async () => {
    const {
      start_date,
      end_date,
      selected_order_accountno,
      selected_client_accountno,
      selected_agent_accountno,
    } = this.state;
    $("#viewBtn").hide();
    $("#btnloader").show();
    this.setState({ tableLoader: true });
    const reportsData = await getCommissionReports(
      auth.getAccount(),
      auth.getToken(),
      start_date,
      end_date,
      selected_order_accountno,
      selected_client_accountno
    );
    //console.log("getCommissionReports regenerate_report: ", reportsData.data);
    console.log("start_date: ", start_date);
    console.log("end_date: ", end_date);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      $("#viewBtn").show();
      $("#btnloader").hide();
      this.setState({
        tableLoader: false,
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        commissionReportsArray: reportsData.data.data,
        tableLoader: false,
      });
      $("#viewBtn").show();
      $("#btnloader").hide();
    } else {
      //console.log("Last: ", reportsData.data);
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
      });
      $("#viewBtn").show();
      $("#btnloader").hide();
    }
  };

  open_deposit_request = async (value, tableMeta) => {
    //console.log("open_deposit_request: ", tableMeta.rowData);
    let order_paid = tableMeta.rowData[5];
    let commission_approved = tableMeta.rowData[7];
    let commission_paid = tableMeta.rowData[9];
    let orderno = tableMeta.rowData[1];

    this.setState({
      deposit_main_section: false,
      depositErrorMessage: "",
      depositSuccessMessage: "",
      email_button: false,
      emailErrorMessageTitle: "",
      orderno: orderno,
      payment_method_title: "",
      selected_payment_method: "",
      paymentDetails: []
    });
    if (order_paid !== "paid") {
      this.setState({
        deposit_main_section: false,
        depositErrorMessage:
          "You cannot request payment because the order has not been paid.",
        emailErrorMessageTitle: "Order Not Paid",
      });
    } else if (order_paid === "paid" && commission_paid === "YES") {
      this.setState({
        deposit_main_section: false,
        depositErrorMessage: "Your commission for this order has been paid.",
        emailErrorMessageTitle: "Commission Paid",
      });
    } else if (order_paid === "paid" && commission_paid === "NO") {
      await this.getBillingDetails();
      this.setState({
        deposit_main_section: true,
        depositErrorMessage: "",
        emailErrorMessageTitle: "",
      });
    }
    window.$("#modalDeposit").modal("show");
  };

  modalHideMarketingPDF = (id) => {
    window.$("#" + id).modal("hide");
  };

  formatOrderno = (value) => {
    return (
      <a
        href={`${APP_URL}ViewSalesOrder/${value}`}
        className="text-primary"
        target="_blank"
      >
        {value}
      </a>
    );
  };

  getActiveClients = async () => {
    const servicesResponce = await listClientsSuper(
      auth.getAccount(),
      auth.getToken()
    );

    //console.log("getActiveClients: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        signupLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          if (servicesResponce.data.data[i][5] === "active__Yes") {
            let newArr = {
              label:
                servicesResponce.data.data[i][0] +
                " (" +
                servicesResponce.data.data[i][1] +
                ")",
              value: servicesResponce.data.data[i][4],
              agent_accountno: servicesResponce.data.data[i][6],
            };
            all_clients.push(newArr);
          }
        }
      }
      this.setState({
        all_active_clients: all_clients,
        filtered_clients: all_clients,
        signupLoader: false,
      });
    } else {
      this.setState({
        signupLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  getCompleteOrders = async () => {
    const servicesResponce = await listCompleteOrders(
      auth.getAccount(),
      auth.getToken()
    );

    //console.log("getCompleteOrders: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        signupLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label:
              servicesResponce.data.data[i][0] +
              " - " +
              this.formatAsCurrency(servicesResponce.data.data[i][2]) +
              " - " +
              this.format_date(servicesResponce.data.data[i][4]),
            value: servicesResponce.data.data[i][0],
            client_accountno: servicesResponce.data.data[i][1],
            agent_accountno: servicesResponce.data.data[i][3],
          };
          all_clients.push(newArr);
        }
      }
      this.setState({
        all_complete_orders: all_clients,
        filtered_orders: all_clients,
        signupLoader: false,
      });
    } else {
      this.setState({
        signupLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  getBillingDetails = async () => {
    const servicesResponce = await getBillingDetails(
      auth.getAccount(),
      auth.getToken()
    );

    //console.log("getBillingDetails: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        form_title: "",
        formDetails: [],
        is_submitted: "No",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let is_submitted = "No";
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        console.log(
          "servicesResponce.data.data[0].is_submitted: ",
          servicesResponce.data.data[0].is_submitted
        );
        is_submitted =
          servicesResponce.data.data[0].is_submitted === 1 ? "Yes" : "No";
      }
      console.log("is_submitted: ", is_submitted);
      this.setState({
        form_title: servicesResponce.data.form_title,
        formDetails: servicesResponce.data.data,
        formGroups: JSON.parse(servicesResponce.data.groups),
        is_submitted: is_submitted,
      });
    } else {
      this.setState({
        form_title: "",
        formDetails: [],
        is_submitted: "No",
      });
    }
  };

  fieldValidation = () => {
    let { emailto, emailsubject, emailcontent } = this.state;

    /*if (emailsubject === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        depositErrorMessage: "Subject is required.",
      });
      return false;
    } else*/ if (emailcontent === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        depositErrorMessage: "Notes are required.",
      });
      return false;
    } else {
      this.setState({
        emailErrorMessageTitle: "",
        depositErrorMessage: "",
      });
    }
    return true;
  };

  submitDepositEmail = async () => {
    const { orderno, emailsubject, emailcontent, selected_payment_method, payment_method_title } = this.state;
    //paymentMethodDetails
    const modalBody = document.getElementById("modalDeposit");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }
    
    if (this.fieldValidation() === true) {
      this.setState({
        email_button: true,
        depositErrorMessage: "",
        emailErrorMessageTitle: "",
        emailSuccessMessage: "",
      });
      let paymentDetails = document.getElementById("paymentMethodDetails").innerHTML;
      const servicesResponce = await sendDepositEmail(
        auth.getAccount(),
        auth.getToken(),
        orderno,
        emailsubject,
        emailcontent,
        selected_payment_method,
        paymentDetails,
        payment_method_title
      );
      //console.log("submitDepositEmail: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          emailErrorMessageTitle: "System Error",
          depositErrorMessage:
            "There is some error while processing your request.",
          email_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          emailSuccessMessage: "Email sent successfully.",
          emailErrorMessageTitle: "",
          depositErrorMessage: "",
          emailsubject: "",
          emailcontent: "",
          email_button: false,
          paymentDetails: [],
          selected_payment_method: ""
        });
      } else {
        this.setState({
          emailErrorMessageTitle: "System Error",
          depositErrorMessage:
            "There is some error while processing your request.",
          email_button: false,
        });
      }
    }
    if (
      this.state.depositErrorMessage !== "" ||
      this.state.emailSuccessMessage !== ""
    ) {
      setTimeout(() => {
        this.setState({
          depositErrorMessage: "",
          emailSuccessMessage: "",
        });
      }, 4000);
    }
  };

  handleChangeSearchClient = async (changed_value) => {
    //console.log("handleChangeSearchClient: ", changed_value);
    if (changed_value) {
      const selected_client_accountno = changed_value.value;
      this.setState(
        (prevState) => ({
          selected_client_accountno: changed_value.value,
          selected_client: changed_value,
          selected_order: null,
          filtered_orders: prevState.all_complete_orders.filter(
            (order) =>
              order.agent_accountno === prevState.selected_agent_accountno &&
              order.client_accountno === selected_client_accountno
          ),
        }),
        () => {
          console.log(
            "Updated client state:",
            this.state.selected_client_accountno
          );
        }
      );
    } else {
      this.setState({
        selected_client: null,
        selected_order: null,
        selected_client_accountno: null,
        filtered_orders: this.state.all_complete_orders.filter(
          (order) =>
            order.agent_accountno === this.state.selected_agent_accountno
        ),
      });
    }
  };

  handleChangeSearchOrders = async (changed_value) => {
    //console.log("handleChangeSearchOrders: ", changed_value);
    if (changed_value) {
      this.setState(
        (prevState) => ({
          selected_order_accountno: changed_value.value,
          selected_order: changed_value,
        }),
        () => {
          console.log(
            "Updated order state:",
            this.state.selected_order_accountno
          );
        }
      );
    } else {
      this.setState({ selected_order: null, selected_order_accountno: null });
    }
  };

  onSelect = (value) => {
    this.setState({
      value,
      start_date: value.start.format("YYYY-MM-DD"),
      end_date: value.end.format("YYYY-MM-DD"),
      isOpen: false,
    });
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  format_date = (value) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  format_client = (value, tableMeta) => {
    if (value && value !== "") {
      return (
        <div>
          {value} ({tableMeta.rowData[11]})<br />
          <span
            class="text-info"
            style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          >
            {tableMeta.rowData[12]}
          </span>
        </div>
      );
    }
  };

  formatCommission = (value) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    );
  };

  formatAsCurrency = (amount) => {
    //console.log("amount: "+  typeof amount + " - " + amount);
    //console.log("amount: ", typeof amount);
    if (!amount) {
      amount = 0;
    }
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: this.decimalPoint,
      maximumFractionDigits: this.decimalPoint,
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeSelect = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    let field_value = "";
    let payment_method_title = "";
    if(value && value !== ""){
      let valArray = value.split("###");
      field_value = valArray[0];
      payment_method_title = valArray[1];
    }
    this.setState({
      [name]: field_value,
    });
    //Show Details
    if(field_value && field_value !== ""){
      const { formDetails, formGroups } = this.state;
      let group_record = this.findMatchingQuestions(
        field_value,
        formGroups,
        formDetails
      );
      this.setState({
        paymentDetails: group_record,
        payment_method_title: payment_method_title
      });
      //console.log("group_record: ", group_record);
    } else {
      this.setState({
        paymentDetails: [],
        payment_method_title: ""
      });
    }
  };

  findMatchingQuestions = (groupId, arrayA, arrayB) => {
    console.log("groupId: ", groupId);
    console.log("arrayA: ", arrayA);
    console.log("arrayB: ", arrayB);
    // Step 1: Find the group with the given ID in Array A
    const matchedGroup = arrayA.find((group) => group.id === groupId);
    if (!matchedGroup) {
      console.log("No group found with the given ID.");
      return [];
    }

    console.log("Matched Group:", matchedGroup);

    // Step 2: Collect all matched questions from Array B
    const matchedRecords = [];
    let arrayBs = JSON.parse(arrayB[0].response);
    
    arrayBs.forEach((item) => {
      console.log("Item Questions:", item.questions);
      //let response = JSON.parse(item.response);
      if (item.questions && Array.isArray(item.questions)) {
        const matches = item.questions.filter((q) =>
          matchedGroup.questions.includes(q.id)
        );
        console.log("Matches Found:", matches);
        matchedRecords.push(...matches); // Add matched questions to the result array
      }
    });

    console.log("Matched Records:", matchedRecords);
    return matchedRecords;
  };

  render() {
    const { isOpen, value, start_date, end_date } = this.state;
    const formattedDateRange = start_date ? `${start_date} - ${end_date}` : "";
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      {" "}
                      <em className="icon ni ni-list-round"></em> Commission
                      Report
                    </h3>
                  </div>
                </div>
              </div>

              <div className="row align-items-end g-4">
                <div className="col-lg-4 col-xxl-4">
                  <div onClick={this.onToggle} className="form-group">
                    <TextField
                      label="Date Range"
                      variant="outlined"
                      fullWidth
                      value={formattedDateRange}
                      InputProps={{
                        onClick: this.onToggle,
                      }}
                      placeholder="Select Date Range"
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-xxl-4">
                  <div className="form-group">
                    <Select
                      options={this.state.filtered_clients}
                      name="selected_accountno"
                      placeholder="Select Clients"
                      value={this.state.selected_client}
                      autoComplete="off"
                      emptyMessage="Clients not found"
                      onChange={this.handleChangeSearchClient}
                      isClearable
                      isSearchable
                      components={animatedComponents}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          lineHeight: "30px",
                        }),
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-xxl-4">
                  <div className="form-group">
                    <Select
                      options={this.state.filtered_orders}
                      name="selected_accountno"
                      placeholder="Select Orders"
                      value={this.state.selected_order}
                      autoComplete="off"
                      emptyMessage="Clients not found"
                      onChange={this.handleChangeSearchOrders}
                      isClearable
                      isSearchable
                      components={animatedComponents}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          lineHeight: "30px",
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row g-4" style={{ display: "block" }}>
                <div className="text-right mt-2 mb-2">
                  <button
                    type="button"
                    id="viewBtn"
                    className="btn btn-lg btn-primary px-4"
                    onClick={() => {
                      this.regenerate_report();
                    }}
                    disabled={this.state.disabled}
                  >
                    <em class="icon ni ni-search"></em>
                    <span>Apply</span>
                  </button>
                  <button
                    id="btnloader"
                    class="btn btn-lg btn-primary px-4"
                    type="button"
                    style={{ display: "none" }}
                    disabled="disabled"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </button>
                </div>
              </div>
              {isOpen && (
                <div
                  className="fixed inset-0"
                  style={{
                    zIndex: 1000,
                    position: "absolute",
                    marginTop: "-80px",
                  }}
                >
                  <div
                    className="absolute inset-0"
                    onClick={this.onToggle}
                  ></div>
                  <div
                    className="absolute top-40 left-1/2 -translate-x-1/2 bg-white shadow-lg rounded-lg p-4"
                    style={{ zIndex: 1001 }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        cursor: "pointer",
                        zIndex: 1002,
                      }}
                      onClick={this.onToggle}
                    >
                      <em className="icon ni ni-cross"></em>
                    </div>
                    <DateRangePicker
                      value={value}
                      onSelect={this.onSelect}
                      singleDateRange={true}
                      numberOfCalendars={2}
                      showLegend={true}
                    />
                  </div>
                </div>
              )}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <>
                          <DataTable
                            columns={this.state.columnsArray}
                            tableData={this.state.commissionReportsArray}
                            title=""
                          />
                        </>
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
          {/* Link Modal MODAL START */}
          <div className="modal fade zoom" tabIndex="-1" id="modalDeposit">
            <div
              className="modal-dialog modal-xl modal-dialog-top"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div
                    className="modal-title"
                    style={{ textAlign: "center", fontSize: "20px" }}
                  >
                    <strong>
                      <em className="icon ni ni-list-round"></em> Submit Payment
                      Request
                    </strong>
                  </div>
                  <a
                    className="close"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.modalHideMarketingPDF("modalDeposit");
                    }}
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body">
                  {this.state.depositErrorMessage !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>{this.state.emailErrorMessageTitle}</h4>

                          <p>{this.state.depositErrorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.is_submitted === "No" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="alert alert-pro alert-warning">
                        <div className="alert-text">
                          <h4>Billing Form Missing</h4>

                          <p>
                            You have not submissted the billing / payment
                            details form.{" "}
                            <a
                              href={`${APP_URL}cp_forms`}
                              target="_blank"
                              style={{ fontWeight: "bold" }}
                            >
                              Click Here
                            </a>{" "}
                            to add the billing information. If do not not find
                            this form please contact admin.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.deposit_main_section === true ? (
                    <div class="row g-gs">
                      {this.state.emailSuccessMessage !== "" ? (
                        <div class="col-sm-12">
                          <div class="alert alert-pro alert-success">
                            <div class="alert-text">
                              <h6>Success</h6>
                              <p>{this.state.emailSuccessMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div class="col-sm-12">
                        <p>
                          This form is designed for channel partners to request
                          clearance of their commission for specific order. By
                          submitting this form, the channel partner notifies the
                          admin to review the order details and send the
                          commission payment. Please ensure all required
                          information is accurate to avoid delays in processing.
                        </p>
                      </div>
                      {this.state.formGroups &&
                      this.state.formGroups.length > 0 ? (
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label class="form-label" htmlFor="emailsubject">
                              Payment Method <sup>*</sup>
                            </label>
                            <div class="form-control-wrap ">
                              <div class="form-control-select">
                                <select
                                  name="selected_payment_method"
                                  class="form-control form-control-lg "
                                  id="default-06"
                                  onChange={this.handleChangeSelect}
                                >
                                  <option value="" key="2weds">Select payment method</option>
                                  {this.state.formGroups.map((grp, index) => (
                                    <option value={`${grp.id}###${grp.group_title}`} key={`aa${index}`}>
                                      {grp.group_title}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.paymentDetails &&
                      this.state.paymentDetails.length > 0 ? (
                        <div class="col-sm-12">
                          <div className="card card-bordered mt-1">
                            <div class="card-inner-group">
                              <div class="card-inner">
                                <div class="sp-plan-head">
                                  <h6 class="title">Payment Method Details</h6>
                                </div>
                                <div class="sp-plan-desc sp-plan-desc-mb" id="paymentMethodDetails">
                                  <table class="table table-bordered table-striped">
                                    <tbody class="text-dark">
                                      {this.state.paymentDetails.map(
                                        (pay, idx) => (
                                          <tr key={`pid${idx}`}>
                                            <td style={{ fontWeight: "600" }}>
                                              {pay.questionText}
                                            </td>
                                            <td>{pay.answer}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      
                      <div class="col-sm-12 mt-2">
                        <div class="form-group">
                          <label class="form-label" htmlFor="emailcontent">
                            Notes <sup>*</sup>
                          </label>
                          <div class="form-control-wrap">
                            <textarea
                              class="form-control"
                              id="emailcontent"
                              name="emailcontent"
                              value={this.state.emailcontent}
                              onChange={this.handleChange}
                              maxLength="500"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 mt-2 d-flex justify-content-end">
                        <div class="nk-block-head-content">
                          <ul class="nk-block-tools gx-3">
                            {this.state.email_button === true ? (
                              <button
                                className="btn btn-primary"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span> Processing... </span>
                              </button>
                            ) : (
                              <li>
                                <a
                                  onClick={() => this.submitDepositEmail()}
                                  style={{
                                    cursor: "pointer",
                                    color: "#fff",
                                  }}
                                  class="btn btn-primary"
                                >
                                  <em class="icon ni ni-check-round-cut"></em>{" "}
                                  <span>Submit Request</span>{" "}
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />
                </div>
              </div>
            </div>
          </div>
          {/* LINK MODAL End */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommissionReport);
