import { React, createRef } from "react";
import { Component } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { addSettings, addMsaInfo } from "../../../actions";
import { ValidateEmail, normalize } from "../../../config/utility";
import {
  listSubAgents,
  getSingleAgent,
  editSubAgent,
  listServices,
  getAgentSplit,
  addAgentSplit,
  ListSubAgentAssignRoles,
  editAgentAssignRole,
} from "./../../../config/api_calls";
import HelperClass from "./../../../config/helperClass";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import PageTitle from "../ExtraComponents/PageTitle";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import MutextField from "@material-ui/core/TextField";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Select from "react-select";
import countryList from "react-select-country-list";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const auth = new HelperClass();
class Agents extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      servicesData: [],
      splitData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      errorMessageEdit: "",
      successMessageEdit: "",
      title: "",
      name: "",
      agent_email: "",
      agent_phone: "",
      agent_password: "",
      c_password: "",
      agent_id: "",
      sub_agent_id: "",
      formValues: [],
      comp: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      address: "",
      address2: "",
      passwordShown: false,
      confirmPasswordShown: false,
      notifyCheckBox: false,
      account_status: "",
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      errorMessageSubAgentRole: "",
      successMessageSubAgentRole: "",
      subassignRoleData: [],
      subassignSubRoleData: [],
      subchangedRolesRights: [],
      subchangedSubRolesRights: [],
      checkedRoleIds: [],
      checkedSubRoleIds: [],
      checkedName: "",
      checkedEmail: "",

      errorName: "",
      errorStatus: "",
      errorAgentCompany: "",
      errorAgentEmail: "",
      errorAgentPhone: "",
      errorAgentAddress: "",
      errorAgentCity: "",
      errorAgentState: "",
      errorAgentZip: "",
      errorAgentCountry: "",
      errorAgentPassword: "",
      errorCPassword: "",
      useGooglePlaces: true,
      toggleButtonText: "Allow PO Box Address",

      columnServices: [
        { name: "Date Added" },
        { name: "Title" },
        { name: "Name" },
        { name: "Email" },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.editAgentForm(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit Sub Agent"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Sub Agent</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.agentSplit(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit Sub Agent"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-share"></em>
                                  <span>Agent Split</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.subagentAssignRole(value, tableMeta)
                                  }
                                  title="Assign Role"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-grid-c"></em>
                                  <span>Assign Role</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
    this.phoneInputRef = createRef();
    this.countryOptions = countryList()
      .getData()
      .filter(
        (country) => country.label !== "United States Minor Outlying Islands"
      );
  }

  async componentDidMount() {
    const servicesResponce = await listSubAgents(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("sub-agent.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  }

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      useGooglePlaces: !prevState.useGooglePlaces,
      toggleButtonText:
        prevState.toggleButtonText === "Allow PO Box Address"
          ? "Allow Simple Address"
          : "Allow PO Box Address",
    }));
  };

  handleFocus = () => {
    // Move cursor to the end of the input value
    const input = this.phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };

  handleAddressSelect = async (place) => {
    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        let neighborhood = null;
        let locality = null;
        let address2 = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            neighborhood = component.long_name;
          } else if (component.types.includes("locality")) {
            locality = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("subpremise")) {
            address2 = component.long_name;
          } else if (
            ![
              "neighborhood",
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += " ";
            }
            streetAddress += component.long_name;
          }
        });
        // Check if neighborhood is part of the formatted address
        if (neighborhood && formattedAddress.includes(neighborhood)) {
          city = neighborhood;
        } else {
          city = locality;
        }
        this.setState({
          city: city && city !== "" ? city : "",
          state: state && state !== "" ? state : "",
          zip: zipCode && zipCode !== "" ? zipCode : "",
          country: country && country !== "" ? country : "",
          address: streetAddress && streetAddress !== "" ? streetAddress : "",
          address2: address2 && address2 !== "" ? address2 : "",
        });
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    });
  };

  toggleConfirmPassword = () => {
    this.setState({
      confirmPasswordShown: !this.state.confirmPasswordShown,
    });
  };

  subagentAssignRole = async (value, tableMeta) => {
    const agentId = tableMeta.rowData[5];
    const agentEmail = tableMeta.rowData[3];
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      agentRoleLoader: true,
      disableRoleBtn: false,
      disableSubRoleBtn: false,
      rolesAgentName: tableMeta.rowData[2],
      checkedName: tableMeta.rowData[2],
      checkedEmail: tableMeta.rowData[3],
    });
    window.$("#modalSubAgentAssignRole").modal("show");
    this.getSubAgentRoleData(agentId, agentEmail);
  };

  getSubAgentRoleData = async (agentId, agentEmail) => {
    const listSubAgentAssignRoles = await ListSubAgentAssignRoles(
      auth.getAccount(),
      auth.getToken(),
      agentId,
      agentEmail
    );

    console.log("ListSubAgentRoles Res: ", listSubAgentAssignRoles.data.data);
    if (
      listSubAgentAssignRoles.data.status === 403 ||
      listSubAgentAssignRoles.data.errors === "authentication missing" ||
      listSubAgentAssignRoles.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listSubAgentAssignRoles.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listSubAgentAssignRoles.data.status === 200 &&
      listSubAgentAssignRoles.data.message === "success"
    ) {
      const assignRoleData = listSubAgentAssignRoles.data.data.roles;
      const assignSubRoleData = listSubAgentAssignRoles.data.data.subroles;
      const allRoleData = listSubAgentAssignRoles.data.data.Allroles.map(
        (role) => {
          const isActiveRole = assignRoleData.some(
            (assignedRole) =>
              assignedRole.alt === role.alt && assignedRole.isactive === 1
          );
          return {
            ...role,
            isChecked: isActiveRole,
          };
        }
      );

      const allSubRoleData = listSubAgentAssignRoles.data.data.Allsubroles.map(
        (subRole) => {
          const isActiveSubRole = assignSubRoleData.some(
            (assignedSubRole) =>
              assignedSubRole.alt === subRole.alt &&
              assignedSubRole.isactive === 1
          );
          return {
            ...subRole,
            isChecked: isActiveSubRole,
          };
        }
      );

      this.setState({
        assignRoleData,
        assignSubRoleData,
        allRoleData,
        allSubRoleData,
        agentRoleLoader: false,
      });

      const initialActiveRoles = assignRoleData
        .filter((role) => role.isactive === 1)
        .map((role) => ({
          alt: role.alt,
          isChecked: true,
        }));

      const initialActiveSubRoles = assignSubRoleData
        .filter((subRole) => subRole.isactive === 1)
        .map((subRole) => ({
          id: subRole.id,
          isChecked: true,
        }));

      this.setState({
        changedRolesRights: initialActiveRoles,
        changedSubRolesRights: initialActiveSubRoles,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  changedAllRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedRoleIds in the state
    const { checkedRoleIds } = this.state;
    const updatedCheckedRoleIds = checked
      ? [...checkedRoleIds, name] // add ID if checked
      : checkedRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedRoleIds: updatedCheckedRoleIds });

    // Update allRoleData with isChecked property
    const { allRoleData, allSubRoleData } = this.state;
    const updatedAllRoleData = allRoleData.map((role) => {
      if (role.id.toString() === name) {
        return {
          ...role,
          isChecked: checked,
        };
      }
      return role;
    });

    // Update associated sub roles
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.role_id.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({
      allRoleData: updatedAllRoleData,
      allSubRoleData: updatedAllSubRoleData,
    });
  };

  changedAllSubRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedSubRoleIds in the state
    const { checkedSubRoleIds } = this.state;
    const updatedCheckedSubRoleIds = checked
      ? [...checkedSubRoleIds, name] // add ID if checked
      : checkedSubRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedSubRoleIds: updatedCheckedSubRoleIds });

    // Update allSubRoleData with isChecked property
    const { allSubRoleData } = this.state;
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.tabdetailid.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({ allSubRoleData: updatedAllSubRoleData });
  };

  handleSubmitRoleCheckbox = async (name, email) => {
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      disableRoleBtn: true,
    });

    const { allRoleData, allSubRoleData } = this.state;

    // Prepare the CheckRoleFields array with IDs and isactive status
    const CheckRoleFields = allRoleData.map((role) => ({
      id: role.id,
      isactive: role.isChecked ? 1 : 0,
    }));

    const CheckSubRoleFields = allSubRoleData.map((role) => ({
      id: role.tabdetailid,
      isactive: role.isChecked ? 1 : 0,
    }));

    try {
      // Call the backend API to save the roles
      const saveResponce = await editAgentAssignRole(
        auth.getAccount(),
        auth.getToken(),
        CheckRoleFields,
        CheckSubRoleFields,
        name,
        email
      );

      // Handle response status
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        this.setState({
          errorMessageAgentRole:
            "There is some error while updating the Agent Role.",
          successMessageAgentRole: "",
          disableRoleBtn: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessageAgentRole: "",
          successMessageAgentRole: "Agent Role updated successfully.",
          disableRoleBtn: false,
        });
        setTimeout(() => {
          window.$("#modalSubAgentAssignRole").modal("hide");
        }, 3000);
      } else {
        this.setState({
          errorMessageAgentRole: saveResponce.data.message,
          successMessageAgentRole: "",
          disableRoleBtn: false,
        });
      }
    } catch (error) {
      console.error("Error while saving roles:", error);
      this.setState({
        errorMessageAgentRole: "An error occurred while saving roles.",
        successMessageAgentRole: "",
        disableRoleBtn: false,
      });
    }
  };

  setStatus = (value, tableMeta) => {
    let domain_str = tableMeta.rowData[4];
    console.log("domain_str: ", domain_str);
    if (domain_str === "active") {
      return (
        <div>
          <span className="badge badge-outline-success">
            {" "}
            <em className="icon ni ni-done" title="Active"></em> Active
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-danger">
            {" "}
            <em className="icon ni ni-alert-c"></em> Inactive
          </span>
        </div>
      );
    }
  };

  setMarkUp = async (value, tableMeta) => {
    this.setState({
      product_desc: tableMeta.rowData[8],
    });
    window.$("#modalFormDetail").modal("show");
  };

  agentSplit = async (value, tableMeta) => {
    let sub_agent_id = value;
    const servicesResponce = await listServices(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data);
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let formValues = [];
      let all_data = servicesResponce.data.data;
      for (let i = 0; i < all_data.length; i++) {
        let created = "80/20_" + all_data[i][0];
        formValues.push(created);
      }
      this.setState({
        servicesData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
        sub_agent_id: sub_agent_id,
        formValues: formValues,
      });
      this.getAgentSplit(servicesResponce.data.data);
    } else {
      //window.location.replace("/error");
    }
    window.$("#modalFormSplit").modal("show");
  };

  getAgentSplit = async (services) => {
    let { sub_agent_id } = this.state;

    const servicesResponce = await getAgentSplit(
      auth.getAccount(),
      auth.getToken(),
      sub_agent_id,
      JSON.stringify(services)
    );
    console.log("getAgentSplit: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        splitData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    }
  };

  editAgentForm = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[5];
    const servicesResponce = await getSingleAgent(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        title: servicesResponce.data.data[0]["title"],
        name: servicesResponce.data.data[0]["name"],
        agent_email: servicesResponce.data.data[0]["email"],
        agent_phone: servicesResponce.data.data[0]["phone"],
        agent_id: servicesResponce.data.data[0]["id"],
        comp: servicesResponce.data.data[0]["comp_name"],
        address: servicesResponce.data.data[0]["address"],
        address2: servicesResponce.data.data[0]["address2"],
        city: servicesResponce.data.data[0]["city"],
        state: servicesResponce.data.data[0]["state"],
        zip: servicesResponce.data.data[0]["zip"],
        country: servicesResponce.data.data[0]["country"],
        account_status: servicesResponce.data.data[0]["account_status"],
        tableLoader: false,
      });
      window.$("#modalFormDetail").modal("show");
    } else {
      // window.location.replace("/error");
    }
  };

  formUpdateSubmit = async () => {
    let {
      title,
      name,
      agent_email,
      agent_password,
      c_password,
      agent_phone,
      comp,
      city,
      state,
      zip,
      address,
      address2,
      country,
      account_status,
    } = this.state;

    this.setState({
      errorMessageEdit: "",
      errorName: "",
      errorStatus: "",
      errorAgentCompany: "",
      errorAgentEmail: "",
      errorAgentPhone: "",
      errorAgentAddress: "",
      errorAgentCity: "",
      errorAgentState: "",
      errorAgentZip: "",
      errorAgentCountry: "",
      errorAgentPassword: "",
      errorCPassword: "",
    });
    const modalBody = document.getElementById("modalFormDetail");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }

    let is_validated = true;
    if (this.state.name === "") {
      this.setState({
        errorName: "error",
        errorMessageEdit: "Agent Name is required.",
      });
      is_validated = false;
    } else if (this.state.comp === "") {
      this.setState({
        errorName: "",
        errorAgentCompany: "error",
        errorMessageEdit: "Company is required.",
      });
      is_validated = false;
    } else if (this.state.agent_phone === "") {
      this.setState({
        errorAgentCompany: "",
        errorAgentPhone: "error",
        errorMessageEdit: "Phone is required.",
      });
      is_validated = false;
    } else if (!this.isPhoneValid(this.state.agent_phone)) {
      this.setState({
        errorAgentCompany: "",
        errorAgentPhone: "error",
        errorMessageEdit: "Invalid phone format.",
      });
      is_validated = false;
    } else if (this.state.address === "") {
      this.setState({
        errorAgentPhone: "",
        errorAgentAddress: "error",
        errorMessageEdit: "Address is required.",
      });
      is_validated = false;
    } else if (this.state.city === "") {
      this.setState({
        errorAgentAddress: "",
        errorAgentCity: "error",
        errorMessageEdit: "City is required.",
      });
      is_validated = false;
    } else if (this.state.state === "") {
      this.setState({
        errorAgentCity: "",
        errorAgentState: "error",
        errorMessageEdit: "State is required.",
      });
      is_validated = false;
    } else if (this.state.zip === "") {
      this.setState({
        errorAgentState: "",
        errorAgentZip: "error",
        errorMessageEdit: "Zip is required.",
      });
      is_validated = false;
    } else if (this.state.country === "") {
      this.setState({
        errorAgentZip: "",
        errorAgentCountry: "error",
        errorMessageEdit: "Country is required.",
      });
      is_validated = false;
    } else if (this.state.agent_password !== "") {
      if (this.state.agent_password.length < 8) {
        this.setState({
          errorAgentCountry: "",
          errorAgentPassword: "error",
          errorMessageEdit: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (this.state.c_password === "") {
        this.setState({
          errorAgentPassword: "",
          errorCPassword: "error",
          errorMessageEdit: "Confirm password is required.",
        });
        is_validated = false;
      } else if (this.state.c_password.length < 8) {
        this.setState({
          errorAgentPassword: "",
          errorCPassword: "error",
          errorMessageEdit:
            "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (
        this.state.agent_password.trim() != this.state.c_password.trim()
      ) {
        this.setState({
          errorAgentPassword: "",
          errorCPassword: "error",
          errorMessageEdit: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    }
    if (is_validated) {
      let apiResponce = await editSubAgent(
        auth.getAccount(),
        auth.getToken(),
        title,
        name,
        agent_email,
        agent_password,
        agent_phone,
        this.state.agent_id,
        address,
        address2,
        zip,
        country,
        city,
        state,
        comp,
        account_status,
        this.state.notifyCheckBox.toString()
      );

      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 409 &&
        apiResponce.data.message === "Address validation failed"
      ) {
        this.setState({
          errorMessageEdit:
          apiResponce.data.errors
          ? String(apiResponce.data.errors).replace(/,/g, "<br />")
          : "Address validation failed",
          disabled: false,
        });
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          successMessageEdit: "Sub channel partner updated successfully.",
          errorMessageEdit: "",
          tableLoader: false,
        });
        setTimeout(async function () {
          //window.$("#modalFormDetail").modal("hide");
          window.location.replace("/agents");
        }, 3000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorMessageEdit: "Something went wrong,try again later.",
          successMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
  };

  handleChange = (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    if (name === "name") {
      value = value.replace(/[^a-zA-Z\s'.,-]/g, "");
    } else if (name === "title") {
      value = value.replace(/[^a-zA-Z0-9\s\-_.,&()]/g, "");
    } else if (name === "comp") {
      value = value.replace(/[^a-zA-Z0-9\s\-_,&().]/g, "");
    }
    this.setState({
      [name]: value,
    });
  };

  handleChangeSplit = (e, i) => {
    console.log(
      "e.target.name  :  e.target.value",
      e.target.name + " : " + e.target.value
    );
    let formValues = [...this.state.formValues];
    formValues[i] = e.target.value;
    this.setState({ formValues });
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  formSplitUpdate = async () => {
    let { formValues, sub_agent_id } = this.state;

    const servicesResponce = await addAgentSplit(
      auth.getAccount(),
      auth.getToken(),
      sub_agent_id,
      JSON.stringify(this.state.formValues)
    );
    console.log("sub_agent_id: ", sub_agent_id);
    console.log("formValues: ", this.state.formValues);
    console.log("formSplitUpdate: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while adding the sub agent split.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Sub agent split added successfully",
      });
      this.getAgentSplit(this.state.servicesData);
    } else {
      this.setState({
        errorMessage: "There is some error while adding the sub agent split.",
        successMessage: "",
      });
      //window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const country = event.label;
    this.setState({
      country: country,
    });
  };

  render() {
    const { useGooglePlaces, toggleButtonText } = this.state;
    const errorStyle = {
      control: (provided) => ({
        ...provided,
        borderColor: this.state.errorAgentAddress ? "red" : "#d3d3d3",
        height: "57px",
      }),
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Sub Agents / Sub Channel Partners"
                        icon="icon ni ni-users"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <Link
                              color="inherit"
                              onClick={() =>
                                this.addMenuHistory("/agents/addagent")
                              }
                              className="btn btn-primary"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                color: "#fff",
                              }}
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Add New Sub Agent
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                {/* Table 5th Col Start */}
                <div className="row g-gs">
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="All Agents"
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                </div>
                {/* Table 5th Col End */}
              </div>
              {/* EDIT MODAL End */}
              <div className="modal fade" tabIndex="-1" id="modalFormDetail">
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        <em className="icon ni ni-edit"></em> Edit Sub Agent
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}
                      {this.state.errorMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              this.state.errorMessageEdit,
                                          }}
                                        ></p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageEdit !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageEdit}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="row g-4">
                        <form method="post" encType="multipart/form-data">
                          <div className="row g-4">
                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="name"
                                name="name"
                                type="text"
                                label="Agent Name"
                                inputProps={{ maxLength: 100 }}
                                value={this.state.name}
                                onChange={this.handleChange}
                                helperText="Enter the agent name. Allowed characters: (&nbsp; ' &nbsp; - &nbsp; . &nbsp; , )"
                                variant="outlined"
                                fullWidth
                                error={this.state.errorName === "error"}
                              />
                            </div>

                            

                            <div className="form-group col-md-3">
                              <MutextField
                                id="title"
                                name="title"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Agent Title"
                                value={this.state.title}
                                onChange={this.handleChange}
                                helperText="Enter the title. Allowed characters: (&nbsp; - &nbsp; _ &nbsp; . &nbsp; &nbsp; , &nbsp; &amp; &nbsp; ( ) )"
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <MutextField
                                required
                                id="comp"
                                name="comp"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Agent Comp"
                                value={this.state.comp}
                                onChange={this.handleChange}
                                helperText="Enter the company. Allowed characters: (&nbsp; _ &nbsp; . &nbsp; - &nbsp; , &nbsp; &amp; &nbsp; ( ) )"
                                variant="outlined"
                                fullWidth
                                error={this.state.errorAgentCompany === "error"}
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                required
                                id="agent_email"
                                name="agent_email"
                                type="email"
                                label="Email Address"
                                value={this.state.agent_email}
                                onChange={this.handleChange}
                                helperText="Enter your email address here."
                                variant="outlined"
                                fullWidth
                                disabled
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </div>

                            <div className="form-group col-md-6">
                              {/*<PhoneInput
                                style={{ height: "71%" }}
                                required
                                defaultCountry={"us"}
                                error={this.state.errorPhone}
                                inputProps={{
                                  name: "agent_phone",
                                  id: "agent_phone",
                                  maxLength: 20,
                                }}
                                value={this.state.agent_phone}
                                onChange={(value) =>
                                  this.handleChange({
                                    target: { name: "agent_phone", value },
                                  })
                                }
                                helperText="Enter the agent phone number."
                                label="Agent Phone Number"
                                ref={this.phoneInputRef}
                                onFocus={this.handleFocus}
                              />*/}
                              <PhoneInput
                                style={{ height: "71%" }}
                                required
                                defaultCountry={"us"}
                                error={this.state.errorPhone}
                                inputProps={{
                                  name: "agent_phone",
                                  id: "agent_phone",
                                  maxLength: 20,
                                  className: this.state.errorAgentPhone
                                    ? "error-border"
                                    : "default-border",
                                }}
                                dialCodePreviewStyleProps={{
                                  className: this.state.errorAgentPhone
                                    ? "error-border"
                                    : "default-border",
                                }}
                                value={this.state.agent_phone}
                                onChange={(value) =>
                                  this.handleChange({
                                    target: { name: "agent_phone", value },
                                  })
                                }
                                helperText="Enter the agent phone number."
                                label="Agent Phone Number"
                                ref={this.phoneInputRef}
                                onFocus={this.handleFocus}
                              />
                            </div>

                            <div className="form-group col-md-6 mt-md-0 googleLocation-autocomplete-wrapper">
                              <div
                                class="nk-block-between-md g-4"
                                style={{
                                  position: "absolute",
                                  zIndex: 9999,
                                  right: "13px",
                                  top: "-15px",
                                }}
                              >
                                <div class="nk-block-head-content"></div>
                                <div class="nk-block-head-content">
                                  <ul class="nk-block-tools gx-3">
                                    <li>
                                      <a
                                        onClick={this.handleToggle}
                                        class="text-primary fs-11"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "11px",
                                        }}
                                      >
                                        {toggleButtonText}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div>
                                {useGooglePlaces ? (
                                  <>
                                    <GooglePlacesAutocomplete
                                      apiKey={
                                        process.env
                                          .REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                                      }
                                      selectProps={{
                                        value: this.state.address
                                          ? {
                                              label: this.state.address,
                                              value: this.state.address,
                                            }
                                          : null,
                                        onChange: this.handleAddressSelect,
                                        placeholder: this.state.address
                                          ? null
                                          : "Enter your address..",
                                        styles: {
                                          ...errorStyle,
                                          input: (provided) => ({
                                            ...provided,
                                            color: "black",
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            zIndex: "9",
                                          }),
                                        },
                                      }}
                                      id="googleLocation-autocomplete"
                                    />
                                    <p
                                      style={{
                                        fontSize: "0.75rem",
                                        fontWeight: 400,
                                        marginTop: "3px",
                                        color: "#526484",
                                        paddingLeft: "13px",
                                      }}
                                    >
                                      Enter your address here.
                                    </p>
                                  </>
                                ) : (
                                  <MutextField
                                    required
                                    id="address"
                                    name="address"
                                    type="text"
                                    label="PO Box Address"
                                    value={this.state.address}
                                    onChange={this.handleChange}
                                    helperText="Enter valid PO Box Address. i.e, PO Box Number, City, State Zip Code"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                      required: false,
                                    }}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="address2"
                                name="address2"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Agent Address2"
                                value={this.state.address2}
                                onChange={this.handleChange}
                                helperText="Enter the address2 here."
                                variant="outlined"
                                fullWidth
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <MutextField
                                required
                                id="city"
                                name="city"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Agent City"
                                value={this.state.city}
                                onChange={this.handleChange}
                                helperText="Enter the city here."
                                variant="outlined"
                                fullWidth
                                error={this.state.errorAgentCity === "error"}
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <MutextField
                                required
                                id="state"
                                name="state"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Agent State"
                                value={this.state.state}
                                onChange={this.handleChange}
                                helperText="Enter the state here."
                                variant="outlined"
                                fullWidth
                                error={this.state.errorAgentState === "error"}
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <MutextField
                                required
                                id="zip"
                                name="zip"
                                type="text"
                                inputProps={{ maxLength: 30 }}
                                label="Agent zip"
                                value={this.state.zip}
                                onChange={this.handleChange}
                                helperText="Enter the zip here."
                                variant="outlined"
                                fullWidth
                                error={this.state.errorAgentZip === "error"}
                              />
                            </div>

                            <div className="form-group col-md-3">
                              <Select
                                className="customStyle-country"
                                options={this.countryOptions}
                                value={{
                                  label: this.state.country,
                                  value: this.state.country,
                                }}
                                onChange={this.handleChangeCountry}
                                placeholder="Select a country"
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    minHeight: "56px",
                                    borderColor: this.state.errorAgentCountry
                                      ? "red"
                                      : baseStyles.borderColor,
                                    "&:hover": {
                                      borderColor: this.state.errorAgentCountry
                                        ? "red"
                                        : baseStyles["&:hover"].borderColor,
                                    },
                                    boxShadow: this.state.errorAgentCountry
                                      ? "0 0 0 1px red"
                                      : baseStyles.boxShadow,
                                  }),
                                }}
                              />
                              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-required">
                                Select country
                              </p>
                            </div>

                            <div
                              className="form-group col-md-6"
                              style={{ position: "relative" }}
                            >
                              <MutextField
                                required
                                id="agent_password"
                                name="agent_password"
                                type={
                                  this.state.passwordShown ? "text" : "password"
                                }
                                label="Paasword"
                                value={this.state.agent_password}
                                inputProps={{ maxLength: 30 }}
                                onChange={this.handleChange}
                                helperText="Enter your password here."
                                variant="outlined"
                                fullWidth
                                error={
                                  this.state.errorAgentPassword === "error"
                                }
                              />
                              <a
                                style={{
                                  left: "auto",
                                  right: "10px",
                                  top: "41px",
                                  position: "absolute",
                                  cursor: "pointer",
                                }}
                                tabIndex="-1"
                                onClick={this.togglePassword}
                                className="form-icon form-icon-right passcode-switch"
                                data-target="password"
                              >
                                {this.state.passwordShown ? (
                                  <em className="icon ni ni-eye-off"></em>
                                ) : (
                                  <em className="icon ni ni-eye"></em>
                                )}
                              </a>
                            </div>
                            <div
                              className="form-group col-md-6"
                              style={{ position: "relative" }}
                            >
                              <MutextField
                                required
                                id="c_password"
                                name="c_password"
                                type={
                                  this.state.confirmPasswordShown
                                    ? "text"
                                    : "password"
                                }
                                label="Confirm Password"
                                value={this.state.c_password}
                                inputProps={{ maxLength: 30 }}
                                onChange={this.handleChange}
                                helperText="Enter the confirm password here."
                                variant="outlined"
                                fullWidth
                                error={this.state.errorCPassword === "error"}
                              />
                              <a
                                style={{
                                  left: "auto",
                                  right: "10px",
                                  top: "41px",
                                  position: "absolute",
                                  cursor: "pointer",
                                }}
                                tabIndex="-1"
                                onClick={this.toggleConfirmPassword}
                                className="form-icon form-icon-right passcode-switch"
                                data-target="password"
                              >
                                {this.state.confirmPasswordShown ? (
                                  <em className="icon ni ni-eye-off"></em>
                                ) : (
                                  <em className="icon ni ni-eye"></em>
                                )}
                              </a>
                            </div>

                            <div className="form-group col-md-6">
                              <MutextField
                                id="account_status"
                                name="account_status"
                                select
                                label="Select Account Status"
                                value={this.state.account_status}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Select the account status here."
                                variant="outlined"
                                fullWidth
                              >
                                <option key="5" value="active">
                                  Active
                                </option>
                                <option key="10" value="inactive">
                                  Inactive
                                </option>
                              </MutextField>
                            </div>

                            <div
                              className="custom-control custom-control-lg custom-checkbox col-lg-3"
                              style={{ padding: 0, marginLeft: "3rem" }}
                            >
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheck2"
                                  checked={this.state.notifyCheckBox}
                                  onChange={() => {
                                    const isChecked =
                                      !this.state.notifyCheckBox;
                                    this.setState({
                                      notifyCheckBox: isChecked,
                                    });
                                    console.log(
                                      "Notify Checkbox Checked:",
                                      isChecked
                                    );
                                  }}
                                />
                                <label
                                  class="custom-control-label"
                                  for="customCheck2"
                                >
                                  Notify Sub Channel Partner
                                </label>
                              </div>
                            </div>

                            <div style={{ clear: "both" }}></div>
                            <hr />
                            <div className="form-group text-right col-md-12">
                              <button
                                className="btn btn-lg btn-primary"
                                type="button"
                                disabled={this.state.disabled}
                                onClick={this.formUpdateSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* EDIT MODAL End */}
              {/* DETAIL MODAL End */}
              <div className="modal fade" tabIndex="-1" id="modalFormSplit">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        <em className="icon ni ni-edit"></em> Sub Agent Split
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}

                      <div className="col-lg-12 col-md-12 col-xxl-12">
                        <div
                          id="accordion-2"
                          className="accordion accordion-s3"
                        >
                          <div className="accordion-item">
                            <a
                              href="#"
                              className="accordion-head"
                              data-toggle="collapse"
                              data-target="#accordion-item-2-1"
                            >
                              <h6 className="title">Sub Agent Split</h6>
                              <span className="accordion-icon"></span>
                            </a>
                            <div
                              className="accordion-body collapse show"
                              id="accordion-item-2-1"
                              data-parent="#accordion-2"
                            >
                              <div className="accordion-inner">
                                {this.state.tableLoaderListing === true ? (
                                  tableLoaderListing()
                                ) : (
                                  <>
                                    {this.state.splitData ? (
                                      <table className="table">
                                        <thead className="thead-light">
                                          <tr>
                                            <th scope="col">Service</th>
                                            <th scope="col">Sub Agent Split</th>
                                            <th scope="col">Date Added</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.splitData.map(
                                            (splits, index) => (
                                              <tr key={`index${index}`}>
                                                <td>{splits[0]}</td>
                                                <td>{splits[1]}</td>
                                                <td>{splits[2]}</td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    ) : (
                                      <div
                                        className="example-alert"
                                        style={{ marginBottom: "20px" }}
                                      >
                                        <div className="alert alert-pro alert-warning">
                                          <div className="alert-text">
                                            <h4>Warning</h4>
                                            <p>Agent split is not added.</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {/*<div className="accordion-item">
                            <a
                              href="#"
                              className="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#accordion-item-2-2"
                            >
                              <h6 className="title">Update Sub Agent Split</h6>
                              <span className="accordion-icon"></span>
                            </a>
                            <div
                              className="accordion-body collapse"
                              id="accordion-item-2-2"
                              data-parent="#accordion-2"
                            >
                              <div className="accordion-inner">
                                {this.state.errorMessage !== "" ? (
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: this.state.errorMessage,
                                          }}
                                        ></p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div
                                    className="example-alert"
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div style={{ clear: "both" }}></div>
                                {this.state.servicesData.length > 0 ? (
                                  <>
                                    <form
                                      method="post"
                                      encType="multipart/form-data"
                                    >
                                      <div className="col-lg-12 col-md-12">
                                        <ul className="data-list is-compact">
                                          <li
                                            className="data-item"
                                            style={{
                                              backgroundColor: "#f5f6fa",
                                            }}
                                          >
                                            <div className="data-col">
                                              <div className="data-label">
                                                Service
                                              </div>
                                              <div
                                                className="data-value"
                                                style={{ width: "48%" }}
                                              >
                                                Select Split
                                              </div>
                                            </div>
                                          </li>
                                          {this.state.servicesData.map(
                                            (didservices, index) => (
                                              <li
                                                className="data-item"
                                                key={index}
                                              >
                                                <div className="data-col">
                                                  <div className="data-label">
                                                    {didservices[1]}
                                                  </div>
                                                  <div
                                                    className="data-value"
                                                    style={{ width: "48%" }}
                                                  >
                                                    <MutextField
                                                      name="agent_split"
                                                      select
                                                      label="Sub Agent Split"
                                                      value={
                                                        this.state.agent_split
                                                      }
                                                      onChange={(e) =>
                                                        this.handleChangeSplit(
                                                          e,
                                                          index
                                                        )
                                                      }
                                                      SelectProps={{
                                                        native: true,
                                                      }}
                                                      helperText="Split is divided in ratio Agent/Sub-agent."
                                                      variant="outlined"
                                                      fullWidth
                                                    >
                                                      <option
                                                        value={`80/20_${didservices[0]}`}
                                                      >
                                                        80/20
                                                      </option>
                                                      <option
                                                        value={`70/30_${didservices[0]}`}
                                                      >
                                                        70/30
                                                      </option>
                                                      <option
                                                        value={`60/40_${didservices[0]}`}
                                                      >
                                                        60/40
                                                      </option>
                                                      <option
                                                        value={`50/50_${didservices[0]}`}
                                                      >
                                                        50/50
                                                      </option>
                                                      <option
                                                        value={`40/60_${didservices[0]}`}
                                                      >
                                                        40/60
                                                      </option>
                                                      <option
                                                        value={`30/70_${didservices[0]}`}
                                                      >
                                                        30/70
                                                      </option>
                                                      <option
                                                        value={`20/80_${didservices[0]}`}
                                                      >
                                                        20/80
                                                      </option>
                                                    </MutextField>
                                                  </div>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      <div className="form-group text-right col-md-12">
                                        <button
                                          className="btn btn-lg btn-primary"
                                          type="button"
                                          disabled={this.state.disabled}
                                          onClick={this.formSplitUpdate}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </form>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>*/}
                        </div>

                        <div style={{ clear: "both" }}></div>
                        <hr />
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* EDIT MODAL End */}
              {/* Role Sub Assignment Modal START */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalSubAgentAssignRole"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-grid-alt"></em>Sub Channel
                          Partner Roles{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.rolesAgentName}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body text-left">
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          {this.state.agentRoleLoader === true ? (
                            tableLoader()
                          ) : (
                            <>
                              {this.state.errorMessageAgentRole !== "" ? (
                                <div
                                  className="example-alert "
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.errorMessageAgentRole}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageAgentRole !== "" ? (
                                <div
                                  className="example-alert "
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.successMessageAgentRole}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="card card-bordered card-preview">
                                <table className="table table-orders">
                                  <thead className="tb-odr-head">
                                    <tr className="tb-odr-item">
                                      <th
                                        width="35%"
                                        style={{
                                          borderRight: "1px solid #dbdfea",
                                        }}
                                      >
                                        <span className="tb-odr-action">
                                          Roles
                                        </span>
                                      </th>
                                      <th width="65%">
                                        <span className="tb-odr-action">
                                          Sub Roles
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="tb-odr-body">
                                    {this.state.allRoleData &&
                                    this.state.allRoleData.length > 0 ? (
                                      <>
                                        {this.state.allRoleData.map(
                                          (role, index) => (
                                            <tr
                                              className="tb-odr-item"
                                              key={`role${index}`}
                                            >
                                              <td
                                                style={{
                                                  borderRight:
                                                    "1px solid #dbdfea",
                                                }}
                                              >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={this.state.allRoleData.some(
                                                        (item) =>
                                                          item.alt ===
                                                            role.alt &&
                                                          item.isChecked
                                                      )}
                                                      onChange={
                                                        this
                                                          .changedAllRolesRights
                                                      }
                                                      disabled={
                                                        role.parent_role_id ===
                                                        1
                                                      }
                                                      name={role?.id?.toString()}
                                                      color="primary"
                                                    />
                                                  }
                                                  style={{
                                                    marginBottom: "0px",
                                                  }}
                                                  label={role.alt}
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  paddingLeft: "16px",
                                                }}
                                              >
                                                {this.state.allSubRoleData
                                                  .filter((subRole) => {
                                                    return (
                                                      subRole.role_id ===
                                                      role.id
                                                    );
                                                  })
                                                  .map((subRole, subIndex) => (
                                                    <div
                                                      key={`subRole${subIndex}`}
                                                    >
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            checked={this.state.allSubRoleData.some(
                                                              (item) =>
                                                                item.alt ===
                                                                  subRole.alt &&
                                                                item.isChecked
                                                            )}
                                                            onChange={
                                                              this
                                                                .changedAllSubRolesRights
                                                            }
                                                            name={subRole?.tabdetailid?.toString()}
                                                            color="primary"
                                                          />
                                                        }
                                                        style={{
                                                          marginBottom: "0px",
                                                        }}
                                                        label={subRole.alt}
                                                      />
                                                    </div>
                                                  ))}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </>
                                    ) : null}
                                  </tbody>
                                </table>
                              </div>
                              <div className="nk-modal-action  text-right">
                                <a
                                  href="#"
                                  className="btn btn-lg btn-mw btn-primary "
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleSubmitRoleCheckbox(
                                      this.state.checkedName,
                                      this.state.checkedEmail
                                    );
                                  }}
                                  disabled={this.state.disableRoleBtn}
                                >
                                  Assign Roles
                                </a>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Role Sub Assignment Modal End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agents);
