import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { getAgentCommissionSettings } from "./../../../config/agent_reports_api_calls";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Fileupload } from "../../../components/Image/Fileupload";

const auth = new HelperClass();

class Payouts extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      client_login_link: "",
      copied: false,
      copied_client: false,
      copied2: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      cancel_button: false,
      tableLoader: true,
      login_url: "",
      logo: "",
      signature_image: "",
      commission_button: false,
      commissionErrorMessage: "",
      commissionSuccessMessage: "",
      commissionErrorTitle: "",
      commission_type: "paid_on_collected",
      commission_value_type: "percentage",
      apply_commission: "",
      draw_threshold: (0).toFixed(this.decimalPoint),
      commission_value: "",
    };
  }

  async componentDidMount() {
    const reportsData = await getAgentCommissionSettings(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("getAgentCommissionSettings: ", reportsData.data);

    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        apply_commission: reportsData.data.data[0].apply_commission,
        commission_type: reportsData.data.data[0].commission_type,
        commission_value: reportsData.data.data[0].commission_value,
        draw_threshold: reportsData.data.data[0].draw_threshold
          ? this.formatAsCurrency(reportsData.data.data[0].draw_threshold)
          : this.formatAsCurrency(0),
        commission_value_type: reportsData.data.data[0].commission_value_type,
        tableLoader: false,
      });
    } else {
      console.log("Last: ", reportsData.data);
      //window.location.replace("/error");
    }
  }

  getDecimalPoint = () => {
    //console.log("this.props.quotationSettings[0]?.decimal_point: ", this.props);
    if (
      this.props.quotationSettings[0]?.decimal_point &&
      this.props.quotationSettings[0]?.decimal_point !== ""
    ) {
      const decimalPoint = this.props.quotationSettings[0]?.decimal_point;
      return decimalPoint !== "" ? Number(decimalPoint) : 2;
    }
    return 2;
  };

  formatAsCurrency = (amount) => {
    //console.log("amount: ", typeof amount);
    let decimal_point = this.getDecimalPoint();
    

    if (typeof amount === "string") {
      amount = parseFloat(amount);
    }
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: decimal_point,
      maximumFractionDigits: decimal_point,
    });
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      {" "}
                      <em class="icon ni ni-setting"></em> Commission
                      Settings
                    </h3>
                    <div className="nk-block-des text-soft">
                      <p>
                        Setting includes <b>Commission Payment Terms</b>, which
                        define the schedule and conditions for payouts, and the{" "}
                        <b>Commission Value</b>, representing the percentage of
                        earnings channel partners receive. You can also specify
                        the <b>Commission Type</b>, whether it’s a fixed amount
                        or a percentage-based structure, along with the{" "}
                        <b>Withdraw Threshold</b>, which sets the minimum
                        commission amount required for channel partners to make
                        a withdrawal each month. These settings ensure
                        flexibility and control over commission management."
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner genericTable">
                          <table class="table table-bordered table-striped">
                            <thead>
                              <tr>
                                <th scope="col" style={{ paddingLeft: "1rem" }}>
                                  Commission Payment Terms
                                </th>
                                <th scope="col">Commission Value</th>
                                <th scope="col">Commission Type</th>
                                <th scope="col">
                                  Withdraw Threshold (Per Month)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{this.state.commission_type}</td>
                                <td>
                                  {this.state.commission_value_type &&
                                  this.state.commission_value_type ===
                                    "percentage" ? (
                                    <>{this.state.commission_value}%</>
                                  ) : (
                                    <>
                                      {this.formatAsCurrency(
                                        this.state.commission_value
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>{this.state.commission_value_type}</td>
                                <td>{this.state.draw_threshold}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
    quotationSettings: state.quotationSettings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Payouts);
